<!-- 最适合的高校大类专业详细报告 -->
<template>
  <div class="main">
    <div class="main-title">
      <span
        style="left: 0; padding-left: 2rem; position: absolute; color: #2EA9DF;"
        >VIP版</span
      ><span>最适合的高校大类专业详细报告</span>
      <div class="print-btn" @click="print()">
        <div class="print-btn-text-wrapper">
          <span>打印</span><span>报告</span>
        </div>
      </div>
    </div>
    <div class="main-subtitle" v-show="!detailActive">
      以下为您比较适合的专业类别，点击卡片查看详情
    </div>

    <div class="main-content">
      <div
        class="main-majors-wrapper"
        :class="{ 'main-majors-wrapper-shrink': detailActive }"
      >
        <div
          class="major major-shrink"
          id="back-btn"
          v-show="detailActive"
          @click="onBackClick"
        >
          返回专业概览
        </div>

        <!-- 小卡片，大类包含几个小类 -->
        <div
          class="major"
          v-for="(item, index) in majorsData"
          :key="item.name"
          @click="onMajorClick(item)"
          :class="{
            'major-shrink': detailActive,
            'major-active': detailActive && currentMajor.name === item.name
          }"
        >
          <div class="major-title">{{ index + 1 }}. {{ item.name }}</div>
          <div class="major-information">
            包含 {{ item.submajorsData.length }} 个专业
          </div>
        </div>

        <div
          class="major"
          v-if="!detailActive"
          style="opacity: 0; cursor: auto;"
        >
          <div class="major-title"></div>
          <div class="major-information"></div>
        </div>
        <!-- TODO: 返回专业概览的第二个按钮，如果滚动到了屏幕上界之外，应该钉在导航栏下方，这样点起来方便。-->
        <div
          class="major major-shrink"
          id="back-btn"
          v-show="detailActive"
          @click="onBackClick"
        >
          返回专业概览
        </div>
        <div class="shrink-buttons-wrapper" v-show="detailActive">
          <!-- <a
            class="lj-btn lj-btn-third lj-btn-block shrink-button"
            @click="jumpPage('HomeMajorStdResultPage5')"
            >上一页</a
          > -->
          <!-- <a
            class="lj-btn lj-btn-primary lj-btn-block shrink-button"
            @click="jumpPage('HomeMajorStdResultPage7')"
            >下一页</a
          > -->
          <a
        class="lj-btn lj-btn-primary lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      >
        </div>
      </div>

      <div
        class="main-submajors-wrapper"
        v-show="detailActive"
        ref="submajorsWrapper"
      >
        <div
          class="submajor-wrapper"
          v-for="(item, index) in currentMajor.submajorsData"
          :key="item.majorname"
        >
          <!-- TODO: 如果专业大类在侧栏的按钮在屏幕下方之外，这里应该有个标题提示一下当前的专业大类是什么。-->
          <div class="submajor">
            <div class="submajor-title">
              {{ index + 1 }}. {{ item.majorname }}
            </div>
            <p v-if="isNotEmpty(item.background)">
              <strong>专业背景：</strong>{{ item.background }}
            </p>
            <p v-if="isNotEmpty(item.learning_threshold)">
              <strong>学习门槛：</strong>{{ item.learning_threshold }}
            </p>
            <p v-if="isNotEmpty(item.main_courses)">
              <strong>主要课程：</strong>{{ item.main_courses }}
            </p>
            <p v-if="isNotEmpty(item.work_type)">
              <strong>毕业去向：</strong>{{ item.work_type }}
            </p>
            <p v-if="isNotEmpty(item.graduates_num)">
              <strong>近五年毕业生规模：</strong>{{ item.graduates_num }}
            </p>
            <p v-if="isNotEmpty(item.school_num)">
              <strong>毕业生规模（院校数量）：</strong>{{ item.school_num }}
            </p>
            <p v-if="isNotEmpty(item.liberal_proporation)">
              <strong>文理科比例（文科）：</strong
              >{{ item.liberal_proporation }}%
            </p>
            <p v-if="isNotEmpty(item.science_proporation)">
              <strong>文理科比例（理科）：</strong
              >{{ item.science_proporation }}%
            </p>
            <p v-if="isNotEmpty(item.employment_num)">
              <strong>近五年就业统计：</strong>{{ item.employment_num }}
            </p>

            <div v-if="getAdditionalRanking(item.name)">
              <p>
                <strong>2017年第四次学科评估及双一流信息：</strong>
              </p>
              <table
                class="lj-table lj-table-bordered lj-table-striped lj-table-hover"
                style="width: 100%;"
              >
                <thead>
                  <tr>
                    <th>学校名称</th>
                    <th>第四次评估结果</th>
                    <th>双一流建设学科</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in getAdditionalRanking(item.name)"
                    :key="index"
                  >
                    <td>{{ getSchoolName(item[0]) }}</td>
                    <td>{{ item[1] || '未参评' }}</td>
                    <td>{{ item[2] || '-' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p v-if="isNotEmpty(item.special_colleges_1)">
              <strong>列入特色专业院校（特色院校）：</strong
              >{{ item.special_colleges_1 }}
            </p>
            <p v-if="isNotEmpty(item.special_colleges_2)">
              <strong>列入特色专业院校（全国重点）：</strong
              >{{ item.special_colleges_2 }}
            </p>
            <p v-if="isNotEmpty(item.special_colleges_3)">
              <strong>列入特色专业院校（省属重点）：</strong
              >{{ item.special_colleges_3 }}
            </p>
            <p v-if="isNotEmpty(item.special_colleges_4)">
              <strong>列入特色专业院校（普通本科）：</strong
              >{{ item.special_colleges_4 }}
            </p>
            <p v-if="isNotEmpty(item.other_colleges_1)">
              <strong>其他开设院校1（全国重点）：</strong
              >{{ item.other_colleges_1 }}
            </p>
            <p v-if="isNotEmpty(item.other_colleges_2)">
              <strong>其他开设院校2（省属重点）：</strong
              >{{ item.other_colleges_2 }}
            </p>
            <p v-if="isNotEmpty(item.other_colleges_3)">
              <strong>其他开设院校3（一般本科）：</strong
              >{{ item.other_colleges_3 }}
            </p>
            <p v-if="isNotEmpty(item.others)">
              <strong>其他：</strong>{{ item.others }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="main-buttons-wrapper" v-show="!detailActive">
      <!-- <a
        class="lj-btn lj-btn-third lj-btn-block main-button"
        @click="jumpPage('HomeMajorStdResultPage5')"
        >上一页</a
      > -->
      <a
        class="lj-btn lj-btn-default lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      >
      <!-- <a
        class="lj-btn lj-btn-primary lj-btn-block main-button"
        @click="jumpPage('HomeMajorStdResultPage7')"
        >下一页</a
      > -->
    </div>
  </div>
</template>

<script>
// import { additionalRanking, doubleFirstClassType } from './schoolinfo.js'
import { queryMatchingMajorsDetails } from './../../../../api/major'
export default {
  data: function () {
    return {
      detailActive: false,
      majorsData: [
        {
          name: '教育学类',
          submajorsData: [
            {
              majorname: '教育学',
              background: 'background',
              learning_threshold: 'learning_threshold',
              main_courses: 'main_courses',
              work_type: 'work_type',
              graduates_num: 'graduates_num',
              school_num: 'school_num',
              special_colleges_1: 'special_colleges_1',
              special_colleges_2: 'special_colleges_2',
              special_colleges_3: 'special_colleges_3',
              special_colleges_4: 'special_colleges_4',
              other_colleges_1: 'other_colleges_1',
              other_colleges_2: 'other_colleges_2',
              other_colleges_3: 'other_colleges_3',
              others: 'others'
            }
          ]
        },
        {
          name: '建筑类',
          submajorsData: [
            {
              name: '建筑学',
              background: 'background',
              learning_threshold: 'learning_threshold',
              main_courses: 'main_courses',
              work_type: 'work_type',
              graduates_num: 'graduates_num',
              school_num: 'school_num',
              special_colleges_1: 'special_colleges_1',
              special_colleges_2: 'special_colleges_2',
              special_colleges_3: 'special_colleges_3',
              special_colleges_4: 'special_colleges_4',
              other_colleges_1: 'other_colleges_1',
              other_colleges_2: 'other_colleges_2',
              other_colleges_3: 'other_colleges_3',
              others: 'others'
            }
          ]
        }
      ],
      currentMajor: [],

      additionalRanking: [
        [
          '安全科学与工程',
          [
            ['安徽理工大学', 'B', null],
            ['北京化工大学', 'C+', null],
            ['北京交通大学', 'B-', null],
            ['北京科技大学', 'B+', null],
            ['北京理工大学', 'B+', null],
            ['常州大学', 'C-', null],
            ['大连理工大学', 'C', null],
            ['东北大学', 'B-', null],
            ['河南理工大学', 'A-', null],
            ['黑龙江科技大学', 'C', null],
            ['湖南科技大学', 'C+', null],
            ['华东理工大学', 'C', null],
            ['华南理工大学', 'C+', null],
            ['昆明理工大学', 'C-', null],
            ['辽宁工程技术大学', 'B', null],
            ['辽宁石油化工大学', 'C-', null],
            ['南华大学', 'C+', null],
            ['南京工业大学', 'B+', null],
            ['南京理工大学', 'C+', null],
            ['青岛科技大学', 'C-', null],
            ['清华大学', 'B+', null],
            ['山东科技大学', 'B', null],
            ['沈阳航空航天大学', 'C', null],
            ['太原理工大学', 'B-', null],
            ['武汉科技大学', 'B-', null],
            ['武汉理工大学', 'B-', null],
            ['西安科技大学', 'A-', null],
            ['郑州大学', 'C-', null],
            ['中北大学', 'C', null],
            ['中国地质大学', 'B', null],
            ['中国科学技术大学', 'A+', '安全科学与工程'],
            ['中国矿业大学', 'A+', '安全科学与工程'],
            ['中国民航大学', 'C+', null],
            ['中国石油大学', 'B+', null],
            ['中南大学', 'A-', null],
            ['重庆大学', 'B', null],
            ['中国矿业大学(北京)', null, '安全科学与工程']
          ]
        ],
        [
          '兵器科学与技术',
          [
            ['北京理工大学', 'A+', '兵器科学与技术'],
            ['海军工程大学', 'C-', null],
            ['火箭军工程大学', 'B-', null],
            ['解放军理工大学', 'C+', null],
            ['空军工程大学', 'B-', null],
            ['南京理工大学', 'A+', '兵器科学与技术'],
            ['西北工业大学', 'B', null],
            ['中北大学', 'C-', null],
            ['装备学院', 'C', null]
          ]
        ],
        [
          '材料科学与工程',
          [
            ['安徽大学', 'C', '材料科学与工程（自定）'],
            ['安徽工业大学', 'C+', null],
            ['安徽建筑大学', 'C-', null],
            ['北京工业大学', 'B+', null],
            ['北京航空航天大学', 'A+', '材料科学与工程'],
            ['北京化工大学', 'A-', null],
            ['北京交通大学', 'C', null],
            ['北京科技大学', 'A', '材料科学与工程'],
            ['北京理工大学', 'A-', '材料科学与工程'],
            ['北京印刷学院', 'C-', null],
            ['常州大学', 'C', null],
            ['大连交通大学', 'C+', null],
            ['大连理工大学', 'B+', null],
            ['电子科技大学', 'B', null],
            ['东北大学', 'A-', null],
            ['东华大学', 'B+', null],
            ['东南大学', 'B', '材料科学与工程'],
            ['福州大学', 'C+', null],
            ['复旦大学', 'B+', '材料科学与工程'],
            ['广东工业大学', 'B-', null],
            ['广西大学', 'C-', null],
            ['贵州大学', 'C+', null],
            ['桂林电子科技大学', 'C', null],
            ['桂林理工大学', 'C', null],
            ['国防科技大学', 'B+', null],
            ['哈尔滨工程大学', 'B', null],
            ['哈尔滨工业大学', 'A', '材料科学与工程'],
            ['哈尔滨理工大学', 'C+', null],
            ['海军工程大学', 'C-', null],
            ['海南大学', 'C', null],
            ['合肥工业大学', 'B', null],
            ['河北工业大学', 'B', null],
            ['河海大学', 'C', null],
            ['河南科技大学', 'B-', null],
            ['河南理工大学', 'C-', null],
            ['湖北大学', 'C+', null],
            ['湖北工业大学', 'C-', null],
            ['湖南大学', 'B', null],
            ['华北电力大学', 'C', null],
            ['华北理工大学', 'C-', null],
            ['华东理工大学', 'B+', '材料科学与工程'],
            ['华南理工大学', 'A-', '材料科学与工程'],
            ['华侨大学', 'C+', null],
            ['华中科技大学', 'B+', '材料科学与工程'],
            ['吉林大学', 'B+', '材料科学与工程'],
            ['济南大学', 'B-', null],
            ['暨南大学', 'C-', null],
            ['佳木斯大学', 'C', null],
            ['江南大学', 'C', null],
            ['江苏大学', 'B', null],
            ['江苏科技大学', 'B-', null],
            ['江西理工大学', 'C-', null],
            ['景德镇陶瓷大学', 'C+', null],
            ['昆明理工大学', 'B', null],
            ['兰州大学', 'C+', null],
            ['兰州理工大学', 'B-', null],
            ['辽宁科技大学', 'C-', null],
            ['南昌大学', 'B-', '材料科学与工程'],
            ['南昌航空大学', 'C', null],
            ['南京大学', 'B+', '材料科学与工程'],
            ['南京工业大学', 'B+', null],
            ['南京航空航天大学', 'B', null],
            ['南京理工大学', 'B', null],
            ['南开大学', 'B', '材料科学与工程'],
            ['内蒙古工业大学', 'C', null],
            ['青岛大学', 'C+', null],
            ['青岛科技大学', 'B-', null],
            ['清华大学', 'A+', '材料科学与工程'],
            ['厦门大学', 'B', null],
            ['山东大学', 'B+', null],
            ['山东科技大学', 'C-', null],
            ['陕西科技大学', 'B-', null],
            ['上海大学', 'B+', null],
            ['上海工程技术大学', 'C-', null],
            ['上海交通大学', 'A', '材料科学与工程'],
            ['深圳大学', 'C', null],
            ['沈阳大学', 'C-', null],
            ['沈阳工业大学', 'B-', null],
            ['沈阳化工大学', 'C-', null],
            ['沈阳建筑大学', 'C-', null],
            ['沈阳理工大学', 'C-', null],
            ['四川大学', 'A-', '材料科学与工程'],
            ['苏州大学', 'B+', '材料科学与工程（自定）'],
            ['太原科技大学', 'C+', null],
            ['太原理工大学', 'B-', null],
            ['天津大学', 'A-', '材料科学与工程'],
            ['天津工业大学', 'B-', null],
            ['天津理工大学', 'B-', null],
            ['同济大学', 'B+', null],
            ['武汉大学', 'B-', null],
            ['武汉工程大学', 'C+', null],
            ['武汉科技大学', 'B', null],
            ['武汉理工大学', 'A+', '材料科学与工程'],
            ['西安电子科技大学', 'C+', null],
            ['西安工业大学', 'C+', null],
            ['西安建筑科技大学', 'B', null],
            ['西安交通大学', 'A-', '材料科学与工程'],
            ['西安理工大学', 'B', null],
            ['西北工业大学', 'A', '材料科学与工程'],
            ['西南交通大学', 'B', null],
            ['西南科技大学', 'C+', null],
            ['西南石油大学', 'C', null],
            ['湘潭大学', 'B', null],
            ['燕山大学', 'B+', null],
            ['长安大学', 'C-', null],
            ['长春工业大学', 'C+', null],
            ['长春理工大学', 'C+', null],
            ['浙江大学', 'A', '材料科学与工程'],
            ['浙江工业大学', 'C+', null],
            ['浙江理工大学', 'C', null],
            ['郑州大学', 'B+', '材料科学与工程（自定）'],
            ['中北大学', 'B-', null],
            ['中国地质大学', 'B-', null],
            ['中国海洋大学', 'C', null],
            ['中国计量大学', 'C', null],
            ['中国科学技术大学', 'A-', '材料科学与工程'],
            ['中国矿业大学', 'C', null],
            ['中国石油大学', 'B-', null],
            ['中南大学', 'A-', '材料科学与工程'],
            ['中山大学', 'B', '材料科学与工程'],
            ['重庆大学', 'B+', null],
            ['重庆理工大学', 'C-', null],
            ['北京大学', null, '材料科学与工程'],
            ['东北师范大学', null, '材料科学与工程'],
            ['中国科学院大学', null, '材料科学与工程']
          ]
        ],
        [
          '草学',
          [
            ['北京林业大学', 'C+', null],
            ['东北农业大学', 'C+', null],
            ['甘肃农业大学', 'B+', null],
            ['河南农业大学', 'C-', null],
            ['湖南农业大学', 'C', null],
            ['华南农业大学', 'B-', null],
            ['兰州大学', 'A+', '草学'],
            ['南京农业大学', 'C+', null],
            ['内蒙古农业大学', 'B+', null],
            ['宁夏大学', 'B-', null],
            ['青海大学', 'B-', null],
            ['山西农业大学', 'C-', null],
            ['四川农业大学', 'B', null],
            ['西北农林科技大学', 'B+', null],
            ['新疆农业大学', 'B', null],
            ['扬州大学', 'C-', null],
            ['云南农业大学', 'C', null],
            ['中国农业大学', 'A+', '草学']
          ]
        ],
        [
          '测绘科学与技术',
          [
            ['北京大学', 'B+', null],
            ['北京建筑大学', 'C+', null],
            ['北京师范大学', 'C+', null],
            ['东北大学', 'C', null],
            ['东华理工大学', 'C', null],
            ['东南大学', 'C+', null],
            ['桂林理工大学', 'C-', null],
            ['河海大学', 'B-', null],
            ['河南理工大学', 'B-', null],
            ['江西理工大学', 'C-', null],
            ['解放军信息工程大学', 'A+', null],
            ['昆明理工大学', 'C-', null],
            ['辽宁工程技术大学', 'B-', null],
            ['山东科技大学', 'B', null],
            ['同济大学', 'B+', '测绘科学与技术'],
            ['武汉大学', 'A+', '测绘科学与技术'],
            ['西安科技大学', 'C+', null],
            ['西南交通大学', 'B', null],
            ['长安大学', 'B', null],
            ['中国地质大学', 'B', null],
            ['中国矿业大学', 'A-', null],
            ['中国石油大学', 'C', null],
            ['中南大学', 'B+', null]
          ]
        ],
        [
          '城乡规划学',
          [
            ['安徽建筑大学', 'B-', null],
            ['北京工业大学', 'C', null],
            ['北京建筑大学', 'B-', null],
            ['北京交通大学', 'C', null],
            ['北京林业大学', 'C-', null],
            ['大连理工大学', 'B', null],
            ['东南大学', 'A-', null],
            ['福州大学', 'C', null],
            ['哈尔滨工业大学', 'A-', null],
            ['合肥工业大学', 'C+', null],
            ['湖南大学', 'B', null],
            ['华南理工大学', 'B+', null],
            ['华中科技大学', 'B+', null],
            ['吉林建筑大学', 'C-', null],
            ['昆明理工大学', 'C-', null],
            ['南京大学', 'B+', null],
            ['南京工业大学', 'C+', null],
            ['清华大学', 'A+', '城乡规划学'],
            ['山东建筑大学', 'B-', null],
            ['深圳大学', 'B-', null],
            ['沈阳建筑大学', 'B', null],
            ['四川大学', 'C+', null],
            ['苏州科技大学', 'B', null],
            ['天津城建大学', 'C', null],
            ['天津大学', 'A-', null],
            ['同济大学', 'A+', '城乡规划学'],
            ['武汉大学', 'B', null],
            ['武汉理工大学', 'C-', null],
            ['西安建筑科技大学', 'B+', null],
            ['西北大学', 'C+', null],
            ['西南交通大学', 'C+', null],
            ['长安大学', 'B-', null],
            ['郑州大学', 'C-', null],
            ['中国矿业大学', 'C', null],
            ['重庆大学', 'B+', null]
          ]
        ],
        [
          '畜牧学',
          [
            ['安徽农业大学', 'C', null],
            ['东北农业大学', 'B', '畜牧学（自定）'],
            ['福建农林大学', 'C', null],
            ['甘肃农业大学', 'B-', null],
            ['广西大学', 'C+', null],
            ['河北农业大学', 'C+', null],
            ['河南农业大学', 'C+', null],
            ['湖南农业大学', 'B-', null],
            ['华南农业大学', 'B+', null],
            ['华中农业大学', 'A+', '畜牧学'],
            ['吉林大学', 'B-', null],
            ['吉林农业大学', 'C+', null],
            ['江西农业大学', 'B', null],
            ['兰州大学', 'C-', null],
            ['南京农业大学', 'B+', null],
            ['内蒙古农业大学', 'B', null],
            ['青岛农业大学', 'C-', null],
            ['山东农业大学', 'B-', null],
            ['山西农业大学', 'C+', null],
            ['上海交通大学', 'C-', null],
            ['石河子大学', 'C', null],
            ['四川农业大学', 'A-', null],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'B+', null],
            ['西南民族大学', 'C-', null],
            ['新疆农业大学', 'C', null],
            ['扬州大学', 'B', null],
            ['云南农业大学', 'B', null],
            ['浙江大学', 'A-', null],
            ['中国农业大学', 'A+', '畜牧学']
          ]
        ],
        [
          '船舶与海洋工程',
          [
            ['大连海事大学', 'B', null],
            ['大连理工大学', 'C+', null],
            ['哈尔滨工程大学', 'A+', '船舶与海洋工程'],
            ['海军工程大学', 'B+', null],
            ['华中科技大学', 'C+', null],
            ['江苏科技大学', 'C', null],
            ['上海海事大学', 'C-', null],
            ['上海交通大学', 'A+', '船舶与海洋工程'],
            ['天津大学', 'B', null],
            ['武汉理工大学', 'B-', null],
            ['西北工业大学', 'B-', null],
            ['浙江大学', 'C-', null]
          ]
        ],
        [
          '大气科学',
          [
            ['北京大学', 'A+', null],
            ['解放军理工大学', 'B', null],
            ['兰州大学', 'B-', '大气科学'],
            ['南京大学', 'B', '大气科学'],
            ['南京信息工程大学', 'A+', '大气科学'],
            ['清华大学', 'C+', null],
            ['中国海洋大学', 'C+', null],
            ['中国科学技术大学', 'C-', null],
            ['中山大学', 'C', null]
          ]
        ],
        [
          '地理学',
          [
            ['安徽师范大学', 'C+', null],
            ['北京大学', 'A+', '地理学'],
            ['北京师范大学', 'A+', '地理学'],
            ['东北师范大学', 'B+', null],
            ['福建师范大学', 'B+', null],
            ['广州大学', 'C-', null],
            ['贵州师范大学', 'B-', null],
            ['哈尔滨师范大学', 'B-', null],
            ['河北师范大学', 'C+', null],
            ['河海大学', 'C', null],
            ['河南大学', 'B+', null],
            ['湖北大学', 'C', null],
            ['湖南师范大学', 'B', null],
            ['华东师范大学', 'A', null],
            ['华南师范大学', 'B', null],
            ['华中师范大学', 'B', null],
            ['江苏师范大学', 'C', null],
            ['江西师范大学', 'C', null],
            ['兰州大学', 'B+', null],
            ['辽宁师范大学', 'B-', null],
            ['南京大学', 'A-', null],
            ['南京师范大学', 'A-', '地理学'],
            ['南京信息工程大学', 'C-', null],
            ['内蒙古师范大学', 'C-', null],
            ['青海师范大学', 'C+', null],
            ['山东师范大学', 'B-', null],
            ['陕西师范大学', 'B', null],
            ['上海师范大学', 'C-', null],
            ['首都师范大学', 'B+', null],
            ['四川师范大学', 'C-', null],
            ['天津师范大学', 'C', null],
            ['武汉大学', 'A-', null],
            ['西北大学', 'B', null],
            ['西北师范大学', 'B-', null],
            ['西南大学', 'B-', null],
            ['新疆大学', 'C+', null],
            ['云南大学', 'C-', null],
            ['云南师范大学', 'B+', null],
            ['浙江师范大学', 'C+', null],
            ['中国地质大学', 'C', null],
            ['中国海洋大学', 'C+', null],
            ['中山大学', 'B+', null],
            ['重庆师范大学', 'C-', null]
          ]
        ],
        [
          '地球物理学',
          [
            ['北京大学', 'B', '地球物理学'],
            ['成都理工大学', 'C-', null],
            ['吉林大学', 'C', null],
            ['同济大学', 'C+', null],
            ['武汉大学', 'A+', '地球物理学'],
            ['长江大学', 'C-', null],
            ['中国地质大学', 'B-', null],
            ['中国科学技术大学', 'A+', '地球物理学'],
            ['中国石油大学', 'B-', null]
          ]
        ],
        [
          '地质学',
          [
            ['北京大学', 'B+', '地质学'],
            ['成都理工大学', 'B-', '地质学'],
            ['合肥工业大学', 'C+', null],
            ['吉林大学', 'B', null],
            ['兰州大学', 'C-', null],
            ['南京大学', 'A+', '地质学'],
            ['西北大学', 'B+', '地质学'],
            ['浙江大学', 'C+', null],
            ['中国地质大学', 'A+', '地质学'],
            ['中国海洋大学', 'C-', null],
            ['中国科学技术大学', 'B', null],
            ['中国矿业大学', 'C+', null],
            ['中国石油大学', 'B-', null],
            ['中南大学', 'C', null],
            ['中山大学', 'C', null],
            ['中国地质大学(武汉)', null, '地质学']
          ]
        ],
        [
          '地质资源与地质工程',
          [
            ['安徽理工大学', 'C-', null],
            ['成都理工大学', 'B+', null],
            ['东北大学', 'C-', null],
            ['东北石油大学', 'B-', null],
            ['东华理工大学', 'C', null],
            ['桂林理工大学', 'C', null],
            ['河海大学', 'B-', null],
            ['河南理工大学', 'C+', null],
            ['华北水利水电大学', 'C+', null],
            ['吉林大学', 'B+', null],
            ['昆明理工大学', 'C', null],
            ['南京大学', 'B', null],
            ['山东科技大学', 'B-', null],
            ['太原理工大学', 'C-', null],
            ['同济大学', 'B', null],
            ['西安科技大学', 'C+', null],
            ['西北大学', 'B', null],
            ['西南交通大学', 'C+', null],
            ['西南石油大学', 'B', null],
            ['长安大学', 'B+', null],
            ['长江大学', 'B-', null],
            ['中国地质大学', 'A+', '地质资源与地质工程'],
            ['中国海洋大学', 'C-', null],
            ['中国矿业大学', 'A-', null],
            ['中国石油大学', 'A+', '地质资源与地质工程'],
            ['中南大学', 'B+', null],
            ['中国石油大学(华东)', null, '地质资源与地质工程'],
            ['中国地质大学(武汉)', null, '地质资源与地质工程']
          ]
        ],
        [
          '电气工程',
          [
            ['北京航空航天大学', 'C+', null],
            ['北京交通大学', 'B', null],
            ['大连理工大学', 'B-', null],
            ['电子科技大学', 'C', null],
            ['东北大学', 'C+', null],
            ['东北电力大学', 'B', null],
            ['东南大学', 'B+', null],
            ['福州大学', 'B-', null],
            ['广西大学', 'C+', null],
            ['哈尔滨工程大学', 'C-', null],
            ['哈尔滨工业大学', 'A-', null],
            ['哈尔滨理工大学', 'B', null],
            ['海军工程大学', 'A-', null],
            ['合肥工业大学', 'B', null],
            ['河北工业大学', 'B', '电气工程（自定）'],
            ['河海大学', 'B-', null],
            ['河南理工大学', 'C-', null],
            ['黑龙江科技大学', 'C-', null],
            ['湖南大学', 'B+', null],
            ['湖南工业大学', 'C-', null],
            ['华北电力大学', 'A', '电气工程（自定）'],
            ['华南理工大学', 'B', null],
            ['华中科技大学', 'A', '电气工程'],
            ['江苏大学', 'C+', null],
            ['辽宁工程技术大学', 'C', null],
            ['南京航空航天大学', 'B+', null],
            ['南京理工大学', 'C', null],
            ['清华大学', 'A+', '电气工程'],
            ['三峡大学', 'B-', null],
            ['山东大学', 'B+', null],
            ['山东科技大学', 'C-', null],
            ['山东理工大学', 'C', null],
            ['上海大学', 'C+', null],
            ['上海电力大学', 'B-', null],
            ['上海海事大学', 'C+', null],
            ['上海交通大学', 'B+', null],
            ['上海理工大学', 'C-', null],
            ['沈阳工业大学', 'B+', null],
            ['四川大学', 'B-', null],
            ['太原理工大学', 'B-', null],
            ['天津大学', 'B+', null],
            ['天津理工大学', 'C-', null],
            ['同济大学', 'C+', null],
            ['武汉大学', 'B', null],
            ['西安交通大学', 'A+', '电气工程'],
            ['西安理工大学', 'C+', null],
            ['西北工业大学', 'B', null],
            ['西南交通大学', 'B+', null],
            ['新疆大学', 'C+', null],
            ['燕山大学', 'C+', null],
            ['长沙理工大学', 'B-', null],
            ['浙江大学', 'A-', '电气工程'],
            ['郑州大学', 'C', null],
            ['郑州轻工业学院', 'C', null],
            ['中国矿业大学', 'B', null],
            ['中国农业大学', 'C-', null],
            ['中南大学', 'C', null],
            ['重庆大学', 'A-', '电气工程（自定）']
          ]
        ],
        [
          '电子科学与技术',
          [
            ['安徽大学', 'B-', null],
            ['北方工业大学', 'C-', null],
            ['北京大学', 'A', '电子科学与技术'],
            ['北京工业大学', 'B', null],
            ['北京航空航天大学', 'B+', null],
            ['北京交通大学', 'B-', null],
            ['北京理工大学', 'B+', null],
            ['北京邮电大学', 'A-', null],
            ['大连理工大学', 'B-', null],
            ['电子科技大学', 'A+', '电子科学与技术'],
            ['东南大学', 'A', '电子科学与技术'],
            ['福州大学', 'B-', null],
            ['复旦大学', 'A-', null],
            ['贵州大学', 'C', null],
            ['桂林电子科技大学', 'C', null],
            ['国防科技大学', 'B+', null],
            ['哈尔滨工程大学', 'C-', null],
            ['哈尔滨工业大学', 'B', null],
            ['海军航空工程学院', 'C', null],
            ['杭州电子科技大学', 'B+', null],
            ['合肥工业大学', 'B-', null],
            ['河北大学', 'C-', null],
            ['河北工业大学', 'C+', null],
            ['黑龙江大学', 'C+', null],
            ['湖北大学', 'C', null],
            ['湖南大学', 'B-', null],
            ['华北电力大学', 'C-', null],
            ['华东师范大学', 'B', null],
            ['华南理工大学', 'B', null],
            ['华中科技大学', 'B+', null],
            ['吉林大学', 'B+', null],
            ['解放军理工大学', 'B-', null],
            ['解放军信息工程大学', 'C+', null],
            ['空军工程大学', 'B+', null],
            ['兰州大学', 'C+', null],
            ['南京大学', 'A-', null],
            ['南京航空航天大学', 'C', null],
            ['南京理工大学', 'B', null],
            ['南京邮电大学', 'B+', '电子科学与技术'],
            ['南开大学', 'B', null],
            ['清华大学', 'A', null],
            ['厦门大学', 'B', null],
            ['山东大学', 'B-', null],
            ['上海大学', 'C+', null],
            ['上海交通大学', 'A-', null],
            ['深圳大学', 'C-', null],
            ['四川大学', 'C', null],
            ['苏州大学', 'C-', null],
            ['太原理工大学', 'C+', null],
            ['天津大学', 'B+', null],
            ['天津工业大学', 'C', null],
            ['天津理工大学', 'C', null],
            ['武汉大学', 'B', null],
            ['武汉理工大学', 'C-', null],
            ['西安电子科技大学', 'A+', null],
            ['西安交通大学', 'A-', null],
            ['西安理工大学', 'B-', null],
            ['西安邮电大学', 'C', null],
            ['西北大学', 'C-', null],
            ['西北工业大学', 'B+', null],
            ['西南交通大学', 'B-', null],
            ['燕山大学', 'C+', null],
            ['长春理工大学', 'C+', null],
            ['长沙理工大学', 'C', null],
            ['浙江大学', 'A-', null],
            ['郑州大学', 'C-', null],
            ['中北大学', 'C-', null],
            ['中国传媒大学', 'C+', null],
            ['中国计量大学', 'C-', null],
            ['中国科学技术大学', 'B', null],
            ['中南大学', 'C+', null],
            ['中山大学', 'B', '电子科学与技术'],
            ['重庆大学', 'B-', null],
            ['重庆邮电大学', 'C+', null]
          ]
        ],
        [
          '动力工程及工程热物理',
          [
            ['北京工业大学', 'B-', null],
            ['北京航空航天大学', 'B+', null],
            ['北京化工大学', 'B-', null],
            ['北京交通大学', 'C', null],
            ['北京科技大学', 'B', null],
            ['北京理工大学', 'B+', null],
            ['大连理工大学', 'B+', null],
            ['东北大学', 'B-', null],
            ['东北电力大学', 'B-', null],
            ['东北石油大学', 'C', null],
            ['东南大学', 'B+', null],
            ['哈尔滨工程大学', 'B+', null],
            ['哈尔滨工业大学', 'A-', null],
            ['海军工程大学', 'B', null],
            ['合肥工业大学', 'C-', null],
            ['河北工业大学', 'C', null],
            ['河海大学', 'C-', null],
            ['华北电力大学', 'A-', null],
            ['华北水利水电大学', 'C-', null],
            ['华东理工大学', 'B+', null],
            ['华南理工大学', 'C+', null],
            ['华中科技大学', 'A-', '动力工程及工程热物理'],
            ['吉林大学', 'B-', null],
            ['江苏大学', 'B+', null],
            ['昆明理工大学', 'C-', null],
            ['兰州理工大学', 'C+', null],
            ['辽宁石油化工大学', 'C-', null],
            ['南京工业大学', 'B', null],
            ['南京航空航天大学', 'B', null],
            ['南京理工大学', 'C+', null],
            ['内蒙古工业大学', 'C', null],
            ['青岛科技大学', 'C+', null],
            ['清华大学', 'A+', '动力工程及工程热物理'],
            ['山东大学', 'B', null],
            ['上海电力大学', 'C+', null],
            ['上海交通大学', 'A', null],
            ['上海理工大学', 'B+', null],
            ['沈阳航空航天大学', 'C', null],
            ['沈阳化工大学', 'C-', null],
            ['四川大学', 'C+', null],
            ['太原理工大学', 'C-', null],
            ['天津大学', 'A-', null],
            ['天津商业大学', 'C-', null],
            ['同济大学', 'B', null],
            ['武汉大学', 'B-', null],
            ['武汉工程大学', 'C-', null],
            ['武汉理工大学', 'C+', null],
            ['西安交通大学', 'A+', '动力工程及工程热物理'],
            ['西北工业大学', 'B-', null],
            ['西华大学', 'C', null],
            ['长沙理工大学', 'C', null],
            ['浙江大学', 'A', null],
            ['浙江工业大学', 'C+', null],
            ['郑州大学', 'C+', null],
            ['中国科学技术大学', 'B+', null],
            ['中国矿业大学', 'C', null],
            ['中国石油大学', 'B', null],
            ['中南大学', 'B-', null],
            ['重庆大学', 'B', null]
          ]
        ],
        [
          '法学',
          [
            ['安徽财经大学', 'C-', null],
            ['安徽大学', 'B', null],
            ['北方工业大学', 'C-', null],
            ['北京大学', 'A', '法学'],
            ['北京工商大学', 'C-', null],
            ['北京航空航天大学', 'B+', null],
            ['北京交通大学', 'C', null],
            ['北京理工大学', 'C+', null],
            ['北京师范大学', 'B+', null],
            ['北京外国语大学', 'C+', null],
            ['大连海事大学', 'B', null],
            ['东北财经大学', 'C', null],
            ['对外经济贸易大学', 'A-', null],
            ['福建师范大学', 'C', null],
            ['福州大学', 'B-', null],
            ['复旦大学', 'B+', null],
            ['甘肃政法学院', 'B-', null],
            ['广东财经大学', 'B-', null],
            ['广东外语外贸大学', 'C+', null],
            ['广西大学', 'C-', null],
            ['广西师范大学', 'C-', null],
            ['广州大学', 'C+', null],
            ['贵州大学', 'C+', null],
            ['海南大学', 'B', null],
            ['杭州师范大学', 'C-', null],
            ['河北大学', 'C+', null],
            ['河北经贸大学', 'C', null],
            ['河海大学', 'C', null],
            ['河南财经政法大学', 'C', null],
            ['河南大学', 'B-', null],
            ['黑龙江大学', 'B', null],
            ['湖南大学', 'B', null],
            ['湖南师范大学', 'B', null],
            ['华北电力大学', 'C-', null],
            ['华东理工大学', 'C', null],
            ['华东政法大学', 'A', null],
            ['华南理工大学', 'B-', null],
            ['华南师范大学', 'C-', null],
            ['华侨大学', 'C+', null],
            ['华中科技大学', 'B-', null],
            ['华中师范大学', 'C+', null],
            ['吉林大学', 'A-', null],
            ['暨南大学', 'B-', null],
            ['江西财经大学', 'B', null],
            ['昆明理工大学', 'C', null],
            ['兰州大学', 'B-', null],
            ['辽宁大学', 'B+', null],
            ['南京财经大学', 'C-', null],
            ['南京大学', 'A-', null],
            ['南京师范大学', 'B+', null],
            ['南开大学', 'B+', null],
            ['内蒙古大学', 'C+', null],
            ['宁波大学', 'C+', null],
            ['青岛大学', 'C-', null],
            ['清华大学', 'A', '法学'],
            ['厦门大学', 'A-', null],
            ['山东大学', 'B+', null],
            ['山西财经大学', 'C', null],
            ['山西大学', 'C+', null],
            ['上海财经大学', 'B', null],
            ['上海大学', 'C+', null],
            ['上海对外经贸大学', 'C+', null],
            ['上海海事大学', 'C+', null],
            ['上海交通大学', 'A-', null],
            ['上海师范大学', 'C-', null],
            ['上海政法学院', 'B-', null],
            ['深圳大学', 'B-', null],
            ['沈阳师范大学', 'C', null],
            ['首都师范大学', 'C', null],
            ['四川大学', 'B+', null],
            ['苏州大学', 'B+', null],
            ['天津师范大学', 'C', null],
            ['同济大学', 'B', null],
            ['武汉大学', 'A', '法学'],
            ['武汉理工大学', 'C-', null],
            ['西安交通大学', 'B-', null],
            ['西北政法大学', 'B+', null],
            ['西南财经大学', 'B', null],
            ['西南民族大学', 'C', null],
            ['西南政法大学', 'A', null],
            ['湘潭大学', 'B+', null],
            ['新疆大学', 'C+', null],
            ['烟台大学', 'B', null],
            ['扬州大学', 'C', null],
            ['云南大学', 'B-', null],
            ['浙江财经大学', 'C-', null],
            ['浙江大学', 'A-', null],
            ['浙江工商大学', 'B-', null],
            ['郑州大学', 'B', null],
            ['中国海洋大学', 'B', null],
            ['中国人民大学', 'A+', '法学'],
            ['中国人民公安大学', 'B', null],
            ['中国政法大学', 'A+', '法学'],
            ['中南财经政法大学', 'A-', '法学（自定）'],
            ['中南大学', 'B+', null],
            ['中南民族大学', 'C', null],
            ['中山大学', 'B+', null],
            ['中央财经大学', 'B', null],
            ['中央民族大学', 'B-', null],
            ['重庆大学', 'B+', null]
          ]
        ],
        [
          '纺织科学与工程',
          [
            ['大连工业大学', 'C', null],
            ['东华大学', 'A+', '纺织科学与工程'],
            ['江南大学', 'B', null],
            ['青岛大学', 'C+', null],
            ['四川大学', 'C-', null],
            ['苏州大学', 'B+', null],
            ['天津工业大学', 'A+', '纺织科学与工程'],
            ['武汉纺织大学', 'B-', null],
            ['西安工程大学', 'C+', null],
            ['浙江理工大学', 'B', null],
            ['中原工学院', 'C', null]
          ]
        ],
        [
          '风景园林学',
          [
            ['安徽农业大学', 'C-', null],
            ['北华大学', 'C', null],
            ['北京建筑大学', 'C', null],
            ['北京林业大学', 'A+', '风景园林学'],
            ['东北林业大学', 'B+', null],
            ['东北农业大学', 'C+', null],
            ['东南大学', 'A-', '风景园林学'],
            ['福建农林大学', 'B+', null],
            ['哈尔滨工业大学', 'B', null],
            ['海南大学', 'C-', null],
            ['河北农业大学', 'C+', null],
            ['河南农业大学', 'B-', null],
            ['华南理工大学', 'B+', null],
            ['华南农业大学', 'B-', null],
            ['华中科技大学', 'B-', null],
            ['华中农业大学', 'B+', null],
            ['江西农业大学', 'C', null],
            ['南京林业大学', 'A-', null],
            ['南京农业大学', 'C+', null],
            ['青岛理工大学', 'C-', null],
            ['青岛农业大学', 'C-', null],
            ['清华大学', 'A+', '风景园林学'],
            ['上海交通大学', 'B-', null],
            ['沈阳建筑大学', 'B-', null],
            ['沈阳农业大学', 'C', null],
            ['四川大学', 'C', null],
            ['四川农业大学', 'B', null],
            ['苏州大学', 'C+', null],
            ['苏州科技大学', 'C', null],
            ['天津大学', 'B+', null],
            ['同济大学', 'A-', '风景园林学'],
            ['西安建筑科技大学', 'B+', null],
            ['西北农林科技大学', 'B', null],
            ['西南大学', 'C+', null],
            ['西南林业大学', 'B-', null],
            ['长江大学', 'C-', null],
            ['浙江农林大学', 'B', null],
            ['中南林业科技大学', 'B-', null],
            ['重庆大学', 'B', null]
          ]
        ],
        [
          '工商管理',
          [
            ['安徽财经大学', 'B-', null],
            ['安徽工业大学', 'C', null],
            ['北方工业大学', 'C+', null],
            ['北京大学', 'A', null],
            ['北京第二外国语学院', 'B', null],
            ['北京工商大学', 'B', null],
            ['北京航空航天大学', 'B', null],
            ['北京化工大学', 'C+', null],
            ['北京交通大学', 'A-', null],
            ['北京科技大学', 'B', null],
            ['北京理工大学', 'B+', null],
            ['北京联合大学', 'C+', null],
            ['北京林业大学', 'C', null],
            ['北京师范大学', 'B', null],
            ['北京物资学院', 'C+', null],
            ['北京邮电大学', 'B-', null],
            ['大连海事大学', 'C+', null],
            ['大连理工大学', 'A-', null],
            ['电子科技大学', 'B+', null],
            ['东北财经大学', 'A-', null],
            ['东北大学', 'B', null],
            ['东北石油大学', 'C-', null],
            ['东华大学', 'B', null],
            ['东华理工大学', 'C+', null],
            ['东南大学', 'B', null],
            ['对外经济贸易大学', 'A', null],
            ['福建农林大学', 'C-', null],
            ['福州大学', 'B+', null],
            ['复旦大学', 'A', null],
            ['广东财经大学', 'B-', null],
            ['广东工业大学', 'C', null],
            ['广东外语外贸大学', 'B-', null],
            ['广西大学', 'C+', null],
            ['广州大学', 'C+', null],
            ['贵州财经大学', 'C+', null],
            ['桂林理工大学', 'C', null],
            ['哈尔滨工程大学', 'C+', null],
            ['哈尔滨工业大学', 'B+', null],
            ['哈尔滨理工大学', 'B', null],
            ['哈尔滨商业大学', 'B', null],
            ['海南大学', 'B', null],
            ['杭州电子科技大学', 'B-', null],
            ['合肥工业大学', 'B+', null],
            ['河北大学', 'C-', null],
            ['河北地质大学', 'C-', null],
            ['河北工业大学', 'B', null],
            ['河北经贸大学', 'C+', null],
            ['河海大学', 'B+', null],
            ['河南财经政法大学', 'B-', null],
            ['河南大学', 'B-', null],
            ['河南理工大学', 'C-', null],
            ['湖北大学', 'C+', null],
            ['湖北工业大学', 'C+', null],
            ['湖南大学', 'A-', null],
            ['湖南工业大学', 'C-', null],
            ['湖南商学院', 'C-', null],
            ['华北电力大学', 'B+', null],
            ['华北水利水电大学', 'C', null],
            ['华东理工大学', 'B-', null],
            ['华东师范大学', 'B', null],
            ['华南理工大学', 'B+', null],
            ['华侨大学', 'B', null],
            ['华中科技大学', 'A-', null],
            ['华中农业大学', 'C', null],
            ['吉林财经大学', 'B-', null],
            ['吉林大学', 'A-', null],
            ['济南大学', 'C+', null],
            ['暨南大学', 'B+', null],
            ['江南大学', 'C+', null],
            ['江苏大学', 'C', null],
            ['江苏科技大学', 'C', null],
            ['江西财经大学', 'B+', null],
            ['江西理工大学', 'C-', null],
            ['昆明理工大学', 'C-', null],
            ['兰州财经大学', 'C-', null],
            ['兰州大学', 'B', null],
            ['辽宁大学', 'B+', null],
            ['辽宁科技大学', 'C-', null],
            ['南昌大学', 'C', null],
            ['南京财经大学', 'B', null],
            ['南京大学', 'A', null],
            ['南京工业大学', 'C', null],
            ['南京航空航天大学', 'B', null],
            ['南京理工大学', 'C+', null],
            ['南京农业大学', 'C', null],
            ['南京审计大学', 'B', null],
            ['南京信息工程大学', 'C-', null],
            ['南京邮电大学', 'C', null],
            ['南开大学', 'A', null],
            ['内蒙古财经大学', 'B-', null],
            ['齐鲁工业大学', 'C-', null],
            ['青岛大学', 'C', null],
            ['清华大学', 'A+', '工商管理'],
            ['三峡大学', 'C', null],
            ['厦门大学', 'A', null],
            ['山东财经大学', 'B+', null],
            ['山东大学', 'A-', null],
            ['山西财经大学', 'B+', null],
            ['山西大学', 'C', null],
            ['汕头大学', 'C', null],
            ['上海财经大学', 'A', null],
            ['上海大学', 'B-', null],
            ['上海对外经贸大学', 'B-', null],
            ['上海工程技术大学', 'C+', null],
            ['上海海事大学', 'C', null],
            ['上海交通大学', 'A+', null],
            ['上海理工大学', 'C', null],
            ['上海外国语大学', 'B-', null],
            ['深圳大学', 'B-', null],
            ['沈阳大学', 'C-', null],
            ['沈阳航空航天大学', 'C', null],
            ['沈阳理工大学', 'C-', null],
            ['石河子大学', 'C+', null],
            ['首都经济贸易大学', 'B+', null],
            ['四川大学', 'A-', null],
            ['四川师范大学', 'C-', null],
            ['苏州大学', 'B', null],
            ['天津财经大学', 'B+', null],
            ['天津大学', 'B+', null],
            ['天津工业大学', 'C-', null],
            ['天津科技大学', 'C-', null],
            ['天津理工大学', 'C', null],
            ['天津商业大学', 'C', null],
            ['同济大学', 'B+', null],
            ['武汉大学', 'A-', null],
            ['武汉科技大学', 'C', null],
            ['武汉理工大学', 'B-', null],
            ['西安财经学院', 'C+', null],
            ['西安交通大学', 'A', '工商管理'],
            ['西安理工大学', 'B-', null],
            ['西北工业大学', 'B-', null],
            ['西华大学', 'C-', null],
            ['西南财经大学', 'A-', null],
            ['西南大学', 'C+', null],
            ['西南交通大学', 'B+', null],
            ['西南石油大学', 'C-', null],
            ['西南政法大学', 'C', null],
            ['湘潭大学', 'C', null],
            ['新疆财经大学', 'B-', null],
            ['燕山大学', 'C', null],
            ['扬州大学', 'C', null],
            ['云南财经大学', 'B', null],
            ['云南大学', 'B', null],
            ['长安大学', 'C', null],
            ['长沙理工大学', 'B', null],
            ['浙江财经大学', 'B', null],
            ['浙江大学', 'A-', null],
            ['浙江工商大学', 'B+', null],
            ['浙江工业大学', 'B+', null],
            ['浙江师范大学', 'B-', null],
            ['郑州大学', 'C+', null],
            ['中国地质大学', 'B-', null],
            ['中国海洋大学', 'B', null],
            ['中国科学技术大学', 'B+', null],
            ['中国矿业大学', 'B-', null],
            ['中国农业大学', 'B-', null],
            ['中国人民大学', 'A+', '工商管理'],
            ['中国石油大学', 'C+', null],
            ['中国药科大学', 'C-', null],
            ['中南财经政法大学', 'B+', null],
            ['中南大学', 'B+', null],
            ['中南林业科技大学', 'C-', null],
            ['中南民族大学', 'C+', null],
            ['中山大学', 'A+', '工商管理'],
            ['中央财经大学', 'A-', null],
            ['中央民族大学', 'C+', null],
            ['重庆大学', 'B+', null],
            ['重庆工商大学', 'B-', null],
            ['重庆交通大学', 'C-', null],
            ['重庆理工大学', 'B-', null]
          ]
        ],
        [
          '公共管理',
          [
            ['安徽财经大学', 'C-', null],
            ['安徽医科大学', 'C-', null],
            ['北京大学', 'A', null],
            ['北京航空航天大学', 'A-', null],
            ['北京科技大学', 'C+', null],
            ['北京理工大学', 'C', null],
            ['北京师范大学', 'A-', null],
            ['北京协和医学院', 'C-', null],
            ['北京邮电大学', 'C+', null],
            ['大连海事大学', 'C-', null],
            ['大连理工大学', 'B', null],
            ['第二军医大学', 'B', null],
            ['电子科技大学', 'B', null],
            ['东北财经大学', 'B', null],
            ['东北大学', 'B+', null],
            ['东北农业大学', 'C-', null],
            ['东南大学', 'B-', null],
            ['对外经济贸易大学', 'B', null],
            ['福建农林大学', 'C+', null],
            ['福州大学', 'C', null],
            ['复旦大学', 'A-', null],
            ['广西大学', 'B-', null],
            ['广州大学', 'C', null],
            ['贵州财经大学', 'C-', null],
            ['贵州大学', 'C', null],
            ['哈尔滨工程大学', 'C', null],
            ['哈尔滨工业大学', 'B', null],
            ['哈尔滨商业大学', 'C-', null],
            ['哈尔滨医科大学', 'B', null],
            ['杭州师范大学', 'B-', null],
            ['河北大学', 'C', null],
            ['河海大学', 'B-', null],
            ['河南大学', 'C-', null],
            ['河南理工大学', 'C+', null],
            ['黑龙江大学', 'C+', null],
            ['湖北大学', 'C-', null],
            ['湖南大学', 'B-', null],
            ['湖南农业大学', 'C+', null],
            ['华北电力大学', 'C', null],
            ['华东理工大学', 'C+', null],
            ['华东师范大学', 'B+', null],
            ['华南理工大学', 'B-', null],
            ['华南农业大学', 'C', null],
            ['华中科技大学', 'A-', null],
            ['华中农业大学', 'B+', null],
            ['华中师范大学', 'B', null],
            ['吉林大学', 'B+', null],
            ['暨南大学', 'C+', null],
            ['江苏大学', 'C-', null],
            ['江西财经大学', 'B-', null],
            ['江西农业大学', 'C', null],
            ['兰州大学', 'B+', null],
            ['辽宁大学', 'B-', null],
            ['南昌大学', 'B-', null],
            ['南京大学', 'B+', null],
            ['南京农业大学', 'A', null],
            ['南京中医药大学', 'C-', null],
            ['南开大学', 'B+', null],
            ['清华大学', 'A+', '公共管理'],
            ['厦门大学', 'B+', null],
            ['山东财经大学', 'B-', null],
            ['山东大学', 'B', null],
            ['上海财经大学', 'B', null],
            ['上海交通大学', 'A-', null],
            ['上海理工大学', 'C', null],
            ['沈阳师范大学', 'C', null],
            ['首都经济贸易大学', 'C+', null],
            ['四川大学', 'A-', null],
            ['苏州大学', 'C', null],
            ['天津财经大学', 'C', null],
            ['天津大学', 'B+', null],
            ['同济大学', 'B+', null],
            ['武汉大学', 'A', null],
            ['武汉科技大学', 'C+', null],
            ['西安交通大学', 'A-', null],
            ['西北工业大学', 'C-', null],
            ['西南财经大学', 'B-', null],
            ['西南大学', 'C-', null],
            ['西南交通大学', 'C+', null],
            ['西南政法大学', 'C-', null],
            ['湘潭大学', 'B', null],
            ['新疆农业大学', 'C-', null],
            ['燕山大学', 'C-', null],
            ['云南财经大学', 'C', null],
            ['云南大学', 'C+', null],
            ['长安大学', 'C', null],
            ['浙江财经大学', 'B-', null],
            ['浙江大学', 'A', null],
            ['浙江工商大学', 'C+', null],
            ['郑州大学', 'B+', null],
            ['中国地质大学', 'B+', null],
            ['中国海洋大学', 'C+', null],
            ['中国科学技术大学', 'B', null],
            ['中国矿业大学', 'B', null],
            ['中国农业大学', 'B+', null],
            ['中国人民大学', 'A+', '公共管理'],
            ['中南财经政法大学', 'B+', null],
            ['中南大学', 'B', null],
            ['中山大学', 'A', null],
            ['中央财经大学', 'B', null],
            ['中央民族大学', 'B-', null],
            ['重庆大学', 'B-', null]
          ]
        ],
        [
          '公共卫生与预防医学',
          [
            ['安徽医科大学', 'B', null],
            ['北京大学', 'A-', '公共卫生与预防医学'],
            ['北京协和医学院', 'B', null],
            ['第二军医大学', 'B+', null],
            ['第四军医大学', 'B+', null],
            ['复旦大学', 'A-', null],
            ['广西医科大学', 'C+', null],
            ['广州医科大学', 'C-', null],
            ['贵州医科大学', 'C', null],
            ['哈尔滨医科大学', 'A-', null],
            ['河北医科大学', 'C-', null],
            ['华北理工大学', 'C', null],
            ['华中科技大学', 'A+', '公共卫生与预防医学'],
            ['吉林大学', 'B-', null],
            ['昆明医科大学', 'C', null],
            ['南方医科大学', 'B+', null],
            ['南京医科大学', 'A+', null],
            ['青岛大学', 'C-', null],
            ['厦门大学', 'B-', null],
            ['山东大学', 'B', null],
            ['山西医科大学', 'C+', null],
            ['上海交通大学', 'B-', null],
            ['首都医科大学', 'B+', null],
            ['四川大学', 'B', null],
            ['苏州大学', 'C+', null],
            ['天津医科大学', 'B-', null],
            ['同济大学', 'C-', null],
            ['武汉大学', 'C+', null],
            ['武汉科技大学', 'C-', null],
            ['西安交通大学', 'C+', null],
            ['新疆医科大学', 'C', null],
            ['浙江大学', 'B', null],
            ['郑州大学', 'C+', null],
            ['中国医科大学', 'B', null],
            ['中南大学', 'B-', null],
            ['中山大学', 'B+', null],
            ['重庆医科大学', 'C', null]
          ]
        ],
        [
          '管理科学与工程',
          [
            ['安徽财经大学', 'C-', null],
            ['安徽工业大学', 'C', null],
            ['北京大学', 'B-', null],
            ['北京工商大学', 'C-', null],
            ['北京航空航天大学', 'A', null],
            ['北京化工大学', 'C+', null],
            ['北京交通大学', 'B+', null],
            ['北京科技大学', 'B', null],
            ['北京理工大学', 'A-', null],
            ['北京林业大学', 'C-', null],
            ['北京外国语大学', 'C+', null],
            ['北京物资学院', 'C+', null],
            ['北京信息科技大学', 'C', null],
            ['北京邮电大学', 'B', null],
            ['成都理工大学', 'C+', null],
            ['成都信息工程大学', 'C', null],
            ['大连海事大学', 'B-', null],
            ['大连理工大学', 'A-', null],
            ['电子科技大学', 'B+', null],
            ['东北财经大学', 'B+', null],
            ['东北大学', 'B+', null],
            ['东北林业大学', 'C-', null],
            ['东华大学', 'B', null],
            ['东南大学', 'A-', null],
            ['福州大学', 'B+', null],
            ['复旦大学', 'B+', null],
            ['广东财经大学', 'C-', null],
            ['广东工业大学', 'B-', null],
            ['广东外语外贸大学', 'C-', null],
            ['国防科技大学', 'A+', '管理科学与工程'],
            ['哈尔滨工程大学', 'B+', null],
            ['哈尔滨工业大学', 'A', null],
            ['哈尔滨理工大学', 'B', null],
            ['哈尔滨商业大学', 'C-', null],
            ['杭州电子科技大学', 'C+', null],
            ['合肥工业大学', 'A', '管理科学与工程（自定）'],
            ['河北大学', 'C+', null],
            ['河北工业大学', 'B-', null],
            ['河海大学', 'B+', null],
            ['河南农业大学', 'C-', null],
            ['湖南大学', 'B', null],
            ['华北电力大学', 'B+', null],
            ['华北水利水电大学', 'B-', null],
            ['华东理工大学', 'B+', null],
            ['华南理工大学', 'A-', null],
            ['华侨大学', 'C-', null],
            ['华中科技大学', 'B+', null],
            ['华中师范大学', 'C+', null],
            ['吉林大学', 'B-', null],
            ['吉林建筑大学', 'C-', null],
            ['济南大学', 'C-', null],
            ['暨南大学', 'B-', null],
            ['江苏大学', 'B', null],
            ['江苏科技大学', 'B-', null],
            ['江西财经大学', 'B', null],
            ['江西师范大学', 'C+', null],
            ['空军工程大学', 'B', null],
            ['昆明理工大学', 'C+', null],
            ['辽宁工程技术大学', 'C+', null],
            ['辽宁工业大学', 'C-', null],
            ['南昌大学', 'B-', null],
            ['南京财经大学', 'C-', null],
            ['南京大学', 'B+', null],
            ['南京工业大学', 'C', null],
            ['南京航空航天大学', 'A-', null],
            ['南京理工大学', 'B', null],
            ['南京信息工程大学', 'C+', null],
            ['南京邮电大学', 'C', null],
            ['南开大学', 'B', null],
            ['青岛大学', 'C+', null],
            ['清华大学', 'A+', '管理科学与工程'],
            ['三峡大学', 'C', null],
            ['厦门大学', 'B', null],
            ['山东财经大学', 'B-', null],
            ['山东大学', 'B', null],
            ['山东建筑大学', 'C-', null],
            ['山东科技大学', 'B-', null],
            ['山东师范大学', 'C+', null],
            ['山西财经大学', 'C-', null],
            ['山西大学', 'C', null],
            ['上海财经大学', 'B', null],
            ['上海大学', 'B', null],
            ['上海海事大学', 'B', null],
            ['上海交通大学', 'A', null],
            ['上海理工大学', 'B+', null],
            ['深圳大学', 'C+', null],
            ['沈阳工业大学', 'C+', null],
            ['石家庄铁道大学', 'C-', null],
            ['首都经济贸易大学', 'B', null],
            ['四川大学', 'A-', null],
            ['四川理工学院', 'C-', null],
            ['天津财经大学', 'B-', null],
            ['天津城建大学', 'C-', null],
            ['天津大学', 'A', '管理科学与工程'],
            ['天津工业大学', 'C', null],
            ['天津科技大学', 'C', null],
            ['天津理工大学', 'B', null],
            ['同济大学', 'A+', null],
            ['武汉大学', 'B+', null],
            ['武汉纺织大学', 'C', null],
            ['武汉科技大学', 'C', null],
            ['武汉理工大学', 'B', null],
            ['西安电子科技大学', 'B-', null],
            ['西安建筑科技大学', 'B-', null],
            ['西安交通大学', 'A-', '管理科学与工程'],
            ['西安科技大学', 'C-', null],
            ['西安理工大学', 'B+', null],
            ['西北工业大学', 'B+', null],
            ['西南财经大学', 'C+', null],
            ['西南交通大学', 'B+', null],
            ['西南石油大学', 'C', null],
            ['燕山大学', 'B-', null],
            ['云南财经大学', 'C', null],
            ['长沙理工大学', 'C-', null],
            ['浙江大学', 'A', '管理科学与工程'],
            ['浙江工商大学', 'C', null],
            ['浙江工业大学', 'C', null],
            ['浙江理工大学', 'C', null],
            ['郑州大学', 'C', null],
            ['中国地质大学', 'B-', null],
            ['中国计量大学', 'C+', null],
            ['中国科学技术大学', 'A-', null],
            ['中国矿业大学', 'B+', null],
            ['中国人民大学', 'C+', null],
            ['中国石油大学', 'B', null],
            ['中南财经政法大学', 'C', null],
            ['中南大学', 'A-', null],
            ['中山大学', 'B', null],
            ['中央财经大学', 'C+', null],
            ['重庆大学', 'B+', null],
            ['重庆工商大学', 'C-', null],
            ['重庆交通大学', 'B-', null],
            ['重庆邮电大学', 'C', null]
          ]
        ],
        [
          '光学工程',
          [
            ['安徽大学', 'C-', null],
            ['北京工业大学', 'B+', null],
            ['北京航空航天大学', 'B+', null],
            ['北京交通大学', 'B', null],
            ['北京理工大学', 'A-', null],
            ['北京邮电大学', 'B-', null],
            ['大连工业大学', 'C-', null],
            ['大连理工大学', 'C+', null],
            ['电子科技大学', 'A-', null],
            ['东南大学', 'B', null],
            ['福建师范大学', 'C+', null],
            ['复旦大学', 'B', null],
            ['国防科技大学', 'A', null],
            ['哈尔滨工程大学', 'B', null],
            ['哈尔滨工业大学', 'A-', null],
            ['合肥工业大学', 'C-', null],
            ['河北大学', 'B-', null],
            ['华侨大学', 'C', null],
            ['华中科技大学', 'A+', '光学工程'],
            ['暨南大学', 'C+', null],
            ['江南大学', 'C', null],
            ['江苏大学', 'C', null],
            ['江苏师范大学', 'C', null],
            ['解放军理工大学', 'C', null],
            ['南昌航空大学', 'C', null],
            ['南京大学', 'B', null],
            ['南京航空航天大学', 'C+', null],
            ['南京理工大学', 'B+', null],
            ['南京信息工程大学', 'C-', null],
            ['南京邮电大学', 'B+', null],
            ['南开大学', 'B+', null],
            ['清华大学', 'B+', null],
            ['厦门大学', 'C', null],
            ['山东大学', 'B-', null],
            ['山西大学', 'C+', null],
            ['上海理工大学', 'B+', null],
            ['深圳大学', 'B+', null],
            ['四川大学', 'B', null],
            ['苏州大学', 'B+', null],
            ['苏州科技大学', 'C-', null],
            ['太原理工大学', 'C+', null],
            ['天津大学', 'A', null],
            ['天津理工大学', 'C-', null],
            ['武汉大学', 'C', null],
            ['西安电子科技大学', 'B', null],
            ['西安工业大学', 'B-', null],
            ['西北大学', 'C-', null],
            ['西北工业大学', 'B-', null],
            ['燕山大学', 'C+', null],
            ['云南师范大学', 'C-', null],
            ['长春理工大学', 'A-', null],
            ['浙江大学', 'A+', '光学工程'],
            ['中国海洋大学', 'C-', null],
            ['中国计量大学', 'B-', null],
            ['中国科学技术大学', 'B-', null],
            ['中山大学', 'B-', null],
            ['重庆大学', 'C+', null]
          ]
        ],
        [
          '海洋科学',
          [
            ['大连海洋大学', 'C', null],
            ['广东海洋大学', 'C+', null],
            ['哈尔滨工业大学', 'C', null],
            ['河海大学', 'C+', null],
            ['华东师范大学', 'C-', null],
            ['解放军理工大学', 'C-', null],
            ['南京大学', 'C+', null],
            ['厦门大学', 'A+', '海洋科学'],
            ['上海海洋大学', 'B-', null],
            ['同济大学', 'B+', null],
            ['浙江大学', 'B-', null],
            ['浙江海洋大学', 'B', null],
            ['中国地质大学', 'B', null],
            ['中国海洋大学', 'A+', '海洋科学'],
            ['中山大学', 'B+', null]
          ]
        ],
        [
          '航空宇航科学与技术',
          [
            ['北京航空航天大学', 'A+', '航空宇航科学与技术'],
            ['北京理工大学', 'B', null],
            ['国防科技大学', 'B+', '航空宇航科学与技术'],
            ['哈尔滨工业大学', 'B+', null],
            ['海军航空工程学院', 'B-', null],
            ['火箭军工程大学', 'C+', null],
            ['空军工程大学', 'B', null],
            ['南京航空航天大学', 'B+', null],
            ['南京理工大学', 'C', null],
            ['清华大学', 'B-', null],
            ['上海交通大学', 'C+', null],
            ['沈阳航空航天大学', 'B-', null],
            ['西安交通大学', 'C', null],
            ['西北工业大学', 'A+', null],
            ['浙江大学', 'C-', null],
            ['中国民航大学', 'C-', null],
            ['装备学院', 'C', null]
          ]
        ],
        [
          '核科学与技术',
          [
            ['北京大学', 'B+', null],
            ['北京师范大学', 'C', null],
            ['成都理工大学', 'C-', null],
            ['东华理工大学', 'C', null],
            ['哈尔滨工程大学', 'B+', null],
            ['海军工程大学', 'C+', null],
            ['兰州大学', 'C+', null],
            ['南华大学', 'B-', null],
            ['南京航空航天大学', 'C-', null],
            ['清华大学', 'A+', '核科学与技术'],
            ['上海交通大学', 'B', null],
            ['四川大学', 'B-', null],
            ['西安交通大学', 'B+', null],
            ['中国科学技术大学', 'A+', '核科学与技术']
          ]
        ],
        [
          '护理学',
          [
            ['安徽医科大学', 'C+', null],
            ['北京大学', 'B+', '护理学'],
            ['北京协和医学院', 'A-', null],
            ['成都中医药大学', 'C-', null],
            ['大连医科大学', 'C', null],
            ['第二军医大学', 'A+', null],
            ['第四军医大学', 'B-', null],
            ['福建中医药大学', 'C-', null],
            ['复旦大学', 'B', null],
            ['广西医科大学', 'C+', null],
            ['广州医科大学', 'C', null],
            ['哈尔滨医科大学', 'B', null],
            ['杭州师范大学', 'C-', null],
            ['华北理工大学', 'C', null],
            ['华中科技大学', 'B', null],
            ['吉林大学', 'B-', null],
            ['南昌大学', 'C-', null],
            ['南方医科大学', 'B', null],
            ['南京医科大学', 'B+', null],
            ['南京中医药大学', 'B+', null],
            ['青岛大学', 'C', null],
            ['山东大学', 'B+', null],
            ['山西医科大学', 'C+', null],
            ['上海交通大学', 'B+', null],
            ['首都医科大学', 'A-', null],
            ['四川大学', 'A-', '护理学'],
            ['苏州大学', 'C+', null],
            ['天津医科大学', 'B', null],
            ['天津中医药大学', 'C+', null],
            ['温州医科大学', 'C', null],
            ['武汉大学', 'B-', null],
            ['西安交通大学', 'B-', null],
            ['新疆医科大学', 'C-', null],
            ['延边大学', 'C-', null],
            ['浙江大学', 'B+', null],
            ['浙江中医药大学', 'C+', null],
            ['郑州大学', 'C+', null],
            ['中国医科大学', 'B-', null],
            ['中南大学', 'A+', null],
            ['中山大学', 'B', null],
            ['重庆医科大学', 'B-', null]
          ]
        ],
        [
          '化学',
          [
            ['安徽大学', 'B-', null],
            ['安徽师范大学', 'B', null],
            ['北京大学', 'A+', '化学'],
            ['北京化工大学', 'B+', null],
            ['北京科技大学', 'B', null],
            ['北京师范大学', 'B+', null],
            ['大连大学', 'C-', null],
            ['大连理工大学', 'B', '化学'],
            ['东北大学', 'B', null],
            ['东北师范大学', 'B+', '化学'],
            ['东华大学', 'B-', null],
            ['福建师范大学', 'C+', null],
            ['福州大学', 'A-', '化学（自定）'],
            ['复旦大学', 'A', '化学'],
            ['广西师范大学', 'B-', null],
            ['贵州大学', 'C-', null],
            ['哈尔滨工业大学', 'C+', null],
            ['哈尔滨师范大学', 'C-', null],
            ['海南师范大学', 'C+', null],
            ['杭州师范大学', 'C+', null],
            ['河北大学', 'B-', null],
            ['河北师范大学', 'C', null],
            ['河南大学', 'B-', null],
            ['河南师范大学', 'B', null],
            ['黑龙江大学', 'B-', null],
            ['湖北大学', 'C', null],
            ['湖北师范大学', 'C-', null],
            ['湖南大学', 'A-', '化学'],
            ['湖南科技大学', 'C', null],
            ['湖南师范大学', 'B', null],
            ['华东理工大学', 'B+', '化学'],
            ['华东师范大学', 'B+', null],
            ['华南理工大学', 'B', '化学'],
            ['华南师范大学', 'B', null],
            ['华侨大学', 'C-', null],
            ['华中科技大学', 'B+', null],
            ['华中师范大学', 'B+', null],
            ['淮北师范大学', 'C', null],
            ['吉林大学', 'A', '化学'],
            ['吉林师范大学', 'C-', null],
            ['济南大学', 'C', null],
            ['暨南大学', 'C+', null],
            ['江苏大学', 'C', null],
            ['江苏师范大学', 'C-', null],
            ['江西师范大学', 'B-', null],
            ['兰州大学', 'B+', '化学'],
            ['辽宁大学', 'C+', null],
            ['辽宁师范大学', 'C+', null],
            ['南昌大学', 'B-', null],
            ['南京大学', 'A-', '化学'],
            ['南京工业大学', 'C', null],
            ['南京航空航天大学', 'C', null],
            ['南京理工大学', 'C-', null],
            ['南京师范大学', 'B', null],
            ['南开大学', 'A', '化学'],
            ['内蒙古大学', 'B-', null],
            ['宁波大学', 'C-', null],
            ['青岛科技大学', 'B', null],
            ['清华大学', 'A+', '化学'],
            ['曲阜师范大学', 'C', null],
            ['厦门大学', 'A', '化学'],
            ['山东大学', 'B+', '化学'],
            ['山东师范大学', 'B', null],
            ['山西大学', 'B', null],
            ['山西师范大学', 'B-', null],
            ['陕西师范大学', 'B+', null],
            ['汕头大学', 'C', null],
            ['上海大学', 'C+', null],
            ['上海交通大学', 'A-', '化学'],
            ['上海师范大学', 'B-', null],
            ['首都师范大学', 'C+', null],
            ['四川大学', 'A-', '化学'],
            ['苏州大学', 'B+', null],
            ['天津师范大学', 'C', null],
            ['同济大学', 'B+', null],
            ['温州大学', 'C+', null],
            ['武汉大学', 'A-', '化学'],
            ['武汉工程大学', 'C-', null],
            ['西安交通大学', 'C+', null],
            ['西北大学', 'B+', null],
            ['西北工业大学', 'B-', null],
            ['西北师范大学', 'B-', null],
            ['西南大学', 'B+', null],
            ['湘潭大学', 'B-', null],
            ['新疆大学', 'B-', '化学（自定）'],
            ['延边大学', 'C', null],
            ['扬州大学', 'B', null],
            ['云南大学', 'B', null],
            ['云南民族大学', 'C-', null],
            ['长春理工大学', 'C-', null],
            ['浙江大学', 'A-', '化学'],
            ['浙江理工大学', 'C', null],
            ['浙江师范大学', 'C+', null],
            ['郑州大学', 'B+', '化学（自定）'],
            ['中国地质大学', 'C', null],
            ['中国海洋大学', 'C', null],
            ['中国科学技术大学', 'A+', '化学'],
            ['中国矿业大学', 'C-', null],
            ['中国农业大学', 'C+', null],
            ['中国人民大学', 'C+', null],
            ['中国石油大学', 'B-', null],
            ['中南大学', 'B', null],
            ['中南民族大学', 'C+', null],
            ['中山大学', 'A-', '化学'],
            ['重庆大学', 'C', null],
            ['天津大学', null, '化学'],
            ['中国科学院大学', null, '化学']
          ]
        ],
        [
          '化学工程与技术',
          [
            ['安徽工业大学', 'C+', null],
            ['安徽理工大学', 'C', null],
            ['北京工商大学', 'C+', null],
            ['北京工业大学', 'B', null],
            ['北京化工大学', 'A', '化学工程与技术（自定）'],
            ['北京科技大学', 'C-', null],
            ['北京理工大学', 'A-', null],
            ['常州大学', 'B-', null],
            ['成都理工大学', 'C-', null],
            ['大连工业大学', 'C-', null],
            ['大连理工大学', 'A', null],
            ['电子科技大学', 'C-', null],
            ['东北大学', 'C-', null],
            ['东北电力大学', 'C', null],
            ['东北石油大学', 'B', null],
            ['东华大学', 'C', null],
            ['东南大学', 'B+', null],
            ['福州大学', 'B', null],
            ['广东工业大学', 'B', null],
            ['广西大学', 'B', null],
            ['贵州大学', 'C', null],
            ['哈尔滨工程大学', 'B-', null],
            ['哈尔滨工业大学', 'A-', null],
            ['哈尔滨理工大学', 'C-', null],
            ['海南大学', 'C+', null],
            ['合肥工业大学', 'B', null],
            ['河北工业大学', 'B+', null],
            ['河北科技大学', 'C+', null],
            ['河南大学', 'C-', null],
            ['黑龙江大学', 'C-', null],
            ['湖南大学', 'B', null],
            ['华北理工大学', 'C', null],
            ['华东理工大学', 'A+', '化学工程与技术'],
            ['华南理工大学', 'A-', null],
            ['华南农业大学', 'C-', null],
            ['华侨大学', 'B-', null],
            ['吉林大学', 'B-', null],
            ['济南大学', 'B', null],
            ['江汉大学', 'C', null],
            ['江南大学', 'B+', null],
            ['江苏大学', 'C+', null],
            ['昆明理工大学', 'C', null],
            ['兰州大学', 'C', null],
            ['兰州交通大学', 'C-', null],
            ['兰州理工大学', 'C', null],
            ['辽宁科技大学', 'B-', null],
            ['辽宁石油化工大学', 'B', null],
            ['南昌大学', 'B-', null],
            ['南京工业大学', 'A', null],
            ['南京理工大学', 'A-', null],
            ['南京林业大学', 'C', null],
            ['内蒙古工业大学', 'B-', null],
            ['齐鲁工业大学', 'C-', null],
            ['青岛大学', 'C-', null],
            ['青岛科技大学', 'B+', null],
            ['清华大学', 'A', '化学工程与技术'],
            ['厦门大学', 'B+', null],
            ['山东大学', 'C+', null],
            ['山东科技大学', 'C', null],
            ['山东理工大学', 'B-', null],
            ['山西大学', 'B-', null],
            ['陕西科技大学', 'B', null],
            ['上海大学', 'C+', null],
            ['上海电力大学', 'C+', null],
            ['上海交通大学', 'B+', '化学工程与技术'],
            ['上海师范大学', 'C-', null],
            ['上海应用技术大学', 'B-', null],
            ['沈阳工业大学', 'C+', null],
            ['沈阳化工大学', 'B', null],
            ['沈阳师范大学', 'C-', null],
            ['石河子大学', 'B-', '化学工程与技术（自定）'],
            ['四川大学', 'A-', null],
            ['四川理工学院', 'C-', null],
            ['苏州大学', 'B', null],
            ['太原理工大学', 'B+', '化学工程与技术（自定）'],
            ['天津大学', 'A+', '化学工程与技术'],
            ['天津工业大学', 'C+', null],
            ['天津科技大学', 'C+', null],
            ['天津理工大学', 'C+', null],
            ['武汉工程大学', 'B+', null],
            ['武汉科技大学', 'B', null],
            ['武汉理工大学', 'C+', null],
            ['西安交通大学', 'B+', null],
            ['西安石油大学', 'C', null],
            ['西北大学', 'B+', null],
            ['西北农林科技大学', 'C-', null],
            ['西南石油大学', 'B+', null],
            ['湘潭大学', 'B', null],
            ['新疆大学', 'B-', null],
            ['燕山大学', 'B', null],
            ['长春工业大学', 'B-', null],
            ['浙江大学', 'A', null],
            ['浙江工业大学', 'A-', null],
            ['郑州大学', 'B+', null],
            ['郑州轻工业学院', 'C+', null],
            ['中北大学', 'B-', null],
            ['中国海洋大学', 'C', null],
            ['中国矿业大学', 'B+', null],
            ['中国石油大学', 'A-', null],
            ['中南大学', 'B+', null],
            ['中山大学', 'C', null],
            ['重庆大学', 'B+', null],
            ['南京大学', null, '化学工程与技术']
          ]
        ],
        [
          '环境科学与工程',
          [
            ['安徽大学', 'C-', null],
            ['安徽理工大学', 'C+', null],
            ['北京大学', 'A', '环境科学与工程'],
            ['北京工商大学', 'C', null],
            ['北京工业大学', 'A-', null],
            ['北京航空航天大学', 'B-', null],
            ['北京化工大学', 'B', null],
            ['北京建筑大学', 'C+', null],
            ['北京交通大学', 'C', null],
            ['北京科技大学', 'B+', null],
            ['北京林业大学', 'B-', null],
            ['北京师范大学', 'A', '环境科学与工程'],
            ['常州大学', 'C', null],
            ['大连海事大学', 'B', null],
            ['大连交通大学', 'C-', null],
            ['大连理工大学', 'A-', null],
            ['东北大学', 'C+', null],
            ['东华大学', 'B', null],
            ['东南大学', 'B+', null],
            ['福建师范大学', 'C-', null],
            ['福州大学', 'C', null],
            ['复旦大学', 'B+', '环境科学与工程'],
            ['广东工业大学', 'C+', null],
            ['广西大学', 'C', null],
            ['广西师范大学', 'C-', null],
            ['广州大学', 'C-', null],
            ['桂林理工大学', 'B', null],
            ['哈尔滨工业大学', 'A+', '环境科学与工程'],
            ['哈尔滨商业大学', 'C-', null],
            ['合肥工业大学', 'C+', null],
            ['河北科技大学', 'C', null],
            ['河海大学', 'A-', '环境科学与工程'],
            ['河南师范大学', 'B-', null],
            ['湖南大学', 'B+', null],
            ['华北电力大学', 'B-', null],
            ['华东理工大学', 'B+', null],
            ['华东师范大学', 'B', null],
            ['华南理工大学', 'A-', null],
            ['华南农业大学', 'C-', null],
            ['华中科技大学', 'B+', null],
            ['华中农业大学', 'C', null],
            ['吉林大学', 'B', null],
            ['吉林建筑大学', 'C-', null],
            ['济南大学', 'C+', null],
            ['暨南大学', 'C+', null],
            ['江南大学', 'B-', null],
            ['江苏大学', 'B', null],
            ['昆明理工大学', 'B+', null],
            ['兰州大学', 'C+', null],
            ['兰州交通大学', 'B', null],
            ['南昌大学', 'B-', null],
            ['南昌航空大学', 'B-', null],
            ['南京大学', 'A', '环境科学与工程'],
            ['南京工业大学', 'C+', null],
            ['南京理工大学', 'B', null],
            ['南京林业大学', 'C', null],
            ['南京农业大学', 'C+', null],
            ['南京信息工程大学', 'B-', null],
            ['南开大学', 'A-', null],
            ['内蒙古大学', 'C-', null],
            ['青岛大学', 'C-', null],
            ['青岛科技大学', 'C-', null],
            ['青岛理工大学', 'C', null],
            ['清华大学', 'A+', '环境科学与工程'],
            ['厦门大学', 'B+', null],
            ['山东大学', 'B+', null],
            ['山西大学', 'B', null],
            ['陕西科技大学', 'C-', null],
            ['上海大学', 'B', null],
            ['上海海洋大学', 'C-', null],
            ['上海交通大学', 'A-', null],
            ['上海理工大学', 'C', null],
            ['上海师范大学', 'B-', null],
            ['沈阳大学', 'C', null],
            ['四川大学', 'B+', null],
            ['苏州科技大学', 'C+', null],
            ['太原理工大学', 'B-', null],
            ['天津大学', 'A-', null],
            ['天津工业大学', 'C+', null],
            ['天津科技大学', 'C-', null],
            ['同济大学', 'A+', '环境科学与工程'],
            ['武汉大学', 'B+', null],
            ['武汉纺织大学', 'C', null],
            ['武汉理工大学', 'B', null],
            ['西安建筑科技大学', 'B+', null],
            ['西安交通大学', 'B-', null],
            ['西安理工大学', 'B-', null],
            ['西北大学', 'C-', null],
            ['西北农林科技大学', 'B-', null],
            ['西南大学', 'C-', null],
            ['西南交通大学', 'C', null],
            ['西南科技大学', 'B-', null],
            ['湘潭大学', 'C', null],
            ['燕山大学', 'C-', null],
            ['长安大学', 'B-', null],
            ['浙江大学', 'A', '环境科学与工程'],
            ['浙江工商大学', 'C', null],
            ['浙江工业大学', 'B', null],
            ['郑州大学', 'C', null],
            ['中国地质大学', 'B+', null],
            ['中国海洋大学', 'B+', null],
            ['中国科学技术大学', 'A-', null],
            ['中国矿业大学', 'B', null],
            ['中国农业大学', 'B', null],
            ['中国人民大学', 'C+', null],
            ['中国石油大学', 'B-', null],
            ['中南大学', 'B-', null],
            ['中南林业科技大学', 'C-', null],
            ['中山大学', 'B+', null],
            ['重庆大学', 'B+', null],
            ['重庆工商大学', 'C+', null]
          ]
        ],
        [
          '机械工程',
          [
            ['安徽工业大学', 'C', null],
            ['安徽理工大学', 'C', null],
            ['北方工业大学', 'C', null],
            ['北京工业大学', 'B+', null],
            ['北京航空航天大学', 'A-', null],
            ['北京化工大学', 'B-', null],
            ['北京交通大学', 'B+', null],
            ['北京科技大学', 'B+', null],
            ['北京理工大学', 'A', null],
            ['北京林业大学', 'C+', null],
            ['北京信息科技大学', 'C', null],
            ['北京邮电大学', 'C+', null],
            ['常州大学', 'C-', null],
            ['大连海事大学', 'C-', null],
            ['大连交通大学', 'B', null],
            ['大连理工大学', 'A', null],
            ['电子科技大学', 'B', null],
            ['东北大学', 'B+', null],
            ['东北林业大学', 'C+', null],
            ['东北石油大学', 'C', null],
            ['东华大学', 'B', null],
            ['东南大学', 'B+', null],
            ['福州大学', 'B', null],
            ['广东工业大学', 'B+', null],
            ['广西大学', 'C', null],
            ['广州大学', 'C-', null],
            ['贵州大学', 'B-', null],
            ['桂林电子科技大学', 'B-', null],
            ['国防科技大学', 'A-', null],
            ['哈尔滨工程大学', 'B', null],
            ['哈尔滨工业大学', 'A+', '机械工程'],
            ['哈尔滨理工大学', 'B', null],
            ['杭州电子科技大学', 'C+', null],
            ['合肥工业大学', 'B+', null],
            ['河北工业大学', 'B', null],
            ['河海大学', 'C', null],
            ['河南科技大学', 'B-', null],
            ['河南理工大学', 'C+', null],
            ['湖北工业大学', 'C', null],
            ['湖南大学', 'A-', '机械工程'],
            ['湖南科技大学', 'B-', null],
            ['华北电力大学', 'C', null],
            ['华北理工大学', 'C-', null],
            ['华东理工大学', 'B', null],
            ['华南理工大学', 'A-', null],
            ['华侨大学', 'B-', null],
            ['华中科技大学', 'A+', '机械工程'],
            ['火箭军工程大学', 'C+', null],
            ['吉林大学', 'A-', null],
            ['济南大学', 'C-', null],
            ['江南大学', 'C+', null],
            ['江苏大学', 'B+', null],
            ['江苏科技大学', 'C-', null],
            ['解放军理工大学', 'B', null],
            ['昆明理工大学', 'B-', null],
            ['兰州交通大学', 'B-', null],
            ['兰州理工大学', 'B-', null],
            ['辽宁工程技术大学', 'C+', null],
            ['辽宁科技大学', 'C', null],
            ['南昌大学', 'B-', null],
            ['南京工业大学', 'C-', null],
            ['南京航空航天大学', 'A-', null],
            ['南京理工大学', 'B+', null],
            ['南京林业大学', 'C+', null],
            ['南通大学', 'C', null],
            ['齐鲁工业大学', 'C-', null],
            ['青岛大学', 'C-', null],
            ['青岛科技大学', 'C+', null],
            ['青岛理工大学', 'C+', null],
            ['清华大学', 'A+', '机械工程'],
            ['厦门大学', 'B-', null],
            ['山东大学', 'B+', null],
            ['山东建筑大学', 'C-', null],
            ['山东科技大学', 'B-', null],
            ['山东理工大学', 'B-', null],
            ['陕西科技大学', 'C-', null],
            ['上海大学', 'B+', '机械工程（自定）'],
            ['上海工程技术大学', 'C+', null],
            ['上海海事大学', 'C-', null],
            ['上海交通大学', 'A+', '机械工程'],
            ['上海理工大学', 'B', null],
            ['上海应用技术大学', 'C-', null],
            ['深圳大学', 'C-', null],
            ['沈阳工业大学', 'B-', null],
            ['沈阳航空航天大学', 'C', null],
            ['沈阳建筑大学', 'B', null],
            ['沈阳理工大学', 'C+', null],
            ['石家庄铁道大学', 'C', null],
            ['四川大学', 'B', null],
            ['苏州大学', 'C', null],
            ['太原科技大学', 'B', null],
            ['太原理工大学', 'B+', null],
            ['天津大学', 'A', null],
            ['天津工业大学', 'C+', null],
            ['天津科技大学', 'C', null],
            ['天津职业技术师范大学', 'C-', null],
            ['同济大学', 'A-', null],
            ['武汉大学', 'B', null],
            ['武汉纺织大学', 'C', null],
            ['武汉科技大学', 'B', null],
            ['武汉理工大学', 'B+', null],
            ['西安电子科技大学', 'B+', null],
            ['西安工业大学', 'C+', null],
            ['西安建筑科技大学', 'C+', null],
            ['西安交通大学', 'A', '机械工程'],
            ['西安科技大学', 'C+', null],
            ['西安理工大学', 'B', null],
            ['西北工业大学', 'B+', '机械工程'],
            ['西华大学', 'C-', null],
            ['西南交通大学', 'B+', null],
            ['西南石油大学', 'B', null],
            ['湘潭大学', 'C', null],
            ['新疆大学', 'C+', null],
            ['燕山大学', 'A-', null],
            ['长安大学', 'B-', null],
            ['长春工业大学', 'B-', null],
            ['长春理工大学', 'B', null],
            ['长沙理工大学', 'C', null],
            ['浙江大学', 'A', '机械工程'],
            ['浙江工业大学', 'B+', null],
            ['浙江理工大学', 'B', null],
            ['郑州大学', 'C', null],
            ['郑州轻工业学院', 'C', null],
            ['中北大学', 'B-', null],
            ['中国地质大学', 'C', null],
            ['中国矿业大学', 'B+', null],
            ['中国农业大学', 'B', null],
            ['中国石油大学', 'B', null],
            ['中南大学', 'A-', null],
            ['重庆大学', 'A-', '机械工程（自定）'],
            ['重庆交通大学', 'C-', null],
            ['重庆理工大学', 'C+', null]
          ]
        ],
        [
          '基础医学',
          [
            ['安徽医科大学', 'C+', null],
            ['蚌埠医学院', 'C-', null],
            ['北京大学', 'A+', '基础医学'],
            ['北京协和医学院', 'A+', null],
            ['大连医科大学', 'B', null],
            ['第二军医大学', 'A-', '基础医学'],
            ['第四军医大学', 'B', null],
            ['复旦大学', 'A', '基础医学'],
            ['广西医科大学', 'C+', null],
            ['广州医科大学', 'C', null],
            ['贵州医科大学', 'C+', null],
            ['桂林医学院', 'C-', null],
            ['哈尔滨医科大学', 'B-', null],
            ['河北医科大学', 'B-', null],
            ['湖南师范大学', 'C-', null],
            ['华北理工大学', 'C-', null],
            ['华中科技大学', 'B+', '基础医学'],
            ['吉林大学', 'B', null],
            ['暨南大学', 'B-', null],
            ['江苏大学', 'C-', null],
            ['锦州医科大学', 'C-', null],
            ['昆明医科大学', 'C', null],
            ['兰州大学', 'B-', null],
            ['南昌大学', 'C+', null],
            ['南方医科大学', 'B+', null],
            ['南华大学', 'C+', null],
            ['南京医科大学', 'B+', null],
            ['南开大学', 'B-', null],
            ['南通大学', 'B-', null],
            ['宁夏医科大学', 'C', null],
            ['青岛大学', 'C+', null],
            ['厦门大学', 'C+', null],
            ['山东大学', 'B+', null],
            ['山西医科大学', 'C', null],
            ['汕头大学', 'C', null],
            ['上海交通大学', 'A-', '基础医学'],
            ['石河子大学', 'C', null],
            ['首都医科大学', 'B', null],
            ['四川大学', 'B+', '基础医学'],
            ['苏州大学', 'B+', null],
            ['天津医科大学', 'B+', null],
            ['同济大学', 'B', null],
            ['温州医科大学', 'C-', null],
            ['武汉大学', 'B', null],
            ['西安交通大学', 'B', null],
            ['新疆医科大学', 'C-', null],
            ['新乡医学院', 'C', null],
            ['徐州医科大学', 'C+', null],
            ['浙江大学', 'A-', '基础医学'],
            ['郑州大学', 'B-', null],
            ['中国医科大学', 'B', null],
            ['中南大学', 'B+', null],
            ['中山大学', 'A-', '基础医学'],
            ['重庆医科大学', 'B-', null]
          ]
        ],
        [
          '计算机科学与技术',
          [
            ['安徽大学', 'B', null],
            ['安徽工业大学', 'C', null],
            ['北方工业大学', 'B-', null],
            ['北京大学', 'A+', '计算机科学与技术'],
            ['北京工商大学', 'C', null],
            ['北京工业大学', 'B+', null],
            ['北京航空航天大学', 'A', '计算机科学与技术'],
            ['北京化工大学', 'C+', null],
            ['北京交通大学', 'A-', null],
            ['北京科技大学', 'B+', null],
            ['北京理工大学', 'A-', null],
            ['北京师范大学', 'B', null],
            ['北京信息科技大学', 'C-', null],
            ['北京邮电大学', 'A', '计算机科学与技术'],
            ['北京语言大学', 'C+', null],
            ['常州大学', 'C+', null],
            ['成都信息工程大学', 'C+', null],
            ['大连大学', 'C', null],
            ['大连海事大学', 'B', null],
            ['大连理工大学', 'B+', null],
            ['电子科技大学', 'A', null],
            ['东北大学', 'A-', null],
            ['东北电力大学', 'C', null],
            ['东华大学', 'B-', null],
            ['东华理工大学', 'C-', null],
            ['东南大学', 'B+', '计算机科学与技术'],
            ['福建师范大学', 'C+', null],
            ['福州大学', 'B-', null],
            ['复旦大学', 'B+', null],
            ['广东工业大学', 'C', null],
            ['广西大学', 'B-', null],
            ['广西师范大学', 'C+', null],
            ['贵州大学', 'C+', null],
            ['桂林电子科技大学', 'B-', null],
            ['桂林理工大学', 'C-', null],
            ['国防科技大学', 'A+', '计算机科学与技术'],
            ['哈尔滨工程大学', 'B+', null],
            ['哈尔滨工业大学', 'A', '计算机科学与技术'],
            ['哈尔滨理工大学', 'B', null],
            ['海军航空工程学院', 'C-', null],
            ['海南大学', 'C-', null],
            ['杭州电子科技大学', 'B+', null],
            ['合肥工业大学', 'B+', null],
            ['河北大学', 'C+', null],
            ['河北工程大学', 'C', null],
            ['河北工业大学', 'C+', null],
            ['河海大学', 'B', null],
            ['河南大学', 'C-', null],
            ['河南工业大学', 'C-', null],
            ['河南理工大学', 'C', null],
            ['河南师范大学', 'C-', null],
            ['黑龙江大学', 'C-', null],
            ['湖北工业大学', 'C-', null],
            ['湖南大学', 'B+', null],
            ['湖南工业大学', 'C-', null],
            ['湖南科技大学', 'C+', null],
            ['华北电力大学', 'B-', null],
            ['华东理工大学', 'B', null],
            ['华东师范大学', 'B+', null],
            ['华南理工大学', 'B+', null],
            ['华南农业大学', 'C', null],
            ['华侨大学', 'C-', null],
            ['华中科技大学', 'A', '计算机科学与技术'],
            ['华中师范大学', 'B-', null],
            ['火箭军工程大学', 'B', null],
            ['吉林大学', 'A-', null],
            ['济南大学', 'B-', null],
            ['暨南大学', 'B', null],
            ['江南大学', 'B-', null],
            ['江苏大学', 'B', null],
            ['江苏科技大学', 'C-', null],
            ['江西财经大学', 'C-', null],
            ['江西理工大学', 'C-', null],
            ['江西师范大学', 'C', null],
            ['解放军理工大学', 'B+', null],
            ['解放军信息工程大学', 'A-', null],
            ['空军工程大学', 'B-', null],
            ['昆明理工大学', 'C+', null],
            ['兰州大学', 'B', null],
            ['辽宁师范大学', 'C+', null],
            ['南昌大学', 'C+', null],
            ['南京大学', 'A', '计算机科学与技术'],
            ['南京航空航天大学', 'B+', null],
            ['南京理工大学', 'B+', null],
            ['南京信息工程大学', 'B', null],
            ['南京邮电大学', 'B-', null],
            ['南开大学', 'B+', null],
            ['内蒙古大学', 'B-', null],
            ['青岛大学', 'C+', null],
            ['青海师范大学', 'B-', null],
            ['清华大学', 'A+', '计算机科学与技术'],
            ['三峡大学', 'C', null],
            ['厦门大学', 'B+', null],
            ['山东财经大学', 'C', null],
            ['山东大学', 'B+', null],
            ['山东科技大学', 'B-', null],
            ['山西大学', 'B', null],
            ['上海大学', 'B', null],
            ['上海海事大学', 'C-', null],
            ['上海海洋大学', 'C+', null],
            ['上海交通大学', 'A', '计算机科学与技术'],
            ['上海理工大学', 'C+', null],
            ['上海师范大学', 'C', null],
            ['深圳大学', 'B', null],
            ['沈阳航空航天大学', 'B-', null],
            ['沈阳建筑大学', 'C+', null],
            ['沈阳理工大学', 'C-', null],
            ['石家庄铁道大学', 'C', null],
            ['首都师范大学', 'B-', null],
            ['四川大学', 'B+', null],
            ['苏州大学', 'B', null],
            ['太原理工大学', 'B-', null],
            ['天津大学', 'B+', null],
            ['天津工业大学', 'B-', null],
            ['天津理工大学', 'B', null],
            ['同济大学', 'A-', null],
            ['温州大学', 'C+', null],
            ['武汉大学', 'A-', null],
            ['武汉纺织大学', 'C-', null],
            ['武汉工程大学', 'C-', null],
            ['武汉科技大学', 'C+', null],
            ['武汉理工大学', 'B', null],
            ['西安电子科技大学', 'A-', '计算机科学与技术'],
            ['西安工业大学', 'C', null],
            ['西安交通大学', 'A-', null],
            ['西安理工大学', 'C', null],
            ['西安石油大学', 'C-', null],
            ['西安邮电大学', 'C+', null],
            ['西北大学', 'B-', null],
            ['西北工业大学', 'A-', null],
            ['西北农林科技大学', 'C', null],
            ['西华大学', 'C-', null],
            ['西南财经大学', 'C-', null],
            ['西南大学', 'B', null],
            ['西南交通大学', 'B+', null],
            ['西南石油大学', 'C-', null],
            ['湘潭大学', 'C', null],
            ['新疆大学', 'B-', '计算机科学与技术（自定）'],
            ['燕山大学', 'B', null],
            ['扬州大学', 'C', null],
            ['云南大学', 'B-', null],
            ['长安大学', 'C+', null],
            ['长春工业大学', 'C', null],
            ['长春理工大学', 'B', null],
            ['长沙理工大学', 'C-', null],
            ['浙江大学', 'A+', '计算机科学与技术'],
            ['浙江工商大学', 'B-', null],
            ['浙江工业大学', 'B', null],
            ['浙江理工大学', 'C+', null],
            ['浙江师范大学', 'C+', null],
            ['郑州大学', 'C+', null],
            ['郑州轻工业学院', 'C', null],
            ['中北大学', 'C', null],
            ['中国传媒大学', 'C+', null],
            ['中国地质大学', 'B', null],
            ['中国海洋大学', 'B', null],
            ['中国科学技术大学', 'A-', '计算机科学与技术'],
            ['中国矿业大学', 'B', null],
            ['中国民航大学', 'C+', null],
            ['中国农业大学', 'B-', null],
            ['中国人民大学', 'B+', null],
            ['中国石油大学', 'B-', null],
            ['中南大学', 'A-', null],
            ['中山大学', 'B+', null],
            ['中央民族大学', 'C-', null],
            ['重庆大学', 'B+', null],
            ['重庆交通大学', 'C-', null],
            ['重庆邮电大学', 'B+', null]
          ]
        ],
        [
          '建筑学',
          [
            ['安徽建筑大学', 'C+', null],
            ['北京工业大学', 'B-', null],
            ['北京建筑大学', 'B', null],
            ['北京交通大学', 'C', null],
            ['大连理工大学', 'B', null],
            ['东南大学', 'A+', '建筑学'],
            ['福州大学', 'C', null],
            ['哈尔滨工业大学', 'B+', null],
            ['合肥工业大学', 'B-', null],
            ['湖南大学', 'B', null],
            ['华南理工大学', 'A-', null],
            ['华侨大学', 'C+', null],
            ['华中科技大学', 'B+', null],
            ['吉林建筑大学', 'C', null],
            ['昆明理工大学', 'C+', null],
            ['南京大学', 'B', null],
            ['南京工业大学', 'C', null],
            ['内蒙古工业大学', 'C-', null],
            ['青岛理工大学', 'C+', null],
            ['清华大学', 'A+', '建筑学'],
            ['厦门大学', 'C+', null],
            ['山东建筑大学', 'B-', null],
            ['上海交通大学', 'C+', null],
            ['深圳大学', 'B-', null],
            ['沈阳建筑大学', 'B', null],
            ['苏州科技大学', 'C-', null],
            ['天津大学', 'A-', null],
            ['同济大学', 'A-', '建筑学'],
            ['武汉大学', 'B', null],
            ['西安建筑科技大学', 'B+', null],
            ['西安交通大学', 'C-', null],
            ['西南交通大学', 'B-', null],
            ['长安大学', 'C-', null],
            ['浙江大学', 'B+', null],
            ['郑州大学', 'C-', null],
            ['中央美术学院', 'C+', null],
            ['重庆大学', 'B+', null]
          ]
        ],
        [
          '交通运输工程',
          [
            ['北京工业大学', 'B-', null],
            ['北京航空航天大学', 'A-', null],
            ['北京建筑大学', 'C', null],
            ['北京交通大学', 'A-', null],
            ['大连海事大学', 'B+', '交通运输工程（自定）'],
            ['大连交通大学', 'C', null],
            ['大连理工大学', 'C', null],
            ['东北林业大学', 'C+', null],
            ['东南大学', 'A+', '交通运输工程'],
            ['哈尔滨工业大学', 'B+', null],
            ['河海大学', 'C-', null],
            ['湖南大学', 'C', null],
            ['华东交通大学', 'B-', null],
            ['华南理工大学', 'B-', null],
            ['吉林大学', 'B', null],
            ['江苏大学', 'C+', null],
            ['空军工程大学', 'C+', null],
            ['昆明理工大学', 'C-', null],
            ['兰州交通大学', 'B', null],
            ['南京航空航天大学', 'B', null],
            ['南京林业大学', 'C-', null],
            ['清华大学', 'C-', null],
            ['上海海事大学', 'B', null],
            ['上海交通大学', 'C+', null],
            ['上海理工大学', 'C-', null],
            ['石家庄铁道大学', 'C+', null],
            ['同济大学', 'A-', null],
            ['武汉理工大学', 'B+', null],
            ['西北工业大学', 'B-', null],
            ['西南交通大学', 'A+', '交通运输工程'],
            ['长安大学', 'B+', '交通运输工程（自定）'],
            ['长沙理工大学', 'B', null],
            ['中国民航大学', 'C+', null],
            ['中南大学', 'B+', null],
            ['中山大学', 'C', null],
            ['重庆交通大学', 'B', null]
          ]
        ],
        [
          '教育学',
          [
            ['安徽师范大学', 'B-', null],
            ['北京大学', 'A-', null],
            ['北京工业大学', 'C', null],
            ['北京理工大学', 'B', null],
            ['北京师范大学', 'A+', '教育学'],
            ['渤海大学', 'C', null],
            ['东北师范大学', 'A', null],
            ['福建师范大学', 'B-', null],
            ['赣南师范大学', 'C', null],
            ['广西师范大学', 'B', null],
            ['广西师范学院', 'C-', null],
            ['广州大学', 'C+', null],
            ['贵州师范大学', 'C', null],
            ['哈尔滨师范大学', 'B', null],
            ['海南师范大学', 'C-', null],
            ['杭州师范大学', 'B-', null],
            ['河北大学', 'B-', null],
            ['河北师范大学', 'C+', null],
            ['河南大学', 'B+', null],
            ['河南师范大学', 'B-', null],
            ['湖北大学', 'C', null],
            ['湖北师范大学', 'C-', null],
            ['湖南师范大学', 'B+', null],
            ['华东师范大学', 'A+', '教育学'],
            ['华南师范大学', 'A-', null],
            ['华中科技大学', 'B+', null],
            ['华中师范大学', 'A', null],
            ['淮北师范大学', 'C-', null],
            ['吉林师范大学', 'C', null],
            ['江南大学', 'C+', null],
            ['江苏大学', 'C-', null],
            ['江苏师范大学', 'B', null],
            ['江西师范大学', 'B', null],
            ['辽宁师范大学', 'B', null],
            ['鲁东大学', 'C-', null],
            ['南京师范大学', 'A', null],
            ['内蒙古师范大学', 'C+', null],
            ['宁波大学', 'C+', null],
            ['清华大学', 'B+', null],
            ['曲阜师范大学', 'B-', null],
            ['厦门大学', 'B+', null],
            ['山东师范大学', 'B+', null],
            ['山西大学', 'C+', null],
            ['山西师范大学', 'C+', null],
            ['陕西师范大学', 'B+', null],
            ['上海师范大学', 'B+', null],
            ['深圳大学', 'C-', null],
            ['沈阳师范大学', 'B', null],
            ['石河子大学', 'C-', null],
            ['首都师范大学', 'A-', null],
            ['四川师范大学', 'B', null],
            ['苏州大学', 'B-', null],
            ['天津大学', 'B', null],
            ['天津师范大学', 'B', null],
            ['天津职业技术师范大学', 'C-', null],
            ['同济大学', 'C+', null],
            ['温州大学', 'C+', null],
            ['西北师范大学', 'B+', null],
            ['西南大学', 'A-', null],
            ['新疆师范大学', 'B-', null],
            ['扬州大学', 'C', null],
            ['云南大学', 'C', null],
            ['云南师范大学', 'B-', null],
            ['长春师范大学', 'C-', null],
            ['浙江大学', 'A-', null],
            ['浙江工业大学', 'C', null],
            ['浙江师范大学', 'B+', null],
            ['中南民族大学', 'C', null],
            ['中央民族大学', 'C+', null],
            ['重庆师范大学', 'B-', null]
          ]
        ],
        [
          '考古学',
          [
            ['安徽大学', 'C-', null],
            ['北京大学', 'A+', '考古学'],
            ['北京联合大学', 'C-', null],
            ['北京师范大学', 'C-', null],
            ['复旦大学', 'B-', null],
            ['河北师范大学', 'C+', null],
            ['河南大学', 'C+', null],
            ['吉林大学', 'A-', '考古学'],
            ['南京大学', 'B+', null],
            ['南京师范大学', 'C', null],
            ['南开大学', 'B-', null],
            ['厦门大学', 'C+', null],
            ['山东大学', 'B+', null],
            ['山西大学', 'C-', null],
            ['陕西师范大学', 'C', null],
            ['首都师范大学', 'B-', null],
            ['四川大学', 'B+', null],
            ['武汉大学', 'B', null],
            ['西北大学', 'A+', null],
            ['浙江大学', 'B', null],
            ['郑州大学', 'B+', null],
            ['中国人民大学', 'B-', null],
            ['中山大学', 'B', null],
            ['中央民族大学', 'C', null],
            ['重庆师范大学', 'C+', null]
          ]
        ],
        [
          '科学技术史',
          [
            ['北京大学', 'B', null],
            ['北京科技大学', 'A+', '科学技术史'],
            ['东华大学', 'C', null],
            ['广西民族大学', 'C+', null],
            ['哈尔滨工业大学', 'C', null],
            ['南京信息工程大学', 'B-', null],
            ['内蒙古师范大学', 'B+', null],
            ['清华大学', 'C+', null],
            ['山西大学', 'B-', null],
            ['上海交通大学', 'B+', null],
            ['首都师范大学', 'C-', null],
            ['西北大学', 'B', null],
            ['云南农业大学', 'C-', null],
            ['中国科学技术大学', 'A+', '科学技术史']
          ]
        ],
        [
          '控制科学与工程',
          [
            ['安徽大学', 'C+', null],
            ['安徽工程大学', 'C+', null],
            ['北方工业大学', 'C+', null],
            ['北京工商大学', 'C-', null],
            ['北京工业大学', 'B+', null],
            ['北京航空航天大学', 'A', '控制科学与工程'],
            ['北京化工大学', 'B+', null],
            ['北京建筑大学', 'C', null],
            ['北京交通大学', 'B-', null],
            ['北京科技大学', 'B+', null],
            ['北京理工大学', 'A', '控制科学与工程'],
            ['北京信息科技大学', 'C-', null],
            ['北京邮电大学', 'B-', null],
            ['渤海大学', 'C', null],
            ['大连工业大学', 'C', null],
            ['大连海事大学', 'B-', null],
            ['大连理工大学', 'B+', null],
            ['电子科技大学', 'B', null],
            ['东北大学', 'A', '控制科学与工程'],
            ['东北电力大学', 'C+', null],
            ['东华大学', 'B', null],
            ['东南大学', 'A-', '控制科学与工程'],
            ['广东工业大学', 'A-', null],
            ['国防科技大学', 'A', null],
            ['哈尔滨工程大学', 'A-', null],
            ['哈尔滨工业大学', 'A+', '控制科学与工程'],
            ['哈尔滨理工大学', 'C+', null],
            ['海军工程大学', 'B', null],
            ['海军航空工程学院', 'B', null],
            ['杭州电子科技大学', 'B+', null],
            ['合肥工业大学', 'B-', null],
            ['河北工业大学', 'B-', null],
            ['河海大学', 'C', null],
            ['河南科技大学', 'B-', null],
            ['河南理工大学', 'C', null],
            ['黑龙江大学', 'C-', null],
            ['湖南大学', 'B', null],
            ['湖南科技大学', 'C-', null],
            ['华北电力大学', 'B', null],
            ['华东交通大学', 'B-', null],
            ['华东理工大学', 'B+', null],
            ['华南理工大学', 'B+', null],
            ['华中科技大学', 'A-', null],
            ['火箭军工程大学', 'A-', null],
            ['吉林大学', 'B', null],
            ['济南大学', 'C', null],
            ['江南大学', 'B+', null],
            ['江苏大学', 'B', null],
            ['江苏科技大学', 'C', null],
            ['解放军信息工程大学', 'B-', null],
            ['空军工程大学', 'B', null],
            ['昆明理工大学', 'C-', null],
            ['兰州理工大学', 'B-', null],
            ['辽宁工业大学', 'C+', null],
            ['辽宁科技大学', 'C', null],
            ['辽宁石油化工大学', 'C+', null],
            ['南京大学', 'C', null],
            ['南京工业大学', 'C+', null],
            ['南京航空航天大学', 'B+', null],
            ['南京理工大学', 'B+', null],
            ['南京邮电大学', 'C+', null],
            ['南开大学', 'B', null],
            ['南通大学', 'C-', null],
            ['青岛大学', 'C', null],
            ['青岛科技大学', 'C', null],
            ['清华大学', 'A+', '控制科学与工程'],
            ['曲阜师范大学', 'C', null],
            ['厦门大学', 'B-', null],
            ['山东大学', 'A-', null],
            ['山东建筑大学', 'C-', null],
            ['山东科技大学', 'B', null],
            ['山西大学', 'C+', null],
            ['陕西科技大学', 'C-', null],
            ['上海大学', 'B', null],
            ['上海交通大学', 'A', '控制科学与工程'],
            ['上海理工大学', 'C+', null],
            ['深圳大学', 'C-', null],
            ['沈阳航空航天大学', 'C-', null],
            ['沈阳化工大学', 'C-', null],
            ['四川大学', 'C', null],
            ['太原科技大学', 'C-', null],
            ['太原理工大学', 'C-', null],
            ['天津大学', 'B+', null],
            ['天津工业大学', 'C+', null],
            ['天津理工大学', 'C+', null],
            ['天津职业技术师范大学', 'C-', null],
            ['同济大学', 'B+', null],
            ['武汉大学', 'B-', null],
            ['武汉科技大学', 'B', null],
            ['武汉理工大学', 'C+', null],
            ['西安电子科技大学', 'B+', null],
            ['西安工程大学', 'C', null],
            ['西安工业大学', 'C-', null],
            ['西安交通大学', 'A-', null],
            ['西安理工大学', 'B+', null],
            ['西北工业大学', 'B+', null],
            ['西南交通大学', 'B-', null],
            ['西南科技大学', 'C+', null],
            ['燕山大学', 'B', null],
            ['长春工业大学', 'C', null],
            ['长春理工大学', 'C-', null],
            ['浙江大学', 'A+', '控制科学与工程'],
            ['浙江工业大学', 'B', null],
            ['浙江理工大学', 'C-', null],
            ['郑州大学', 'C+', null],
            ['中国地质大学', 'C+', null],
            ['中国海洋大学', 'C-', null],
            ['中国计量大学', 'B-', null],
            ['中国科学技术大学', 'B+', null],
            ['中国矿业大学', 'B-', null],
            ['中国民航大学', 'C+', null],
            ['中国石油大学', 'B-', null],
            ['中南大学', 'A-', null],
            ['重庆大学', 'B', null],
            ['重庆邮电大学', 'B-', null],
            ['北京大学', null, '控制科学与工程']
          ]
        ],
        [
          '口腔医学',
          [
            ['安徽医科大学', 'C', null],
            ['北京大学', 'A+', '口腔医学'],
            ['大连医科大学', 'C+', null],
            ['第四军医大学', 'A+', null],
            ['广西医科大学', 'C+', null],
            ['哈尔滨医科大学', 'B-', null],
            ['河北医科大学', 'C-', null],
            ['华中科技大学', 'C', null],
            ['吉林大学', 'B-', null],
            ['昆明医科大学', 'C', null],
            ['兰州大学', 'C-', null],
            ['南方医科大学', 'C-', null],
            ['南京医科大学', 'B+', null],
            ['南开大学', 'C-', null],
            ['山东大学', 'B', null],
            ['上海交通大学', 'B+', '口腔医学'],
            ['首都医科大学', 'B', null],
            ['四川大学', 'A+', '口腔医学'],
            ['天津医科大学', 'B-', null],
            ['同济大学', 'B-', null],
            ['温州医科大学', 'C-', null],
            ['武汉大学', 'B+', '口腔医学'],
            ['西安交通大学', 'C+', null],
            ['浙江大学', 'B', null],
            ['中国医科大学', 'B', null],
            ['中南大学', 'C', null],
            ['中山大学', 'B+', null],
            ['重庆医科大学', 'C+', null]
          ]
        ],
        [
          '矿业工程',
          [
            ['安徽理工大学', 'B-', null],
            ['北京科技大学', 'B+', '矿业工程'],
            ['东北大学', 'B+', null],
            ['河南理工大学', 'B', null],
            ['湖南科技大学', 'C-', null],
            ['华北理工大学', 'C-', null],
            ['江西理工大学', 'C', null],
            ['昆明理工大学', 'C+', null],
            ['辽宁工程技术大学', 'B-', null],
            ['南华大学', 'C', null],
            ['内蒙古科技大学', 'C-', null],
            ['山东科技大学', 'B', null],
            ['太原理工大学', 'B', null],
            ['武汉科技大学', 'C+', null],
            ['武汉理工大学', 'B-', null],
            ['西安科技大学', 'C+', null],
            ['中国矿业大学', 'A+', '矿业工程'],
            ['中南大学', 'A+', '矿业工程'],
            ['重庆大学', 'B+', null],
            ['中国矿业大学(北京)', null, '矿业工程'],
            ['南京大学', null, '矿业工程'],
            ['武汉大学', null, '矿业工程']
          ]
        ],
        [
          '理论经济学',
          [
            ['安徽大学', 'C', null],
            ['北京大学', 'A', '理论经济学'],
            ['北京工商大学', 'C-', null],
            ['北京理工大学', 'C+', null],
            ['北京师范大学', 'A-', null],
            ['东北财经大学', 'B', null],
            ['东北师范大学', 'B-', null],
            ['对外经济贸易大学', 'B', null],
            ['福建师范大学', 'B', null],
            ['复旦大学', 'A+', null],
            ['河北大学', 'C+', null],
            ['河南大学', 'C+', null],
            ['黑龙江大学', 'C', null],
            ['湖北大学', 'B-', null],
            ['湖南大学', 'B-', null],
            ['湖南师范大学', 'C+', null],
            ['华东师范大学', 'C+', null],
            ['华南师范大学', 'B-', null],
            ['华中科技大学', 'B+', null],
            ['华中师范大学', 'C', null],
            ['吉林财经大学', 'C', null],
            ['吉林大学', 'B+', null],
            ['暨南大学', 'B-', null],
            ['江西财经大学', 'B', null],
            ['兰州财经大学', 'C-', null],
            ['兰州大学', 'C-', null],
            ['辽宁大学', 'B', null],
            ['南京财经大学', 'C+', null],
            ['南京大学', 'A-', null],
            ['南京审计大学', 'C-', null],
            ['南开大学', 'A', null],
            ['青岛大学', 'C', null],
            ['清华大学', 'B+', null],
            ['厦门大学', 'B+', null],
            ['山东财经大学', 'C', null],
            ['山东大学', 'B+', null],
            ['山西财经大学', 'C+', null],
            ['山西大学', 'C', null],
            ['陕西师范大学', 'B-', null],
            ['上海财经大学', 'B+', null],
            ['上海大学', 'C-', null],
            ['深圳大学', 'B', null],
            ['首都经济贸易大学', 'B-', null],
            ['四川大学', 'B', null],
            ['四川师范大学', 'C', null],
            ['天津财经大学', 'C+', null],
            ['武汉大学', 'A-', '理论经济学'],
            ['西安交通大学', 'C-', null],
            ['西北大学', 'A-', null],
            ['西南财经大学', 'B+', null],
            ['湘潭大学', 'B-', null],
            ['新疆大学', 'B-', null],
            ['云南财经大学', 'C-', null],
            ['云南大学', 'B', null],
            ['浙江财经大学', 'C', null],
            ['浙江大学', 'A-', null],
            ['中国人民大学', 'A+', '理论经济学'],
            ['中国政法大学', 'C+', null],
            ['中南财经政法大学', 'B', null],
            ['中山大学', 'B+', null],
            ['中央财经大学', 'B+', null],
            ['中央民族大学', 'C+', null],
            ['重庆大学', 'C', null]
          ]
        ],
        [
          '力学',
          [
            ['北京大学', 'A+', '力学'],
            ['北京工业大学', 'B-', null],
            ['北京航空航天大学', 'A-', '力学'],
            ['北京交通大学', 'B', null],
            ['北京科技大学', 'B-', null],
            ['北京理工大学', 'B+', null],
            ['大连交通大学', 'C-', null],
            ['大连理工大学', 'A-', null],
            ['东北大学', 'C', null],
            ['东南大学', 'B-', null],
            ['复旦大学', 'C+', null],
            ['国防科技大学', 'B', null],
            ['哈尔滨工程大学', 'B', null],
            ['哈尔滨工业大学', 'A', '力学'],
            ['合肥工业大学', 'C-', null],
            ['河海大学', 'B', null],
            ['湖南大学', 'B-', null],
            ['华南理工大学', 'C+', null],
            ['华中科技大学', 'B+', null],
            ['吉林大学', 'C', null],
            ['暨南大学', 'C+', null],
            ['江苏大学', 'C', null],
            ['昆明理工大学', 'C+', null],
            ['兰州大学', 'B', null],
            ['辽宁工程技术大学', 'C+', null],
            ['南昌大学', 'C-', null],
            ['南京航空航天大学', 'A-', '力学'],
            ['南京理工大学', 'B', null],
            ['内蒙古工业大学', 'C-', null],
            ['宁波大学', 'C', '力学'],
            ['清华大学', 'A+', '力学'],
            ['厦门大学', 'C-', null],
            ['山东大学', 'C', null],
            ['上海大学', 'B+', null],
            ['上海交通大学', 'B+', null],
            ['石家庄铁道大学', 'C-', null],
            ['四川大学', 'B-', null],
            ['太原理工大学', 'C+', null],
            ['天津大学', 'A-', null],
            ['同济大学', 'B+', null],
            ['武汉大学', 'C+', null],
            ['武汉理工大学', 'B-', null],
            ['西安交通大学', 'A', '力学'],
            ['西北工业大学', 'B+', null],
            ['西南交通大学', 'B', null],
            ['湘潭大学', 'C+', null],
            ['燕山大学', 'C-', null],
            ['长安大学', 'C-', null],
            ['浙江大学', 'B+', null],
            ['郑州大学', 'C', null],
            ['中国科学技术大学', 'B+', null],
            ['中国矿业大学', 'B', null],
            ['中国石油大学', 'C+', null],
            ['中南大学', 'C', null],
            ['中山大学', 'B-', null],
            ['重庆大学', 'B-', null]
          ]
        ],
        [
          '林学',
          [
            ['安徽农业大学', 'C', null],
            ['北华大学', 'C+', null],
            ['北京林业大学', 'A+', '林学'],
            ['东北林业大学', 'B+', '林学'],
            ['福建农林大学', 'B+', null],
            ['甘肃农业大学', 'C-', null],
            ['贵州大学', 'C', null],
            ['河北农业大学', 'B-', null],
            ['河南农业大学', 'C', null],
            ['华南农业大学', 'C+', null],
            ['华中农业大学', 'C-', null],
            ['江西农业大学', 'B-', null],
            ['南京林业大学', 'A+', null],
            ['内蒙古农业大学', 'C+', null],
            ['山东农业大学', 'B-', null],
            ['沈阳农业大学', 'C-', null],
            ['四川农业大学', 'B', null],
            ['西北农林科技大学', 'A-', null],
            ['西南林业大学', 'B', null],
            ['浙江农林大学', 'B+', null],
            ['中南林业科技大学', 'B', null]
          ]
        ],
        [
          '林业工程',
          [
            ['北华大学', 'C-', null],
            ['北京林业大学', 'B', null],
            ['东北林业大学', 'A+', '林业工程'],
            ['福建农林大学', 'B-', null],
            ['南京林业大学', 'A+', '林业工程'],
            ['内蒙古农业大学', 'C-', null],
            ['西南林业大学', 'C', null],
            ['浙江农林大学', 'C+', null],
            ['中南林业科技大学', 'B-', null]
          ]
        ],
        [
          '临床医学',
          [
            ['安徽医科大学', 'B-', null],
            ['蚌埠医学院', 'C-', null],
            ['北京大学', 'A-', '临床医学'],
            ['北京协和医学院', 'A', '临床医学'],
            ['大连医科大学', 'B', null],
            ['第二军医大学', 'B+', null],
            ['第四军医大学', 'B+', '临床医学（自定）'],
            ['复旦大学', 'A', '临床医学'],
            ['广东医科大学', 'C', null],
            ['广西医科大学', 'C+', null],
            ['广州医科大学', 'B-', null],
            ['贵州医科大学', 'C', null],
            ['哈尔滨医科大学', 'B+', null],
            ['河北大学', 'C-', null],
            ['河北医科大学', 'B-', null],
            ['河南大学', 'C-', null],
            ['华中科技大学', 'A-', null],
            ['吉林大学', 'B', null],
            ['济南大学', 'C+', null],
            ['暨南大学', 'C+', null],
            ['江苏大学', 'C', null],
            ['锦州医科大学', 'C', null],
            ['昆明医科大学', 'B-', null],
            ['兰州大学', 'C+', null],
            ['南昌大学', 'B-', null],
            ['南方医科大学', 'B', null],
            ['南京医科大学', 'B+', null],
            ['南开大学', 'B-', null],
            ['南通大学', 'C', null],
            ['内蒙古医科大学', 'C-', null],
            ['宁夏医科大学', 'C+', null],
            ['青岛大学', 'C+', null],
            ['青海大学', 'C-', null],
            ['厦门大学', 'C', null],
            ['山东大学', 'B+', null],
            ['山西医科大学', 'B-', null],
            ['汕头大学', 'C+', null],
            ['上海交通大学', 'A+', '临床医学'],
            ['石河子大学', 'C-', null],
            ['首都医科大学', 'A-', null],
            ['四川大学', 'A-', null],
            ['苏州大学', 'B-', null],
            ['天津医科大学', 'B', '临床医学（自定）'],
            ['同济大学', 'B', null],
            ['温州医科大学', 'B-', null],
            ['武汉大学', 'B', null],
            ['西安交通大学', 'B', null],
            ['西南医科大学', 'C-', null],
            ['新疆医科大学', 'C+', null],
            ['新乡医学院', 'C', null],
            ['徐州医科大学', 'C+', null],
            ['延边大学', 'C', null],
            ['浙江大学', 'A+', null],
            ['浙江中医药大学', 'C-', null],
            ['郑州大学', 'B', '临床医学（自定）'],
            ['中国医科大学', 'B+', null],
            ['中南大学', 'A-', null],
            ['中山大学', 'A-', '临床医学'],
            ['重庆医科大学', 'B+', null],
            ['遵义医学院', 'C-', null]
          ]
        ],
        [
          '马克思主义理论',
          [
            ['安徽大学', 'B-', null],
            ['安徽工业大学', 'C', null],
            ['安徽师范大学', 'B', null],
            ['北方工业大学', 'C', null],
            ['北华大学', 'C', null],
            ['北京大学', 'A', '马克思主义理论'],
            ['北京工商大学', 'C-', null],
            ['北京航空航天大学', 'B-', null],
            ['北京化工大学', 'C-', null],
            ['北京交通大学', 'B+', null],
            ['北京科技大学', 'B', null],
            ['北京理工大学', 'C+', null],
            ['北京师范大学', 'A-', null],
            ['渤海大学', 'C', null],
            ['成都理工大学', 'C-', null],
            ['大连海事大学', 'B', null],
            ['东北林业大学', 'C+', null],
            ['东北师范大学', 'A+', '马克思主义理论'],
            ['福建农林大学', 'C+', null],
            ['福建师范大学', 'A-', null],
            ['福州大学', 'C+', null],
            ['复旦大学', 'A', null],
            ['赣南师范大学', 'C', null],
            ['广东财经大学', 'C-', null],
            ['广西大学', 'B-', null],
            ['广西民族大学', 'C', null],
            ['广西师范大学', 'B+', null],
            ['广西师范学院', 'C-', null],
            ['广州大学', 'C+', null],
            ['贵州财经大学', 'C', null],
            ['贵州师范大学', 'B', null],
            ['哈尔滨工程大学', 'B', null],
            ['哈尔滨工业大学', 'C+', null],
            ['哈尔滨理工大学', 'B-', null],
            ['哈尔滨师范大学', 'B+', null],
            ['海南大学', 'C+', null],
            ['海南师范大学', 'B-', null],
            ['杭州师范大学', 'C+', null],
            ['合肥工业大学', 'B+', null],
            ['河北大学', 'B-', null],
            ['河北工业大学', 'C+', null],
            ['河北师范大学', 'B', null],
            ['河海大学', 'B+', null],
            ['河南大学', 'B', null],
            ['河南工业大学', 'C-', null],
            ['河南科技大学', 'C-', null],
            ['河南理工大学', 'C+', null],
            ['河南农业大学', 'C-', null],
            ['河南师范大学', 'C+', null],
            ['黑龙江大学', 'B', null],
            ['湖北大学', 'B-', null],
            ['湖南大学', 'B+', null],
            ['湖南科技大学', 'B', null],
            ['湖南师范大学', 'B', null],
            ['华北电力大学', 'C', null],
            ['华东理工大学', 'B-', null],
            ['华东师范大学', 'A-', null],
            ['华东政法大学', 'C+', null],
            ['华南理工大学', 'B', null],
            ['华南师范大学', 'A-', null],
            ['华中科技大学', 'B+', null],
            ['华中师范大学', 'A', null],
            ['吉林大学', 'A', null],
            ['江南大学', 'B-', null],
            ['江苏大学', 'B-', null],
            ['江苏师范大学', 'B-', null],
            ['江西财经大学', 'C+', null],
            ['江西理工大学', 'C-', null],
            ['江西师范大学', 'A-', null],
            ['兰州大学', 'B+', null],
            ['兰州理工大学', 'C-', null],
            ['辽宁大学', 'B+', null],
            ['辽宁师范大学', 'B-', null],
            ['辽宁石油化工大学', 'C-', null],
            ['南昌航空大学', 'C+', null],
            ['南华大学', 'C-', null],
            ['南京财经大学', 'C-', null],
            ['南京大学', 'B+', null],
            ['南京航空航天大学', 'B', null],
            ['南京理工大学', 'B-', null],
            ['南京师范大学', 'A', null],
            ['南京政治学院', 'B+', null],
            ['南开大学', 'A-', null],
            ['南通大学', 'C', null],
            ['内蒙古大学', 'B-', null],
            ['宁波大学', 'C-', null],
            ['清华大学', 'A+', '马克思主义理论'],
            ['曲阜师范大学', 'B-', null],
            ['三峡大学', 'C+', null],
            ['厦门大学', 'B', null],
            ['山东财经大学', 'C-', null],
            ['山东大学', 'A', null],
            ['山东师范大学', 'B+', null],
            ['山西财经大学', 'C', null],
            ['山西大学', 'B-', null],
            ['山西师范大学', 'C+', null],
            ['陕西师范大学', 'B+', null],
            ['上海财经大学', 'B+', null],
            ['上海大学', 'B+', null],
            ['上海交通大学', 'B', null],
            ['上海师范大学', 'B', null],
            ['深圳大学', 'C', null],
            ['沈阳师范大学', 'C-', null],
            ['首都师范大学', 'A-', null],
            ['四川大学', 'A-', null],
            ['苏州大学', 'B+', null],
            ['天津师范大学', 'B+', null],
            ['同济大学', 'B+', null],
            ['温州大学', 'B-', null],
            ['武汉大学', 'A+', '马克思主义理论'],
            ['武汉纺织大学', 'C', null],
            ['武汉工程大学', 'C+', null],
            ['武汉科技大学', 'C', null],
            ['武汉理工大学', 'B+', null],
            ['西安交通大学', 'A-', null],
            ['西安科技大学', 'B-', null],
            ['西安理工大学', 'B', null],
            ['西北师范大学', 'B-', null],
            ['西华大学', 'C+', null],
            ['西华师范大学', 'C', null],
            ['西南财经大学', 'B-', null],
            ['西南大学', 'A-', null],
            ['西南交通大学', 'B+', null],
            ['西南科技大学', 'C', null],
            ['西南石油大学', 'C', null],
            ['西南政法大学', 'C', null],
            ['湘潭大学', 'B+', null],
            ['新疆大学', 'B', '马克思主义理论（自定）'],
            ['新疆师范大学', 'B', null],
            ['信阳师范学院', 'C+', null],
            ['延安大学', 'C', null],
            ['燕山大学', 'C-', null],
            ['扬州大学', 'B', null],
            ['云南财经大学', 'C-', null],
            ['云南大学', 'B-', null],
            ['云南师范大学', 'C', null],
            ['长安大学', 'C+', null],
            ['长春理工大学', 'C', null],
            ['长春师范大学', 'C-', null],
            ['长沙理工大学', 'C', null],
            ['浙江大学', 'A-', null],
            ['浙江工商大学', 'C+', null],
            ['浙江理工大学', 'C+', null],
            ['浙江师范大学', 'B', null],
            ['郑州大学', 'B+', null],
            ['中国地质大学', 'B', null],
            ['中国矿业大学', 'B-', null],
            ['中国农业大学', 'C-', null],
            ['中国人民大学', 'A+', '马克思主义理论'],
            ['中国石油大学', 'B', null],
            ['中国政法大学', 'B', null],
            ['中南财经政法大学', 'C+', null],
            ['中南大学', 'A-', null],
            ['中南民族大学', 'B-', null],
            ['中山大学', 'A', null],
            ['中央财经大学', 'B+', null],
            ['中央民族大学', 'C', null],
            ['重庆大学', 'C', null],
            ['重庆工商大学', 'C-', null],
            ['重庆交通大学', 'C', null],
            ['重庆师范大学', 'C+', null],
            ['重庆邮电大学', 'C+', null]
          ]
        ],
        [
          '美术学',
          [
            ['安徽师范大学', 'C', null],
            ['北京电影学院', 'B+', null],
            ['北京服装学院', 'B-', null],
            ['北京师范大学', 'C-', null],
            ['北京印刷学院', 'C+', null],
            ['东北师范大学', 'B+', null],
            ['东华大学', 'C', null],
            ['福建师范大学', 'B', null],
            ['福州大学', 'C', null],
            ['广西师范大学', 'B-', null],
            ['广西艺术学院', 'B+', null],
            ['广州大学', 'C+', null],
            ['广州美术学院', 'B+', null],
            ['哈尔滨师范大学', 'B', null],
            ['杭州师范大学', 'B-', null],
            ['河北大学', 'C-', null],
            ['河北师范大学', 'C+', null],
            ['河南大学', 'C-', null],
            ['河南师范大学', 'C', null],
            ['湖北美术学院', 'B+', null],
            ['湖南师范大学', 'B', null],
            ['华南师范大学', 'C+', null],
            ['华中师范大学', 'C+', null],
            ['吉林艺术学院', 'B-', null],
            ['江南大学', 'B-', null],
            ['江苏大学', 'C-', null],
            ['江西师范大学', 'C-', null],
            ['景德镇陶瓷大学', 'B+', null],
            ['辽宁师范大学', 'C-', null],
            ['鲁迅美术学院', 'B+', null],
            ['南京师范大学', 'A-', null],
            ['南京艺术学院', 'A', null],
            ['内蒙古师范大学', 'C', null],
            ['清华大学', 'A-', null],
            ['厦门大学', 'C', null],
            ['山东工艺美术学院', 'C', null],
            ['山东师范大学', 'B', null],
            ['山西大学', 'C', null],
            ['山西师范大学', 'C-', null],
            ['上海大学', 'A-', null],
            ['上海师范大学', 'B', null],
            ['深圳大学', 'C+', null],
            ['沈阳师范大学', 'C+', null],
            ['首都师范大学', 'A-', null],
            ['四川大学', 'B+', null],
            ['四川美术学院', 'A-', null],
            ['四川师范大学', 'C', null],
            ['苏州大学', 'B-', null],
            ['天津美术学院', 'B+', null],
            ['西安建筑科技大学', 'C+', null],
            ['西安美术学院', 'A', null],
            ['西北师范大学', 'B-', null],
            ['西南大学', 'B', null],
            ['西南民族大学', 'C+', null],
            ['新疆师范大学', 'B', null],
            ['云南大学', 'C-', null],
            ['云南艺术学院', 'B', null],
            ['浙江师范大学', 'B-', null],
            ['中国传媒大学', 'B', null],
            ['中国美术学院', 'A+', '美术学'],
            ['中国人民大学', 'B-', null],
            ['中央美术学院', 'A+', null],
            ['中央民族大学', 'B', null],
            ['重庆大学', 'C', null],
            ['重庆师范大学', 'C', null]
          ]
        ],
        [
          '民族学',
          [
            ['北方民族大学', 'C-', null],
            ['广西民族大学', 'B', null],
            ['贵州民族大学', 'C-', null],
            ['湖北民族学院', 'C', null],
            ['吉首大学', 'C-', null],
            ['兰州大学', 'B+', null],
            ['内蒙古大学', 'B', null],
            ['内蒙古师范大学', 'C', null],
            ['宁夏大学', 'B-', null],
            ['厦门大学', 'B-', null],
            ['陕西师范大学', 'B-', null],
            ['四川大学', 'C+', null],
            ['西北民族大学', 'C+', null],
            ['西藏民族大学', 'C', null],
            ['西南民族大学', 'B+', null],
            ['新疆师范大学', 'C+', null],
            ['云南大学', 'A+', '民族学'],
            ['云南民族大学', 'B', null],
            ['中南民族大学', 'A-', null],
            ['中山大学', 'B+', null],
            ['中央民族大学', 'A+', '民族学']
          ]
        ],
        [
          '农林经济管理',
          [
            ['北京林业大学', 'B', null],
            ['北京农学院', 'C', null],
            ['东北林业大学', 'B', null],
            ['东北农业大学', 'B', null],
            ['福建农林大学', 'B-', null],
            ['河北农业大学', 'C', null],
            ['河南农业大学', 'C+', null],
            ['湖南农业大学', 'C+', null],
            ['华南农业大学', 'B+', null],
            ['华中农业大学', 'A-', '农林经济管理'],
            ['吉林农业大学', 'B-', null],
            ['江西农业大学', 'C-', null],
            ['南京农业大学', 'A+', null],
            ['内蒙古农业大学', 'C', null],
            ['上海海洋大学', 'C-', null],
            ['沈阳农业大学', 'B-', null],
            ['石河子大学', 'C-', null],
            ['四川农业大学', 'B-', null],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'B', null],
            ['新疆农业大学', 'C', null],
            ['浙江大学', 'A+', '农林经济管理'],
            ['浙江农林大学', 'C-', null],
            ['中国海洋大学', 'C+', null],
            ['中国农业大学', 'B+', null],
            ['中国人民大学', 'B+', '农林经济管理'],
            ['中南财经政法大学', 'C+', null]
          ]
        ],
        [
          '农业工程',
          [
            ['东北农业大学', 'B+', null],
            ['甘肃农业大学', 'C', null],
            ['河北农业大学', 'C', null],
            ['河海大学', 'B', null],
            ['河南农业大学', 'B', null],
            ['黑龙江八一农垦大学', 'C+', null],
            ['华北水利水电大学', 'C-', null],
            ['华南农业大学', 'B+', null],
            ['华中农业大学', 'B', null],
            ['吉林大学', 'B+', null],
            ['江苏大学', 'A-', null],
            ['南京农业大学', 'B', null],
            ['内蒙古农业大学', 'B-', null],
            ['青岛农业大学', 'C', null],
            ['山东理工大学', 'C+', null],
            ['沈阳农业大学', 'B-', null],
            ['石河子大学', 'B-', null],
            ['西安理工大学', 'C-', null],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'C+', null],
            ['扬州大学', 'C', null],
            ['云南农业大学', 'C-', null],
            ['云南师范大学', 'C+', null],
            ['浙江大学', 'A+', '农业工程'],
            ['中国农业大学', 'A+', '农业工程']
          ]
        ],
        [
          '农业资源与环境',
          [
            ['安徽农业大学', 'C-', null],
            ['东北农业大学', 'C+', null],
            ['福建农林大学', 'B-', null],
            ['河北农业大学', 'C+', null],
            ['河南农业大学', 'C', null],
            ['湖南农业大学', 'B', null],
            ['华南农业大学', 'B', null],
            ['华中农业大学', 'B+', null],
            ['吉林农业大学', 'B-', null],
            ['南京农业大学', 'A+', '农业资源与环境'],
            ['内蒙古农业大学', 'C', null],
            ['青岛农业大学', 'C-', null],
            ['山东农业大学', 'B-', null],
            ['山西农业大学', 'C+', null],
            ['沈阳农业大学', 'B', null],
            ['四川农业大学', 'B', null],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'B+', null],
            ['扬州大学', 'C-', null],
            ['云南农业大学', 'C', null],
            ['浙江大学', 'A+', null],
            ['浙江农林大学', 'C+', null],
            ['中国农业大学', 'A-', '农业资源与环境']
          ]
        ],
        [
          '轻工技术与工程',
          [
            ['北京化工大学', 'C', null],
            ['大连工业大学', 'B', null],
            ['广西大学', 'C+', null],
            ['湖北工业大学', 'B-', null],
            ['华南理工大学', 'A+', '轻工技术与工程'],
            ['华中农业大学', 'C', null],
            ['江南大学', 'A+', '轻工技术与工程'],
            ['南京工业大学', 'B', null],
            ['南京林业大学', 'C+', null],
            ['齐鲁工业大学', 'B-', null],
            ['陕西科技大学', 'B+', null],
            ['四川大学', 'B', null],
            ['天津科技大学', 'B+', null],
            ['武汉大学', 'C+', null],
            ['西安理工大学', 'C-', null],
            ['西北农林科技大学', 'C-', null]
          ]
        ],
        [
          '软件工程',
          [
            ['安徽大学', 'B-', null],
            ['北方工业大学', 'C', null],
            ['北京大学', 'A', '软件工程'],
            ['北京工业大学', 'B+', null],
            ['北京航空航天大学', 'A+', '软件工程'],
            ['北京化工大学', 'C', null],
            ['北京交通大学', 'B+', null],
            ['北京理工大学', 'B+', null],
            ['北京联合大学', 'B-', null],
            ['北京邮电大学', 'B+', null],
            ['成都信息工程大学', 'C+', null],
            ['大连大学', 'B-', null],
            ['大连海事大学', 'C', null],
            ['大连交通大学', 'C', null],
            ['大连理工大学', 'B+', null],
            ['电子科技大学', 'B+', null],
            ['东北大学', 'A-', null],
            ['东华大学', 'B-', null],
            ['东南大学', 'B+', null],
            ['福建师范大学', 'C-', null],
            ['福州大学', 'B-', null],
            ['复旦大学', 'B+', null],
            ['广东工业大学', 'C+', null],
            ['广西大学', 'C+', null],
            ['广西师范大学', 'B-', null],
            ['贵州大学', 'B-', null],
            ['桂林电子科技大学', 'C+', null],
            ['桂林理工大学', 'C-', null],
            ['国防科技大学', 'A+', '软件工程'],
            ['哈尔滨工程大学', 'B', null],
            ['哈尔滨工业大学', 'A-', null],
            ['哈尔滨理工大学', 'C+', null],
            ['海军航空工程学院', 'C-', null],
            ['杭州电子科技大学', 'C+', null],
            ['合肥工业大学', 'B', null],
            ['河北大学', 'C+', null],
            ['河海大学', 'B', null],
            ['河南理工大学', 'C', null],
            ['黑龙江大学', 'C-', null],
            ['湖南科技大学', 'C-', null],
            ['华北电力大学', 'B-', null],
            ['华东师范大学', 'A', null],
            ['华南理工大学', 'B+', null],
            ['华侨大学', 'C', null],
            ['华中师范大学', 'C', null],
            ['淮北师范大学', 'C-', null],
            ['吉林大学', 'B+', null],
            ['江南大学', 'B', null],
            ['江苏大学', 'C+', null],
            ['江苏科技大学', 'C-', null],
            ['解放军理工大学', 'B+', null],
            ['解放军信息工程大学', 'B', null],
            ['昆明理工大学', 'C-', null],
            ['辽宁工程技术大学', 'C', null],
            ['辽宁科技大学', 'C+', null],
            ['聊城大学', 'C-', null],
            ['南昌航空大学', 'C+', null],
            ['南华大学', 'C', null],
            ['南京财经大学', 'C-', null],
            ['南京大学', 'A', null],
            ['南京工业大学', 'C-', null],
            ['南京航空航天大学', 'B+', null],
            ['南京理工大学', 'B', null],
            ['南京信息工程大学', 'C+', null],
            ['南京邮电大学', 'B', null],
            ['南开大学', 'B', null],
            ['内蒙古大学', 'C-', null],
            ['青岛大学', 'C', null],
            ['青岛科技大学', 'C', null],
            ['清华大学', 'A', '软件工程'],
            ['厦门大学', 'B', null],
            ['山东大学', 'B+', null],
            ['山西大学', 'C+', null],
            ['陕西科技大学', 'C', null],
            ['上海大学', 'B-', null],
            ['上海交通大学', 'A-', null],
            ['上海理工大学', 'C', null],
            ['首都师范大学', 'B-', null],
            ['四川大学', 'A-', null],
            ['四川师范大学', 'C-', null],
            ['苏州大学', 'A-', null],
            ['太原理工大学', 'C-', null],
            ['天津大学', 'A-', null],
            ['天津工业大学', 'C+', null],
            ['天津理工大学', 'B-', null],
            ['同济大学', 'A-', null],
            ['武汉大学', 'A', null],
            ['武汉科技大学', 'C', null],
            ['武汉理工大学', 'B-', null],
            ['西安电子科技大学', 'B+', null],
            ['西安交通大学', 'B', null],
            ['西安邮电大学', 'C', null],
            ['西北大学', 'B+', null],
            ['西北工业大学', 'B+', null],
            ['西华大学', 'C-', null],
            ['西南大学', 'B-', null],
            ['西南交通大学', 'B-', null],
            ['西南石油大学', 'C', null],
            ['湘潭大学', 'C', null],
            ['新疆大学', 'C+', null],
            ['燕山大学', 'B-', null],
            ['云南大学', 'B-', null],
            ['长安大学', 'C-', null],
            ['长春理工大学', 'C', null],
            ['浙江大学', 'A+', '软件工程'],
            ['浙江工业大学', 'B', null],
            ['浙江理工大学', 'C+', null],
            ['浙江师范大学', 'B-', null],
            ['郑州大学', 'B', null],
            ['郑州轻工业学院', 'C', null],
            ['中国地质大学', 'B', null],
            ['中国海洋大学', 'B', null],
            ['中国科学技术大学', 'A-', null],
            ['中国矿业大学', 'C-', null],
            ['中国人民大学', 'B', null],
            ['中山大学', 'B', null],
            ['重庆大学', 'B+', null],
            ['重庆邮电大学', 'B-', null]
          ]
        ],
        [
          '设计学',
          [
            ['北方工业大学', 'C-', null],
            ['北京服装学院', 'B+', null],
            ['北京工业大学', 'C', null],
            ['北京交通大学', 'C-', null],
            ['北京理工大学', 'B', null],
            ['北京林业大学', 'C+', null],
            ['北京印刷学院', 'B-', null],
            ['大连工业大学', 'C+', null],
            ['东华大学', 'B+', null],
            ['东南大学', 'B', null],
            ['福州大学', 'C+', null],
            ['广东工业大学', 'B', null],
            ['广西艺术学院', 'B', null],
            ['广州美术学院', 'B+', null],
            ['哈尔滨工业大学', 'B', null],
            ['湖北工业大学', 'B-', null],
            ['湖北美术学院', 'B', null],
            ['湖南大学', 'A-', null],
            ['湖南工业大学', 'C+', null],
            ['湖南师范大学', 'C', null],
            ['华中科技大学', 'B-', null],
            ['华中师范大学', 'C-', null],
            ['吉林大学', 'C-', null],
            ['吉林艺术学院', 'C+', null],
            ['江南大学', 'A-', null],
            ['景德镇陶瓷大学', 'B+', null],
            ['鲁迅美术学院', 'B', null],
            ['南昌大学', 'C', null],
            ['南京理工大学', 'C+', null],
            ['南京师范大学', 'B-', null],
            ['南京艺术学院', 'A-', null],
            ['齐鲁工业大学', 'C-', null],
            ['清华大学', 'A+', '设计学'],
            ['厦门大学', 'C-', null],
            ['山东工艺美术学院', 'B', null],
            ['陕西科技大学', 'C+', null],
            ['上海大学', 'B', null],
            ['上海交通大学', 'B+', null],
            ['上海戏剧学院', 'C', null],
            ['深圳大学', 'C+', null],
            ['沈阳航空航天大学', 'C', null],
            ['首都师范大学', 'C', null],
            ['四川大学', 'B', null],
            ['四川美术学院', 'B+', null],
            ['苏州大学', 'A-', null],
            ['天津工业大学', 'C', null],
            ['天津美术学院', 'B-', null],
            ['同济大学', 'A', null],
            ['武汉纺织大学', 'B-', null],
            ['武汉理工大学', 'B+', null],
            ['西安工程大学', 'C', null],
            ['西安理工大学', 'C-', null],
            ['西安美术学院', 'B+', null],
            ['西北工业大学', 'B-', null],
            ['西南交通大学', 'C+', null],
            ['云南艺术学院', 'C', null],
            ['浙江大学', 'A-', null],
            ['浙江工业大学', 'B-', null],
            ['浙江理工大学', 'B-', null],
            ['中国传媒大学', 'B+', null],
            ['中国地质大学', 'C+', null],
            ['中国美术学院', 'A+', null],
            ['中国人民大学', 'C-', null],
            ['中央美术学院', 'A', null],
            ['重庆大学', 'C-', null]
          ]
        ],
        [
          '社会学',
          [
            ['安徽大学', 'C+', null],
            ['北京大学', 'A+', '社会学'],
            ['北京师范大学', 'B-', null],
            ['复旦大学', 'A-', null],
            ['赣南师范大学', 'C-', null],
            ['贵州民族大学', 'C', null],
            ['哈尔滨工程大学', 'C-', null],
            ['哈尔滨工业大学', 'C+', null],
            ['河海大学', 'B', null],
            ['湖南师范大学', 'C-', null],
            ['华东理工大学', 'B+', null],
            ['华东师范大学', 'B+', null],
            ['华中科技大学', 'B+', null],
            ['华中师范大学', 'B', null],
            ['吉林大学', 'B+', null],
            ['济南大学', 'C', null],
            ['兰州大学', 'C', null],
            ['南京大学', 'A', null],
            ['南京师范大学', 'C', null],
            ['南开大学', 'B+', null],
            ['清华大学', 'A-', null],
            ['厦门大学', 'B', null],
            ['山东大学', 'B-', null],
            ['上海大学', 'A-', null],
            ['沈阳师范大学', 'C-', null],
            ['四川大学', 'C+', null],
            ['苏州大学', 'C', null],
            ['武汉大学', 'B', null],
            ['西安交通大学', 'B-', null],
            ['西北民族大学', 'C-', null],
            ['西北农林科技大学', 'C', null],
            ['西南财经大学', 'C+', null],
            ['西南大学', 'C', null],
            ['云南大学', 'C+', null],
            ['云南民族大学', 'B-', null],
            ['云南师范大学', 'C-', null],
            ['浙江大学', 'B', null],
            ['浙江师范大学', 'C+', null],
            ['中国农业大学', 'B', null],
            ['中国人民大学', 'A+', '社会学'],
            ['中国政法大学', 'B-', null],
            ['中南大学', 'B-', null],
            ['中山大学', 'B+', null],
            ['中央财经大学', 'B-', null],
            ['中央民族大学', 'B+', null]
          ]
        ],
        [
          '生态学',
          [
            ['安徽大学', 'B', null],
            ['安徽农业大学', 'B-', null],
            ['安徽师范大学', 'B-', null],
            ['北京大学', 'A', '生态学'],
            ['北京林业大学', 'B', null],
            ['北京师范大学', 'A-', '生态学'],
            ['东北林业大学', 'B', null],
            ['东北师范大学', 'A', null],
            ['福建农林大学', 'B+', null],
            ['福建师范大学', 'B-', null],
            ['复旦大学', 'A-', '生态学'],
            ['广西大学', 'B-', null],
            ['贵州大学', 'C', null],
            ['贵州师范大学', 'C-', null],
            ['海南师范大学', 'C+', null],
            ['杭州师范大学', 'C+', null],
            ['河北大学', 'C', null],
            ['河北农业大学', 'C-', null],
            ['河北师范大学', 'B', null],
            ['河南大学', 'C', null],
            ['黑龙江大学', 'C-', null],
            ['湖北大学', 'C', null],
            ['湖南农业大学', 'B-', null],
            ['湖南师范大学', 'B-', null],
            ['华东师范大学', 'A-', '生态学'],
            ['华南师范大学', 'B-', null],
            ['华中农业大学', 'C+', null],
            ['华中师范大学', 'C-', null],
            ['吉林农业大学', 'C-', null],
            ['吉首大学', 'C-', null],
            ['暨南大学', 'B-', null],
            ['兰州大学', 'A', '生态学'],
            ['辽宁大学', 'C', null],
            ['辽宁师范大学', 'C-', null],
            ['南昌大学', 'C', null],
            ['南京大学', 'A-', null],
            ['南京师范大学', 'C+', null],
            ['南京信息工程大学', 'C', null],
            ['南开大学', 'B+', null],
            ['内蒙古大学', 'B', null],
            ['宁夏大学', 'C-', null],
            ['清华大学', 'B+', null],
            ['三峡大学', 'C-', null],
            ['厦门大学', 'B+', '生态学'],
            ['山东大学', 'B-', null],
            ['山西大学', 'B-', null],
            ['陕西师范大学', 'C+', null],
            ['上海交通大学', 'B+', null],
            ['沈阳师范大学', 'C', null],
            ['首都师范大学', 'B', null],
            ['四川大学', 'B+', null],
            ['武汉大学', 'B+', null],
            ['西北大学', 'B', null],
            ['西北农林科技大学', 'B+', null],
            ['西华师范大学', 'C+', null],
            ['西南大学', 'C+', null],
            ['西南林业大学', 'C', null],
            ['新疆大学', 'C+', null],
            ['云南大学', 'A-', '生态学'],
            ['浙江大学', 'A+', '生态学'],
            ['浙江农林大学', 'C+', null],
            ['浙江师范大学', 'C-', null],
            ['中国海洋大学', 'B', null],
            ['中国科学技术大学', 'B+', null],
            ['中国农业大学', 'B+', null],
            ['中国人民大学', 'C', null],
            ['中南林业科技大学', 'B', null],
            ['中山大学', 'A+', '生态学'],
            ['中央民族大学', 'C+', null],
            ['重庆大学', 'B', null]
          ]
        ],
        [
          '生物学',
          [
            ['安徽大学', 'C', null],
            ['安徽农业大学', 'B', null],
            ['安徽师范大学', 'C+', null],
            ['安徽医科大学', 'C-', null],
            ['北京大学', 'A+', '生物学'],
            ['北京工业大学', 'C-', null],
            ['北京林业大学', 'B', null],
            ['北京师范大学', 'B+', null],
            ['大连理工大学', 'C+', null],
            ['大连医科大学', 'B', null],
            ['第四军医大学', 'B+', null],
            ['东北大学', 'C', null],
            ['东北林业大学', 'B', null],
            ['东北师范大学', 'A-', null],
            ['福建农林大学', 'B', null],
            ['福建师范大学', 'B-', null],
            ['福州大学', 'C-', null],
            ['复旦大学', 'A-', '生物学'],
            ['广西大学', 'B-', null],
            ['贵州大学', 'B-', null],
            ['哈尔滨工业大学', 'C', null],
            ['哈尔滨师范大学', 'C', null],
            ['哈尔滨医科大学', 'B', null],
            ['杭州师范大学', 'B-', null],
            ['合肥工业大学', 'C-', null],
            ['河北大学', 'B-', null],
            ['河北农业大学', 'B-', null],
            ['河北师范大学', 'B-', null],
            ['河北医科大学', 'C+', null],
            ['河南大学', 'B', '生物学'],
            ['河南农业大学', 'C', null],
            ['河南师范大学', 'B', null],
            ['黑龙江大学', 'C', null],
            ['湖北大学', 'B', null],
            ['湖南大学', 'C+', null],
            ['湖南农业大学', 'B', null],
            ['湖南师范大学', 'B', null],
            ['华东理工大学', 'C+', null],
            ['华东师范大学', 'B+', null],
            ['华南理工大学', 'B-', null],
            ['华南师范大学', 'B-', null],
            ['华中科技大学', 'A-', null],
            ['华中农业大学', 'A', '生物学'],
            ['华中师范大学', 'B', null],
            ['吉林大学', 'B+', null],
            ['吉林农业大学', 'C+', null],
            ['暨南大学', 'B+', null],
            ['江苏大学', 'C-', null],
            ['江苏科技大学', 'C-', null],
            ['江苏师范大学', 'C', null],
            ['锦州医科大学', 'C-', null],
            ['昆明理工大学', 'C', null],
            ['兰州大学', 'B+', null],
            ['辽宁大学', 'C-', null],
            ['辽宁师范大学', 'C+', null],
            ['南昌大学', 'B-', null],
            ['南京大学', 'A', '生物学'],
            ['南京师范大学', 'B+', null],
            ['南开大学', 'A-', null],
            ['内蒙古大学', 'B', '生物学（自定）'],
            ['宁夏大学', 'C-', null],
            ['青岛大学', 'C+', null],
            ['清华大学', 'A+', '生物学'],
            ['三峡大学', 'C-', null],
            ['厦门大学', 'A-', '生物学'],
            ['山东大学', 'B+', null],
            ['山东师范大学', 'B-', null],
            ['山西大学', 'B-', null],
            ['山西医科大学', 'B-', null],
            ['陕西师范大学', 'B+', null],
            ['汕头大学', 'C+', null],
            ['上海大学', 'C', null],
            ['上海海洋大学', 'B-', null],
            ['上海交通大学', 'A+', '生物学'],
            ['上海师范大学', 'C+', null],
            ['沈阳师范大学', 'C-', null],
            ['首都师范大学', 'B+', null],
            ['首都医科大学', 'B+', null],
            ['四川大学', 'A-', null],
            ['四川农业大学', 'B-', null],
            ['苏州大学', 'C', null],
            ['天津师范大学', 'C', null],
            ['同济大学', 'B+', null],
            ['温州医科大学', 'C+', null],
            ['武汉大学', 'A', '生物学'],
            ['西安交通大学', 'B-', null],
            ['西北大学', 'B', null],
            ['西北工业大学', 'C-', null],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'B', '生物学'],
            ['西南科技大学', 'C-', null],
            ['新疆大学', 'C+', null],
            ['延边大学', 'C', null],
            ['扬州大学', 'C+', null],
            ['云南大学', 'B+', null],
            ['云南师范大学', 'C-', null],
            ['浙江大学', 'A-', '生物学'],
            ['浙江理工大学', 'C', null],
            ['浙江农林大学', 'C', null],
            ['浙江师范大学', 'C+', null],
            ['郑州大学', 'C', null],
            ['中国海洋大学', 'B+', null],
            ['中国计量大学', 'C', null],
            ['中国科学技术大学', 'A', '生物学'],
            ['中国农业大学', 'A', '生物学'],
            ['中国医科大学', 'B', null],
            ['中南大学', 'B+', null],
            ['中南林业科技大学', 'C+', null],
            ['中南民族大学', 'C-', null],
            ['中山大学', 'A-', '生物学'],
            ['重庆大学', 'C+', null],
            ['重庆师范大学', 'C-', null],
            ['北京协和医学院', null, '生物学']
          ]
        ],
        [
          '生物医学工程',
          [
            ['北京大学', 'B+', null],
            ['北京工业大学', 'B', null],
            ['北京航空航天大学', 'A-', null],
            ['北京理工大学', 'B', null],
            ['北京邮电大学', 'C-', null],
            ['大连理工大学', 'B-', null],
            ['第四军医大学', 'B', null],
            ['电子科技大学', 'B+', null],
            ['东北大学', 'B-', null],
            ['东华大学', 'C-', null],
            ['东南大学', 'A+', '生物医学工程'],
            ['复旦大学', 'B+', null],
            ['国防科技大学', 'C', null],
            ['哈尔滨工程大学', 'C-', null],
            ['哈尔滨工业大学', 'B', null],
            ['哈尔滨医科大学', 'B-', null],
            ['河北工业大学', 'C-', null],
            ['湖南工业大学', 'C', null],
            ['华南理工大学', 'B+', null],
            ['华中科技大学', 'A+', null],
            ['吉林大学', 'C-', null],
            ['暨南大学', 'B-', null],
            ['南方医科大学', 'B', null],
            ['南京大学', 'C+', null],
            ['南京航空航天大学', 'C-', null],
            ['清华大学', 'A-', '生物医学工程'],
            ['厦门大学', 'C', null],
            ['山东大学', 'C+', null],
            ['上海交通大学', 'A', null],
            ['上海理工大学', 'B', null],
            ['深圳大学', 'B', null],
            ['首都医科大学', 'B-', null],
            ['四川大学', 'A-', null],
            ['苏州大学', 'C', null],
            ['太原理工大学', 'C', null],
            ['天津大学', 'B+', null],
            ['天津医科大学', 'C+', null],
            ['同济大学', 'B-', null],
            ['温州医科大学', 'C+', null],
            ['武汉大学', 'C', null],
            ['西安电子科技大学', 'C+', null],
            ['西安交通大学', 'B+', null],
            ['西北工业大学', 'C+', null],
            ['西南交通大学', 'C', null],
            ['长春理工大学', 'C-', null],
            ['浙江大学', 'A-', null],
            ['中国科学技术大学', 'B-', null],
            ['中山大学', 'B-', null],
            ['重庆大学', 'B+', null],
            ['北京协和医学院', null, '生物医学工程']
          ]
        ],
        [
          '石油与天然气工程',
          [
            ['成都理工大学', 'C-', null],
            ['东北石油大学', 'B', null],
            ['西安石油大学', 'C', null],
            ['西南石油大学', 'A+', '石油与天然气工程'],
            ['长江大学', 'C+', null],
            ['中国地质大学', 'B-', null],
            ['中国石油大学', 'A+', '石油与天然气工程'],
            ['中国石油大学(北京)', null, '石油与天然气工程']
          ]
        ],
        [
          '食品科学与工程',
          [
            ['安徽农业大学', 'C-', null],
            ['北京工商大学', 'B', null],
            ['渤海大学', 'C', null],
            ['大连工业大学', 'B+', null],
            ['东北农业大学', 'B+', null],
            ['福建农林大学', 'B', null],
            ['福州大学', 'C', null],
            ['甘肃农业大学', 'C-', null],
            ['广东海洋大学', 'B-', null],
            ['哈尔滨工业大学', 'C+', null],
            ['哈尔滨商业大学', 'B-', null],
            ['海南大学', 'C', null],
            ['合肥工业大学', 'B', null],
            ['河北农业大学', 'B-', null],
            ['河南工业大学', 'B-', null],
            ['河南科技大学', 'C', null],
            ['河南农业大学', 'C+', null],
            ['黑龙江八一农垦大学', 'C+', null],
            ['华南理工大学', 'A-', null],
            ['华南农业大学', 'B+', null],
            ['华中农业大学', 'A-', null],
            ['吉林大学', 'B', null],
            ['吉林农业大学', 'B-', null],
            ['集美大学', 'C+', null],
            ['暨南大学', 'C+', null],
            ['江南大学', 'A+', '食品科学与工程'],
            ['江苏大学', 'B+', null],
            ['南昌大学', 'A', null],
            ['南京财经大学', 'B-', null],
            ['南京农业大学', 'A-', null],
            ['内蒙古农业大学', 'B', null],
            ['宁波大学', 'C', null],
            ['青岛农业大学', 'C-', null],
            ['陕西科技大学', 'C', null],
            ['上海海洋大学', 'B+', null],
            ['上海交通大学', 'B-', null],
            ['上海理工大学', 'C-', null],
            ['沈阳农业大学', 'B', null],
            ['四川大学', 'C+', null],
            ['四川农业大学', 'C', null],
            ['天津科技大学', 'B+', null],
            ['武汉轻工大学', 'B-', null],
            ['西北农林科技大学', 'B+', null],
            ['西华大学', 'C', null],
            ['西南大学', 'B', null],
            ['扬州大学', 'C-', null],
            ['云南农业大学', 'C', null],
            ['长沙理工大学', 'C-', null],
            ['浙江大学', 'A-', null],
            ['浙江工商大学', 'B', null],
            ['浙江工业大学', 'C+', null],
            ['郑州轻工业学院', 'C+', null],
            ['中国海洋大学', 'B+', null],
            ['中国农业大学', 'A+', '食品科学与工程'],
            ['中南林业科技大学', 'C+', null]
          ]
        ],
        [
          '世界史',
          [
            ['北京大学', 'A+', '世界史'],
            ['北京师范大学', 'B+', null],
            ['东北师范大学', 'A-', '世界史'],
            ['福建师范大学', 'B-', null],
            ['复旦大学', 'B+', null],
            ['广西师范大学', 'C-', null],
            ['哈尔滨师范大学', 'C', null],
            ['河北师范大学', 'C-', null],
            ['河南大学', 'C', null],
            ['湖南师范大学', 'C-', null],
            ['华东师范大学', 'A+', null],
            ['华南师范大学', 'C+', null],
            ['华中师范大学', 'B-', null],
            ['吉林大学', 'B', null],
            ['暨南大学', 'C+', null],
            ['辽宁大学', 'C-', null],
            ['聊城大学', 'C-', null],
            ['南京大学', 'B+', null],
            ['南开大学', 'A-', '世界史'],
            ['清华大学', 'B-', null],
            ['厦门大学', 'B', null],
            ['山东大学', 'C+', null],
            ['山东师范大学', 'C', null],
            ['陕西师范大学', 'B-', null],
            ['上海大学', 'B-', null],
            ['上海师范大学', 'B', null],
            ['首都师范大学', 'A-', null],
            ['四川大学', 'B', null],
            ['苏州科技大学', 'C', null],
            ['天津师范大学', 'B+', null],
            ['武汉大学', 'B+', null],
            ['西北大学', 'B+', null],
            ['西南大学', 'C-', null],
            ['延边大学', 'C+', null],
            ['云南大学', 'C+', null],
            ['浙江大学', 'B', null],
            ['浙江师范大学', 'C', null],
            ['郑州大学', 'C+', null],
            ['中国人民大学', 'B', null],
            ['中山大学', 'B-', null]
          ]
        ],
        [
          '兽医学',
          [
            ['北京农学院', 'C-', null],
            ['东北农业大学', 'B+', null],
            ['福建农林大学', 'C-', null],
            ['甘肃农业大学', 'B-', null],
            ['广西大学', 'C+', null],
            ['河北农业大学', 'C', null],
            ['河南农业大学', 'B', null],
            ['黑龙江八一农垦大学', 'C+', null],
            ['湖南农业大学', 'B-', null],
            ['华南农业大学', 'A-', null],
            ['华中农业大学', 'A+', '兽医学'],
            ['吉林大学', 'B+', null],
            ['吉林农业大学', 'B', null],
            ['江西农业大学', 'C', null],
            ['南京农业大学', 'B+', null],
            ['内蒙古农业大学', 'C+', null],
            ['青岛农业大学', 'C', null],
            ['山东农业大学', 'B-', null],
            ['山西农业大学', 'B-', null],
            ['沈阳农业大学', 'C+', null],
            ['石河子大学', 'C-', null],
            ['四川农业大学', 'B', null],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'C', null],
            ['新疆农业大学', 'C-', null],
            ['扬州大学', 'A-', null],
            ['浙江大学', 'B', null],
            ['中国农业大学', 'A+', '兽医学']
          ]
        ],
        [
          '数学',
          [
            ['安徽大学', 'B', null],
            ['安徽师范大学', 'C', null],
            ['北方工业大学', 'C+', null],
            ['北京大学', 'A+', '数学'],
            ['北京工业大学', 'B', null],
            ['北京化工大学', 'C-', null],
            ['北京交通大学', 'B-', null],
            ['北京科技大学', 'B-', null],
            ['北京师范大学', 'A', '数学'],
            ['渤海大学', 'C-', null],
            ['成都理工大学', 'C', null],
            ['大连理工大学', 'B+', null],
            ['东北大学', 'C+', null],
            ['东北师范大学', 'B+', '数学'],
            ['东华大学', 'C+', null],
            ['福建师范大学', 'B', null],
            ['福州大学', 'B', null],
            ['复旦大学', 'A+', '数学'],
            ['广西大学', 'C+', null],
            ['广西民族大学', 'C-', null],
            ['广州大学', 'B', null],
            ['贵州大学', 'B-', null],
            ['贵州师范大学', 'C+', null],
            ['桂林电子科技大学', 'C', null],
            ['国防科技大学', 'B+', null],
            ['哈尔滨工业大学', 'A-', null],
            ['哈尔滨理工大学', 'C', null],
            ['哈尔滨师范大学', 'C', null],
            ['杭州师范大学', 'C+', null],
            ['合肥工业大学', 'B-', null],
            ['河北大学', 'C-', null],
            ['河北师范大学', 'B', null],
            ['河海大学', 'C-', null],
            ['河南大学', 'C+', null],
            ['河南理工大学', 'C-', null],
            ['河南师范大学', 'C+', null],
            ['黑龙江大学', 'C-', null],
            ['湖北大学', 'B-', null],
            ['湖北师范大学', 'C-', null],
            ['湖南大学', 'B+', null],
            ['湖南师范大学', 'B+', null],
            ['华北电力大学', 'C', null],
            ['华北理工大学', 'C', null],
            ['华北水利水电大学', 'C-', null],
            ['华东理工大学', 'B-', null],
            ['华东师范大学', 'A-', null],
            ['华南理工大学', 'B+', null],
            ['华南师范大学', 'B+', null],
            ['华中科技大学', 'B+', null],
            ['华中师范大学', 'B+', null],
            ['吉林大学', 'A-', '数学'],
            ['吉林师范大学', 'C', null],
            ['集美大学', 'C-', null],
            ['暨南大学', 'C+', null],
            ['江苏大学', 'C+', null],
            ['江苏师范大学', 'B-', null],
            ['江西师范大学', 'B-', null],
            ['解放军理工大学', 'C', null],
            ['昆明理工大学', 'C-', null],
            ['兰州大学', 'B+', null],
            ['辽宁大学', 'C-', null],
            ['辽宁师范大学', 'C+', null],
            ['南昌大学', 'C+', null],
            ['南京大学', 'A-', null],
            ['南京航空航天大学', 'B-', null],
            ['南京理工大学', 'B-', null],
            ['南京师范大学', 'B+', null],
            ['南京信息工程大学', 'B-', null],
            ['南开大学', 'A', '数学'],
            ['内蒙古大学', 'B-', null],
            ['宁波大学', 'B-', null],
            ['宁夏大学', 'C+', null],
            ['青岛大学', 'C-', null],
            ['清华大学', 'A', '数学'],
            ['曲阜师范大学', 'B-', null],
            ['厦门大学', 'B+', null],
            ['山东大学', 'A+', '数学'],
            ['山东师范大学', 'B-', null],
            ['山西大学', 'B-', null],
            ['山西师范大学', 'C-', null],
            ['陕西师范大学', 'B+', null],
            ['汕头大学', 'C+', null],
            ['上海大学', 'B+', null],
            ['上海交通大学', 'A', '数学'],
            ['上海理工大学', 'C', null],
            ['上海师范大学', 'B', null],
            ['沈阳师范大学', 'C-', null],
            ['首都师范大学', 'B+', '数学'],
            ['四川大学', 'A-', '数学'],
            ['四川师范大学', 'B-', null],
            ['苏州大学', 'B+', null],
            ['天津师范大学', 'C-', null],
            ['同济大学', 'A-', null],
            ['温州大学', 'C+', null],
            ['武汉大学', 'A-', null],
            ['西安交通大学', 'A', null],
            ['西北大学', 'B', null],
            ['西北工业大学', 'B', null],
            ['西北师范大学', 'B', null],
            ['西华师范大学', 'C-', null],
            ['西南大学', 'B', null],
            ['西南交通大学', 'C', null],
            ['西南石油大学', 'C-', null],
            ['湘潭大学', 'B+', null],
            ['新疆大学', 'B', null],
            ['信阳师范学院', 'C-', null],
            ['烟台大学', 'C', null],
            ['扬州大学', 'B-', null],
            ['云南大学', 'B', null],
            ['云南师范大学', 'C', null],
            ['长沙理工大学', 'C', null],
            ['浙江大学', 'A-', null],
            ['浙江工业大学', 'C', null],
            ['浙江理工大学', 'C+', null],
            ['浙江师范大学', 'B+', null],
            ['郑州大学', 'B', null],
            ['中北大学', 'C', null],
            ['中国地质大学', 'C', null],
            ['中国海洋大学', 'C+', null],
            ['中国科学技术大学', 'A', '数学'],
            ['中国矿业大学', 'B', null],
            ['中国民航大学', 'C-', null],
            ['中国农业大学', 'C', null],
            ['中国人民大学', 'B', null],
            ['中国石油大学', 'C+', null],
            ['中南大学', 'B+', '数学'],
            ['中山大学', 'A-', '数学'],
            ['重庆大学', 'B+', null],
            ['重庆师范大学', 'B-', null]
          ]
        ],
        [
          '水产',
          [
            ['大连海洋大学', 'B', null],
            ['广东海洋大学', 'B-', null],
            ['海南大学', 'C-', null],
            ['湖南农业大学', 'C-', null],
            ['华中农业大学', 'B+', null],
            ['集美大学', 'C+', null],
            ['南京农业大学', 'B-', null],
            ['宁波大学', 'B', null],
            ['上海海洋大学', 'A+', '水产'],
            ['天津农学院', 'C', null],
            ['西南大学', 'C', null],
            ['浙江海洋大学', 'C+', null],
            ['中国海洋大学', 'A+', '水产']
          ]
        ],
        [
          '水利工程',
          [
            ['北京工业大学', 'C-', null],
            ['北京师范大学', 'C+', null],
            ['大连理工大学', 'B+', null],
            ['合肥工业大学', 'C-', null],
            ['河海大学', 'A+', '水利工程'],
            ['华北电力大学', 'C', null],
            ['华北水利水电大学', 'B', null],
            ['华南理工大学', 'C-', null],
            ['华中科技大学', 'B', null],
            ['吉林大学', 'B-', null],
            ['南昌大学', 'C-', null],
            ['南京大学', 'B-', null],
            ['内蒙古农业大学', 'C', null],
            ['宁夏大学', 'C+', null],
            ['清华大学', 'A+', '水利工程'],
            ['三峡大学', 'B-', null],
            ['山东大学', 'C-', null],
            ['四川大学', 'B+', null],
            ['太原理工大学', 'C+', null],
            ['天津大学', 'A-', null],
            ['同济大学', 'C', null],
            ['武汉大学', 'A-', null],
            ['西安理工大学', 'B+', null],
            ['西北农林科技大学', 'B-', null],
            ['新疆农业大学', 'C+', null],
            ['扬州大学', 'C+', null],
            ['长安大学', 'C', null],
            ['长沙理工大学', 'B-', null],
            ['郑州大学', 'B+', null],
            ['中国地质大学', 'B', null],
            ['中国海洋大学', 'B', null],
            ['中国农业大学', 'B+', null],
            ['中山大学', 'C', null],
            ['重庆交通大学', 'B', null]
          ]
        ],
        [
          '体育学',
          [
            ['安徽师范大学', 'C+', null],
            ['北京师范大学', 'B+', null],
            ['北京体育大学', 'A+', '体育学'],
            ['成都体育学院', 'A-', null],
            ['东北师范大学', 'B', null],
            ['福建师范大学', 'A-', null],
            ['广西师范大学', 'C+', null],
            ['广州体育学院', 'B', null],
            ['哈尔滨师范大学', 'C-', null],
            ['杭州师范大学', 'C-', null],
            ['河北师范大学', 'B-', null],
            ['河南大学', 'B-', null],
            ['河南师范大学', 'C', null],
            ['湖北大学', 'C', null],
            ['湖南师范大学', 'B', null],
            ['华东师范大学', 'A', null],
            ['华南师范大学', 'A-', null],
            ['华中师范大学', 'B+', null],
            ['吉林大学', 'C-', null],
            ['吉林体育学院', 'B', null],
            ['吉首大学', 'C', null],
            ['集美大学', 'C-', null],
            ['江苏师范大学', 'C-', null],
            ['江西师范大学', 'C+', null],
            ['辽宁师范大学', 'B-', null],
            ['南京师范大学', 'B', null],
            ['南京体育学院', 'B-', null],
            ['宁波大学', 'B-', null],
            ['清华大学', 'B+', null],
            ['曲阜师范大学', 'B', null],
            ['山东师范大学', 'B-', null],
            ['山东体育学院', 'B-', null],
            ['山西大学', 'B', null],
            ['山西师范大学', 'C+', null],
            ['陕西师范大学', 'B-', null],
            ['上海师范大学', 'C-', null],
            ['上海体育学院', 'A+', '体育学'],
            ['深圳大学', 'C', null],
            ['沈阳师范大学', 'C-', null],
            ['沈阳体育学院', 'B+', null],
            ['首都体育学院', 'B+', null],
            ['苏州大学', 'B+', null],
            ['天津体育学院', 'A-', null],
            ['同济大学', 'C-', null],
            ['武汉体育学院', 'A-', null],
            ['西安体育学院', 'B', null],
            ['西北师范大学', 'C+', null],
            ['西南大学', 'C', null],
            ['新疆师范大学', 'C+', null],
            ['扬州大学', 'C+', null],
            ['云南师范大学', 'C+', null],
            ['浙江大学', 'B+', null],
            ['浙江师范大学', 'C+', null],
            ['郑州大学', 'C+', null]
          ]
        ],
        [
          '天文学',
          [
            ['北京大学', 'B-', null],
            ['北京师范大学', 'C-', null],
            ['南京大学', 'A+', '天文学'],
            ['上海交通大学', 'C+', null],
            ['中国科学技术大学', 'A+', '天文学']
          ]
        ],
        [
          '统计学',
          [
            ['安徽财经大学', 'B-', null],
            ['安徽大学', 'B', null],
            ['安徽师范大学', 'C-', null],
            ['北方工业大学', 'C-', null],
            ['北京大学', 'A+', '统计学'],
            ['北京工业大学', 'B+', null],
            ['北京交通大学', 'B+', null],
            ['北京科技大学', 'C-', null],
            ['北京林业大学', 'C-', null],
            ['北京师范大学', 'A-', null],
            ['成都信息工程大学', 'C', null],
            ['东北财经大学', 'A-', null],
            ['东北师范大学', 'A', '统计学'],
            ['对外经济贸易大学', 'B+', null],
            ['福建农林大学', 'C-', null],
            ['福建师范大学', 'C+', null],
            ['复旦大学', 'B+', null],
            ['广东财经大学', 'C-', null],
            ['广西大学', 'C-', null],
            ['广西师范大学', 'C-', null],
            ['广州大学', 'B-', null],
            ['贵州财经大学', 'C-', null],
            ['桂林理工大学', 'C', null],
            ['哈尔滨工业大学', 'B', null],
            ['河北大学', 'C', null],
            ['河北经贸大学', 'C', null],
            ['河南大学', 'C+', null],
            ['河南师范大学', 'C', null],
            ['湖南大学', 'B', null],
            ['湖南师范大学', 'C+', null],
            ['华东师范大学', 'A', '统计学'],
            ['华中科技大学', 'B', null],
            ['华中师范大学', 'B', null],
            ['吉林财经大学', 'C+', null],
            ['吉林大学', 'B+', null],
            ['暨南大学', 'B-', null],
            ['江苏大学', 'C-', null],
            ['江苏师范大学', 'B-', null],
            ['江西财经大学', 'A-', null],
            ['兰州财经大学', 'C+', null],
            ['辽宁大学', 'B-', null],
            ['南京财经大学', 'C+', null],
            ['南京大学', 'B-', null],
            ['南京师范大学', 'B-', null],
            ['南开大学', 'A', '统计学'],
            ['内蒙古财经大学', 'C-', null],
            ['清华大学', 'B+', null],
            ['曲阜师范大学', 'B-', null],
            ['厦门大学', 'A', '统计学'],
            ['山东财经大学', 'C+', null],
            ['山东大学', 'B+', null],
            ['山西财经大学', 'B', null],
            ['上海财经大学', 'A-', '统计学'],
            ['上海大学', 'C', null],
            ['上海交通大学', 'B', null],
            ['首都经济贸易大学', 'B+', null],
            ['首都师范大学', 'B+', null],
            ['四川大学', 'B-', null],
            ['苏州大学', 'B-', null],
            ['武汉大学', 'B', null],
            ['西安财经学院', 'C+', null],
            ['西安交通大学', 'B', null],
            ['西北大学', 'C+', null],
            ['西南大学', 'B-', null],
            ['西南交通大学', 'C', null],
            ['湘潭大学', 'C+', null],
            ['新疆财经大学', 'C', null],
            ['云南财经大学', 'B', null],
            ['云南大学', 'B', null],
            ['长春工业大学', 'C', null],
            ['长沙理工大学', 'C-', null],
            ['浙江财经大学', 'C+', null],
            ['浙江工商大学', 'A-', null],
            ['郑州大学', 'C', null],
            ['中国地质大学', 'C-', null],
            ['中国科学技术大学', 'A-', null],
            ['中国矿业大学', 'C', null],
            ['中国人民大学', 'A+', '统计学'],
            ['中南财经政法大学', 'B+', null],
            ['中南大学', 'B+', null],
            ['中山大学', 'B', null],
            ['中央财经大学', 'B+', null],
            ['中央民族大学', 'C', null],
            ['重庆大学', 'B-', null],
            ['重庆工商大学', 'C+', null]
          ]
        ],
        [
          '图书情报与档案管理',
          [
            ['北京大学', 'B+', null],
            ['北京师范大学', 'C+', null],
            ['北京协和医学院', 'C', null],
            ['福建师范大学', 'C', null],
            ['河北大学', 'C', null],
            ['黑龙江大学', 'B', null],
            ['华东师范大学', 'B-', null],
            ['华中师范大学', 'B+', null],
            ['吉林大学', 'B', null],
            ['辽宁大学', 'C-', null],
            ['南京大学', 'A+', '图书情报与档案管理'],
            ['南京理工大学', 'C+', null],
            ['南京农业大学', 'B-', null],
            ['南京政治学院', 'B', null],
            ['南开大学', 'B+', null],
            ['山西大学', 'C-', null],
            ['上海大学', 'B', null],
            ['四川大学', 'C+', null],
            ['苏州大学', 'C', null],
            ['天津师范大学', 'C-', null],
            ['武汉大学', 'A+', '图书情报与档案管理'],
            ['湘潭大学', 'C+', null],
            ['云南大学', 'B', null],
            ['郑州大学', 'B-', null],
            ['中国农业大学', 'C-', null],
            ['中国人民大学', 'A-', '图书情报与档案管理'],
            ['中山大学', 'B+', null]
          ]
        ],
        [
          '土木工程',
          [
            ['安徽建筑大学', 'C+', null],
            ['安徽理工大学', 'B-', null],
            ['北方工业大学', 'C', null],
            ['北京工业大学', 'A', '土木工程（自定）'],
            ['北京航空航天大学', 'C+', null],
            ['北京建筑大学', 'B', null],
            ['北京交通大学', 'B+', null],
            ['北京科技大学', 'B', null],
            ['成都理工大学', 'B-', null],
            ['大连理工大学', 'A-', null],
            ['东北大学', 'B-', null],
            ['东北电力大学', 'C-', null],
            ['东华大学', 'C+', null],
            ['东南大学', 'A+', '土木工程'],
            ['福州大学', 'B', null],
            ['广东工业大学', 'C+', null],
            ['广西大学', 'B-', '土木工程（自定）'],
            ['广州大学', 'B+', null],
            ['贵州大学', 'C', null],
            ['桂林理工大学', 'C', null],
            ['哈尔滨工程大学', 'C', null],
            ['哈尔滨工业大学', 'A', '土木工程'],
            ['合肥工业大学', 'B', null],
            ['河北工业大学', 'C+', null],
            ['河海大学', 'A-', null],
            ['河南理工大学', 'C-', null],
            ['湖北工业大学', 'C+', null],
            ['湖南大学', 'A-', null],
            ['湖南科技大学', 'C+', null],
            ['华北水利水电大学', 'C', null],
            ['华东交通大学', 'C+', null],
            ['华南理工大学', 'B+', null],
            ['华侨大学', 'B-', null],
            ['华中科技大学', 'B+', null],
            ['吉林大学', 'C', null],
            ['吉林建筑大学', 'C', null],
            ['济南大学', 'C-', null],
            ['解放军理工大学', 'A-', null],
            ['昆明理工大学', 'C+', null],
            ['兰州交通大学', 'B', null],
            ['兰州理工大学', 'B', null],
            ['辽宁工程技术大学', 'C+', null],
            ['南华大学', 'C-', null],
            ['南京工业大学', 'B', null],
            ['南京航空航天大学', 'C', null],
            ['南京理工大学', 'C-', null],
            ['南京林业大学', 'C', null],
            ['内蒙古工业大学', 'C-', null],
            ['宁波大学', 'C', null],
            ['青岛理工大学', 'B', null],
            ['清华大学', 'A', '土木工程'],
            ['三峡大学', 'B-', null],
            ['厦门大学', 'C', null],
            ['山东大学', 'B+', null],
            ['山东建筑大学', 'B-', null],
            ['山东科技大学', 'B-', null],
            ['汕头大学', 'C', null],
            ['上海大学', 'B-', null],
            ['上海交通大学', 'B+', '土木工程'],
            ['上海理工大学', 'C', null],
            ['深圳大学', 'C+', null],
            ['沈阳建筑大学', 'B+', null],
            ['石家庄铁道大学', 'B+', null],
            ['四川大学', 'B', null],
            ['苏州科技大学', 'B-', null],
            ['太原理工大学', 'B-', null],
            ['天津城建大学', 'C+', null],
            ['天津大学', 'A-', null],
            ['同济大学', 'A+', '土木工程'],
            ['武汉大学', 'B+', null],
            ['武汉科技大学', 'C-', null],
            ['武汉理工大学', 'B', null],
            ['西安建筑科技大学', 'B+', null],
            ['西安交通大学', 'C+', null],
            ['西安科技大学', 'B-', null],
            ['西安理工大学', 'B-', null],
            ['西北工业大学', 'C-', null],
            ['西南交通大学', 'A-', null],
            ['烟台大学', 'C-', null],
            ['燕山大学', 'C-', null],
            ['扬州大学', 'C-', null],
            ['长安大学', 'B', null],
            ['长沙理工大学', 'B+', null],
            ['浙江大学', 'A', null],
            ['浙江工业大学', 'C+', null],
            ['郑州大学', 'B', null],
            ['中国地质大学', 'B', null],
            ['中国海洋大学', 'C-', null],
            ['中国矿业大学', 'B+', null],
            ['中国农业大学', 'C-', null],
            ['中南大学', 'A-', null],
            ['中南林业科技大学', 'C', null],
            ['重庆大学', 'B+', '土木工程（自定）'],
            ['重庆交通大学', 'B', null]
          ]
        ],
        [
          '外国语言文学',
          [
            ['安徽大学', 'C+', null],
            ['北京大学', 'A+', '外国语言文学'],
            ['北京第二外国语学院', 'B', null],
            ['北京航空航天大学', 'A-', null],
            ['北京交通大学', 'B-', null],
            ['北京科技大学', 'B', null],
            ['北京理工大学', 'C+', null],
            ['北京林业大学', 'C', null],
            ['北京师范大学', 'A-', null],
            ['北京外国语大学', 'A+', '外国语言文学'],
            ['北京语言大学', 'B+', null],
            ['大连海事大学', 'C', null],
            ['大连外国语大学', 'B', null],
            ['东北师范大学', 'B+', null],
            ['对外经济贸易大学', 'A-', null],
            ['福建师范大学', 'B', null],
            ['福州大学', 'C-', null],
            ['复旦大学', 'A-', null],
            ['广东外语外贸大学', 'A', null],
            ['广西大学', 'C+', null],
            ['广西民族大学', 'B-', null],
            ['广西师范大学', 'C+', null],
            ['国防科技大学', 'C', null],
            ['哈尔滨工业大学', 'B-', null],
            ['哈尔滨师范大学', 'B-', null],
            ['杭州师范大学', 'B', null],
            ['河北大学', 'C-', null],
            ['河北师范大学', 'C', null],
            ['河南大学', 'B', null],
            ['河南师范大学', 'C+', null],
            ['黑龙江大学', 'A', null],
            ['湖北大学', 'C-', null],
            ['湖南大学', 'B+', null],
            ['湖南科技大学', 'C', null],
            ['湖南师范大学', 'B+', '外国语言文学（自定）'],
            ['华北电力大学', 'C-', null],
            ['华东理工大学', 'C-', null],
            ['华东师范大学', 'A-', null],
            ['华南理工大学', 'B-', null],
            ['华南师范大学', 'B-', null],
            ['华中科技大学', 'B', null],
            ['华中师范大学', 'B', null],
            ['吉林大学', 'B', null],
            ['吉林师范大学', 'C-', null],
            ['暨南大学', 'B-', null],
            ['江苏大学', 'C', null],
            ['江苏师范大学', 'C-', null],
            ['江西师范大学', 'C+', null],
            ['兰州大学', 'C+', null],
            ['辽宁大学', 'C', null],
            ['辽宁师范大学', 'C+', null],
            ['鲁东大学', 'C', null],
            ['南昌大学', 'C-', null],
            ['南京大学', 'A', '外国语言文学'],
            ['南京航空航天大学', 'C+', null],
            ['南京理工大学', 'C-', null],
            ['南京师范大学', 'A-', null],
            ['南开大学', 'B+', null],
            ['内蒙古大学', 'C-', null],
            ['宁波大学', 'B', null],
            ['宁夏大学', 'C-', null],
            ['青岛大学', 'C', null],
            ['清华大学', 'A-', null],
            ['曲阜师范大学', 'B-', null],
            ['厦门大学', 'B+', null],
            ['山东大学', 'A-', null],
            ['山东师范大学', 'B-', null],
            ['山西大学', 'C+', null],
            ['陕西师范大学', 'B', null],
            ['上海财经大学', 'C+', null],
            ['上海大学', 'B-', null],
            ['上海对外经贸大学', 'B', null],
            ['上海海事大学', 'B-', null],
            ['上海交通大学', 'A', null],
            ['上海理工大学', 'C', null],
            ['上海师范大学', 'C+', null],
            ['上海外国语大学', 'A+', '外国语言文学'],
            ['深圳大学', 'C', null],
            ['首都师范大学', 'B', null],
            ['四川大学', 'B+', null],
            ['四川师范大学', 'C+', null],
            ['四川外国语大学', 'B+', null],
            ['苏州大学', 'B+', null],
            ['天津师范大学', 'C+', null],
            ['天津外国语大学', 'B', null],
            ['同济大学', 'B+', null],
            ['武汉大学', 'B+', null],
            ['西安交通大学', 'B-', null],
            ['西安外国语大学', 'B+', null],
            ['西北大学', 'C', null],
            ['西北师范大学', 'B-', null],
            ['西南大学', 'B+', null],
            ['西南交通大学', 'C+', null],
            ['西南民族大学', 'C-', null],
            ['西南政法大学', 'C-', null],
            ['湘潭大学', 'C+', null],
            ['延边大学', 'B+', '外国语言文学（自定）'],
            ['扬州大学', 'B-', null],
            ['云南大学', 'C-', null],
            ['云南师范大学', 'C-', null],
            ['浙江大学', 'A', null],
            ['浙江工商大学', 'B', null],
            ['浙江师范大学', 'B-', null],
            ['郑州大学', 'B-', null],
            ['中国传媒大学', 'C', null],
            ['中国地质大学', 'C', null],
            ['中国海洋大学', 'B', null],
            ['中国矿业大学', 'C+', null],
            ['中国人民大学', 'B+', null],
            ['中国石油大学', 'C+', null],
            ['中国政法大学', 'C+', null],
            ['中南财经政法大学', 'C-', null],
            ['中山大学', 'B+', null],
            ['重庆大学', 'B-', null],
            ['重庆师范大学', 'C', null]
          ]
        ],
        [
          '物理学',
          [
            ['安徽大学', 'C-', null],
            ['安徽师范大学', 'C', null],
            ['北京大学', 'A+', '物理学'],
            ['北京工业大学', 'B-', null],
            ['北京化工大学', 'C', null],
            ['北京交通大学', 'C+', null],
            ['北京科技大学', 'B-', null],
            ['北京师范大学', 'B+', null],
            ['大连理工大学', 'B', null],
            ['东北大学', 'B-', null],
            ['东北师范大学', 'B+', null],
            ['东华大学', 'C-', null],
            ['福建师范大学', 'B-', null],
            ['复旦大学', 'A', '物理学'],
            ['广西大学', 'C', null],
            ['广西师范大学', 'C', null],
            ['贵州大学', 'C-', null],
            ['国防科技大学', 'B+', null],
            ['哈尔滨工业大学', 'B+', null],
            ['哈尔滨师范大学', 'C-', null],
            ['杭州师范大学', 'C', null],
            ['河北师范大学', 'B-', null],
            ['河南大学', 'C+', null],
            ['河南师范大学', 'B', null],
            ['湖北大学', 'C-', null],
            ['湖南大学', 'B', null],
            ['湖南师范大学', 'B', null],
            ['华北电力大学', 'C-', null],
            ['华东理工大学', 'C', null],
            ['华东师范大学', 'B+', null],
            ['华南理工大学', 'C+', null],
            ['华南师范大学', 'B+', '物理学'],
            ['华中科技大学', 'A-', null],
            ['华中师范大学', 'B+', null],
            ['吉林大学', 'A-', '物理学'],
            ['吉林师范大学', 'C', null],
            ['济南大学', 'C-', null],
            ['江西师范大学', 'C', null],
            ['兰州大学', 'B+', null],
            ['辽宁大学', 'C', null],
            ['辽宁师范大学', 'C+', null],
            ['南昌大学', 'C+', null],
            ['南京大学', 'A', '物理学'],
            ['南京航空航天大学', 'C+', null],
            ['南京理工大学', 'C-', null],
            ['南京师范大学', 'B-', null],
            ['南开大学', 'A-', null],
            ['内蒙古大学', 'B-', null],
            ['宁波大学', 'C', null],
            ['青岛大学', 'C+', null],
            ['清华大学', 'A', '物理学'],
            ['曲阜师范大学', 'C+', null],
            ['厦门大学', 'B+', null],
            ['山东大学', 'B+', null],
            ['山东师范大学', 'B-', null],
            ['山西大学', 'B+', null],
            ['陕西师范大学', 'B', null],
            ['上海大学', 'B', null],
            ['上海交通大学', 'A', null],
            ['上海理工大学', 'C-', null],
            ['上海师范大学', 'C+', null],
            ['四川大学', 'B', null],
            ['四川师范大学', 'C', null],
            ['苏州大学', 'B', null],
            ['天津师范大学', 'C', null],
            ['同济大学', 'B+', null],
            ['武汉大学', 'A-', null],
            ['西安交通大学', 'B+', null],
            ['西北大学', 'B', null],
            ['西北工业大学', 'B', null],
            ['西北师范大学', 'B-', null],
            ['西南大学', 'C', null],
            ['西南交通大学', 'C+', null],
            ['湘潭大学', 'B-', null],
            ['云南大学', 'C+', null],
            ['长春理工大学', 'B-', null],
            ['浙江大学', 'A-', null],
            ['浙江工业大学', 'C+', null],
            ['浙江师范大学', 'C+', null],
            ['郑州大学', 'B-', null],
            ['中国地质大学', 'C-', null],
            ['中国海洋大学', 'C-', null],
            ['中国科学技术大学', 'A+', '物理学'],
            ['中国矿业大学', 'C-', null],
            ['中国人民大学', 'B', null],
            ['中国石油大学', 'C-', null],
            ['中南大学', 'B', null],
            ['中山大学', 'A-', null],
            ['重庆大学', 'B', null]
          ]
        ],
        [
          '戏剧与影视学',
          [
            ['北京大学', 'C+', null],
            ['北京电影学院', 'A-', null],
            ['北京师范大学', 'A+', '戏剧与影视学'],
            ['东北师范大学', 'B-', null],
            ['福建师范大学', 'B-', null],
            ['广州大学', 'C', null],
            ['哈尔滨师范大学', 'C+', null],
            ['河南大学', 'C', null],
            ['吉林大学', 'C-', null],
            ['吉林艺术学院', 'B-', null],
            ['辽宁大学', 'C', null],
            ['辽宁师范大学', 'C-', null],
            ['南京师范大学', 'B-', null],
            ['南京艺术学院', 'B+', null],
            ['厦门大学', 'B+', null],
            ['山东师范大学', 'C+', null],
            ['山西师范大学', 'B', null],
            ['上海大学', 'B+', null],
            ['上海师范大学', 'B-', null],
            ['上海戏剧学院', 'A-', null],
            ['上海音乐学院', 'B', null],
            ['深圳大学', 'C-', null],
            ['四川美术学院', 'C', null],
            ['四川师范大学', 'C+', null],
            ['苏州大学', 'C-', null],
            ['西北师范大学', 'C+', null],
            ['西南大学', 'B', null],
            ['扬州大学', 'C-', null],
            ['云南师范大学', 'C', null],
            ['云南艺术学院', 'B', null],
            ['中国传媒大学', 'A+', '戏剧与影视学'],
            ['中国美术学院', 'B+', null],
            ['中国戏曲学院', 'B+', null],
            ['中央戏剧学院', 'A-', '戏剧与影视学'],
            ['重庆大学', 'B', null]
          ]
        ],
        [
          '系统科学',
          [
            ['北京交通大学', 'A+', '系统科学'],
            ['北京师范大学', 'B-', '系统科学'],
            ['国防科技大学', 'A+', null],
            ['青岛大学', 'C+', null],
            ['上海理工大学', 'B', null],
            ['四川大学', 'C', null],
            ['云南大学', 'C+', null],
            ['重庆师范大学', 'C-', null]
          ]
        ],
        [
          '心理学',
          [
            ['安徽医科大学', 'C-', null],
            ['北京大学', 'A+', '心理学'],
            ['北京师范大学', 'A+', '心理学'],
            ['北京体育大学', 'C-', null],
            ['第四军医大学', 'B', null],
            ['东北师范大学', 'B-', null],
            ['福建师范大学', 'C+', null],
            ['广州大学', 'C', null],
            ['贵州师范大学', 'C', null],
            ['杭州师范大学', 'C', null],
            ['河北师范大学', 'C-', null],
            ['河南大学', 'C', null],
            ['湖南师范大学', 'B-', null],
            ['华东师范大学', 'A-', null],
            ['华南师范大学', 'A+', null],
            ['华中师范大学', 'B+', null],
            ['吉林大学', 'C-', null],
            ['江西师范大学', 'B-', null],
            ['辽宁师范大学', 'B', null],
            ['南京师范大学', 'B+', null],
            ['内蒙古师范大学', 'C', null],
            ['清华大学', 'C-', null],
            ['曲阜师范大学', 'C-', null],
            ['山东师范大学', 'B', null],
            ['陕西师范大学', 'B+', null],
            ['上海师范大学', 'B', null],
            ['深圳大学', 'C+', null],
            ['首都师范大学', 'B', null],
            ['苏州大学', 'C+', null],
            ['天津师范大学', 'B+', null],
            ['西北师范大学', 'C+', null],
            ['西南大学', 'A-', null],
            ['浙江大学', 'B+', null],
            ['浙江师范大学', 'B-', null],
            ['中南大学', 'B-', null],
            ['中山大学', 'B-', null]
          ]
        ],
        [
          '新闻传播学',
          [
            ['安徽大学', 'B', null],
            ['安徽师范大学', 'C-', null],
            ['北京大学', 'B+', null],
            ['北京工商大学', 'C-', null],
            ['北京师范大学', 'C+', null],
            ['北京外国语大学', 'C+', null],
            ['北京印刷学院', 'C+', null],
            ['复旦大学', 'A', null],
            ['广西大学', 'C', null],
            ['河北大学', 'B', null],
            ['河南大学', 'B-', null],
            ['湖南大学', 'B', null],
            ['湖南师范大学', 'B', null],
            ['华东师范大学', 'B+', null],
            ['华南理工大学', 'B-', null],
            ['华中科技大学', 'A', null],
            ['华中师范大学', 'C-', null],
            ['吉林大学', 'C', null],
            ['暨南大学', 'A-', null],
            ['兰州大学', 'B-', null],
            ['辽宁大学', 'C+', null],
            ['南昌大学', 'B-', null],
            ['南京大学', 'B+', null],
            ['南京师范大学', 'B+', null],
            ['南京政治学院', 'B-', null],
            ['南开大学', 'C-', null],
            ['清华大学', 'A-', null],
            ['厦门大学', 'B+', null],
            ['山东大学', 'B-', null],
            ['陕西师范大学', 'B', null],
            ['汕头大学', 'C', null],
            ['上海大学', 'B+', null],
            ['上海交通大学', 'A-', null],
            ['上海理工大学', 'C', null],
            ['上海师范大学', 'C-', null],
            ['上海外国语大学', 'B-', null],
            ['深圳大学', 'B', null],
            ['四川大学', 'B+', null],
            ['苏州大学', 'B-', null],
            ['天津师范大学', 'C+', null],
            ['同济大学', 'C', null],
            ['武汉大学', 'A-', null],
            ['西安交通大学', 'C', null],
            ['西北大学', 'C+', null],
            ['西南大学', 'C-', null],
            ['西南政法大学', 'C+', null],
            ['新疆大学', 'C-', null],
            ['云南大学', 'C-', null],
            ['浙江大学', 'B+', null],
            ['郑州大学', 'B', null],
            ['中国传媒大学', 'A+', '新闻传播学'],
            ['中国人民大学', 'A+', '新闻传播学'],
            ['中国政法大学', 'C', null],
            ['中山大学', 'B', null],
            ['中央民族大学', 'C', null],
            ['重庆大学', 'C+', null]
          ]
        ],
        [
          '信息与通信工程',
          [
            ['北京大学', 'B+', null],
            ['北京工业大学', 'C+', null],
            ['北京航空航天大学', 'A-', null],
            ['北京交通大学', 'A-', null],
            ['北京科技大学', 'C+', null],
            ['北京理工大学', 'A-', null],
            ['北京邮电大学', 'A+', '信息与通信工程'],
            ['成都信息工程大学', 'C+', null],
            ['大连海事大学', 'B-', null],
            ['大连理工大学', 'B+', null],
            ['电子科技大学', 'A+', '信息与通信工程'],
            ['东北大学', 'B', null],
            ['东华大学', 'C-', null],
            ['东南大学', 'A-', '信息与通信工程'],
            ['福州大学', 'C+', null],
            ['复旦大学', 'C', null],
            ['广东工业大学', 'C-', null],
            ['桂林电子科技大学', 'B-', null],
            ['国防科技大学', 'A', '信息与通信工程'],
            ['哈尔滨工程大学', 'B+', null],
            ['哈尔滨工业大学', 'A-', null],
            ['海军工程大学', 'B-', null],
            ['海军航空工程学院', 'B+', null],
            ['海南大学', 'C+', null],
            ['杭州电子科技大学', 'C', null],
            ['合肥工业大学', 'B-', null],
            ['河海大学', 'B-', null],
            ['黑龙江大学', 'C', null],
            ['湖南大学', 'C+', null],
            ['华北电力大学', 'C+', null],
            ['华东交通大学', 'C', null],
            ['华东师范大学', 'C+', null],
            ['华南理工大学', 'B+', null],
            ['华中科技大学', 'B+', null],
            ['火箭军工程大学', 'C-', null],
            ['吉林大学', 'B-', null],
            ['暨南大学', 'C', null],
            ['解放军理工大学', 'A-', null],
            ['解放军信息工程大学', 'A-', null],
            ['空军工程大学', 'B+', null],
            ['兰州大学', 'C-', null],
            ['兰州交通大学', 'C-', null],
            ['南昌大学', 'C', null],
            ['南京大学', 'B', null],
            ['南京航空航天大学', 'B', null],
            ['南京理工大学', 'B', null],
            ['南京信息工程大学', 'C+', null],
            ['南京邮电大学', 'B+', null],
            ['南开大学', 'C', null],
            ['南通大学', 'C+', null],
            ['宁波大学', 'B-', null],
            ['清华大学', 'A', '信息与通信工程'],
            ['厦门大学', 'B', null],
            ['山东大学', 'B', null],
            ['山东科技大学', 'C-', null],
            ['上海大学', 'B', null],
            ['上海海事大学', 'C', null],
            ['上海交通大学', 'A', '信息与通信工程'],
            ['深圳大学', 'B', null],
            ['沈阳航空航天大学', 'C-', null],
            ['四川大学', 'B', null],
            ['苏州大学', 'B-', null],
            ['天津大学', 'B+', null],
            ['天津工业大学', 'C', null],
            ['天津理工大学', 'C-', null],
            ['同济大学', 'C+', null],
            ['武汉大学', 'B', null],
            ['武汉理工大学', 'B', null],
            ['西安电子科技大学', 'A', '信息与通信工程'],
            ['西安交通大学', 'B+', '信息与通信工程'],
            ['西安科技大学', 'C-', null],
            ['西安理工大学', 'C-', null],
            ['西安邮电大学', 'B-', null],
            ['西北大学', 'C-', null],
            ['西北工业大学', 'B', null],
            ['西南交通大学', 'B+', null],
            ['燕山大学', 'C-', null],
            ['云南大学', 'C+', null],
            ['长春理工大学', 'C+', null],
            ['浙江大学', 'B+', null],
            ['浙江工商大学', 'C', null],
            ['浙江工业大学', 'C', null],
            ['郑州大学', 'C+', null],
            ['中北大学', 'B', null],
            ['中国传媒大学', 'B', null],
            ['中国地质大学', 'C-', null],
            ['中国海洋大学', 'C', null],
            ['中国科学技术大学', 'B+', null],
            ['中国矿业大学', 'B-', null],
            ['中国民航大学', 'C', null],
            ['中南大学', 'C', null],
            ['中山大学', 'B-', null],
            ['重庆大学', 'B-', null],
            ['重庆邮电大学', 'B+', null],
            ['装备学院', 'B-', null]
          ]
        ],
        [
          '药学',
          [
            ['安徽医科大学', 'B', null],
            ['安徽中医药大学', 'C-', null],
            ['北京大学', 'A', '药学'],
            ['北京化工大学', 'C-', null],
            ['北京协和医学院', 'A+', '药学'],
            ['成都学院', 'C-', null],
            ['成都中医药大学', 'C', null],
            ['大连医科大学', 'C+', null],
            ['第二军医大学', 'A-', null],
            ['第四军医大学', 'B+', null],
            ['复旦大学', 'A-', '药学'],
            ['广东药科大学', 'B', null],
            ['广西医科大学', 'C+', null],
            ['广州医科大学', 'C', null],
            ['广州中医药大学', 'C-', null],
            ['贵州医科大学', 'C+', null],
            ['哈尔滨医科大学', 'B+', null],
            ['河北医科大学', 'B-', null],
            ['黑龙江中医药大学', 'B-', null],
            ['湖北中医药大学', 'C-', null],
            ['湖南中医药大学', 'C+', null],
            ['华东理工大学', 'B+', null],
            ['华中科技大学', 'B+', null],
            ['吉林大学', 'B', null],
            ['济南大学', 'C-', null],
            ['暨南大学', 'B-', '药学（自定）'],
            ['江南大学', 'C+', null],
            ['江苏大学', 'C+', null],
            ['昆明医科大学', 'C', null],
            ['兰州大学', 'C+', null],
            ['辽宁中医药大学', 'B-', null],
            ['南昌大学', 'C', null],
            ['南方医科大学', 'B', null],
            ['南京工业大学', 'C+', null],
            ['南京医科大学', 'B', null],
            ['南京中医药大学', 'B-', null],
            ['南开大学', 'B', null],
            ['青岛大学', 'C-', null],
            ['厦门大学', 'B-', null],
            ['山东大学', 'A-', null],
            ['山东中医药大学', 'C', null],
            ['山西医科大学', 'C', null],
            ['汕头大学', 'C', null],
            ['上海交通大学', 'A-', '药学'],
            ['沈阳药科大学', 'A', null],
            ['首都医科大学', 'B+', null],
            ['四川大学', 'A-', null],
            ['苏州大学', 'B+', null],
            ['天津科技大学', 'C', null],
            ['天津医科大学', 'B', null],
            ['天津中医药大学', 'C+', null],
            ['同济大学', 'C-', null],
            ['温州医科大学', 'B-', null],
            ['武汉大学', 'B+', null],
            ['西安交通大学', 'B', null],
            ['西南交通大学', 'C-', null],
            ['新疆医科大学', 'B-', null],
            ['新乡医学院', 'C-', null],
            ['徐州医科大学', 'C+', null],
            ['烟台大学', 'C', null],
            ['延边大学', 'B-', null],
            ['浙江大学', 'A', '药学'],
            ['浙江工业大学', 'B', null],
            ['浙江中医药大学', 'C+', null],
            ['郑州大学', 'B', null],
            ['中国海洋大学', 'B+', null],
            ['中国药科大学', 'A+', null],
            ['中国医科大学', 'B', null],
            ['中南大学', 'B+', null],
            ['中山大学', 'A-', '药学'],
            ['重庆医科大学', 'B-', null],
            ['遵义医学院', 'C', null]
          ]
        ],
        [
          '冶金工程',
          [
            ['安徽工业大学', 'C', null],
            ['北京科技大学', 'A+', '冶金工程'],
            ['东北大学', 'B+', null],
            ['贵州大学', 'C-', null],
            ['华北理工大学', 'C+', null],
            ['江西理工大学', 'C+', null],
            ['昆明理工大学', 'B+', null],
            ['辽宁科技大学', 'C-', null],
            ['内蒙古科技大学', 'B-', null],
            ['上海大学', 'B', null],
            ['武汉科技大学', 'B', null],
            ['西安建筑科技大学', 'C', null],
            ['中南大学', 'A+', '冶金工程'],
            ['重庆大学', 'B-', null]
          ]
        ],
        [
          '仪器科学与技术',
          [
            ['北京工业大学', 'C+', null],
            ['北京航空航天大学', 'A+', '仪器科学与技术'],
            ['北京科技大学', 'C+', null],
            ['北京理工大学', 'B', null],
            ['北京信息科技大学', 'C+', null],
            ['大连理工大学', 'B', null],
            ['电子科技大学', 'B+', null],
            ['东北石油大学', 'C-', null],
            ['东南大学', 'A-', null],
            ['广东工业大学', 'C-', null],
            ['桂林电子科技大学', 'B-', null],
            ['国防科技大学', 'B+', null],
            ['哈尔滨工程大学', 'B-', null],
            ['哈尔滨工业大学', 'A-', null],
            ['哈尔滨理工大学', 'B-', null],
            ['海军航空工程学院', 'C', null],
            ['合肥工业大学', 'B+', null],
            ['河北大学', 'C-', null],
            ['河北工业大学', 'C-', null],
            ['湖北工业大学', 'C-', null],
            ['吉林大学', 'B+', null],
            ['江苏大学', 'C+', null],
            ['解放军信息工程大学', 'C', null],
            ['南昌航空大学', 'C+', null],
            ['南京航空航天大学', 'B-', null],
            ['南京理工大学', 'B-', null],
            ['南京邮电大学', 'C-', null],
            ['清华大学', 'A+', '仪器科学与技术'],
            ['厦门大学', 'B', null],
            ['上海大学', 'C', null],
            ['上海交通大学', 'A-', null],
            ['上海理工大学', 'C+', null],
            ['沈阳工业大学', 'B', null],
            ['四川大学', 'C+', null],
            ['天津大学', 'A', null],
            ['天津科技大学', 'C-', null],
            ['武汉大学', 'C+', null],
            ['武汉理工大学', 'C', null],
            ['西安电子科技大学', 'B', null],
            ['西安交通大学', 'B+', null],
            ['西安理工大学', 'B-', null],
            ['西北工业大学', 'B-', null],
            ['西南石油大学', 'C-', null],
            ['燕山大学', 'B-', null],
            ['长春理工大学', 'B', null],
            ['中北大学', 'B+', null],
            ['中国地质大学', 'C', null],
            ['中国计量大学', 'B', null],
            ['中国科学技术大学', 'B+', null],
            ['重庆大学', 'A-', null],
            ['重庆理工大学', 'C', null]
          ]
        ],
        [
          '艺术学理论',
          [
            ['北京大学', 'A+', '艺术学理论'],
            ['北京电影学院', 'B-', null],
            ['北京服装学院', 'C+', null],
            ['北京师范大学', 'B+', null],
            ['东华大学', 'C', null],
            ['东南大学', 'A+', '艺术学理论'],
            ['福建师范大学', 'C', null],
            ['广西艺术学院', 'C+', null],
            ['广州美术学院', 'B', null],
            ['哈尔滨音乐学院', 'B-', null],
            ['杭州师范大学', 'B', null],
            ['河北师范大学', 'C-', null],
            ['河南大学', 'C-', null],
            ['湖北美术学院', 'C+', null],
            ['鲁迅美术学院', 'C', null],
            ['南京艺术学院', 'B+', null],
            ['清华大学', 'A-', null],
            ['厦门大学', 'C+', null],
            ['上海戏剧学院', 'B', null],
            ['上海音乐学院', 'B+', null],
            ['深圳大学', 'C+', null],
            ['沈阳音乐学院', 'C', null],
            ['四川大学', 'B', null],
            ['四川美术学院', 'C+', null],
            ['苏州大学', 'C-', null],
            ['武汉理工大学', 'B-', null],
            ['西安美术学院', 'B', null],
            ['云南大学', 'C-', null],
            ['云南艺术学院', 'C-', null],
            ['浙江理工大学', 'B-', null],
            ['中国传媒大学', 'A-', null],
            ['中国美术学院', 'A-', null],
            ['中国人民大学', 'B-', null],
            ['中央美术学院', 'B+', null],
            ['中央戏剧学院', 'B+', null]
          ]
        ],
        [
          '音乐与舞蹈学',
          [
            ['安徽师范大学', 'C+', null],
            ['北京师范大学', 'C+', null],
            ['北京舞蹈学院', 'A-', null],
            ['东北师范大学', 'B+', null],
            ['福建师范大学', 'A-', null],
            ['广西师范大学', 'C-', null],
            ['广西艺术学院', 'B', null],
            ['广州大学', 'C', null],
            ['哈尔滨音乐学院', 'B', null],
            ['河北师范大学', 'C', null],
            ['河南大学', 'B-', null],
            ['河南师范大学', 'C', null],
            ['湖南师范大学', 'B', null],
            ['华南师范大学', 'B-', null],
            ['华中师范大学', 'C', null],
            ['吉林艺术学院', 'B-', null],
            ['江西师范大学', 'C+', null],
            ['辽宁师范大学', 'C-', null],
            ['南京师范大学', 'B', null],
            ['南京艺术学院', 'A-', null],
            ['青岛大学', 'C-', null],
            ['曲阜师范大学', 'C', null],
            ['厦门大学', 'B-', null],
            ['山东师范大学', 'B', null],
            ['山西大学', 'C', null],
            ['上海师范大学', 'B-', null],
            ['上海戏剧学院', 'C+', null],
            ['上海音乐学院', 'A+', '音乐与舞蹈学'],
            ['沈阳师范大学', 'C', null],
            ['沈阳音乐学院', 'B+', null],
            ['首都师范大学', 'B+', null],
            ['四川音乐学院', 'B+', null],
            ['天津音乐学院', 'B+', null],
            ['西安音乐学院', 'B+', null],
            ['西北民族大学', 'C', null],
            ['西北师范大学', 'C+', null],
            ['西南大学', 'C+', null],
            ['西南民族大学', 'C-', null],
            ['星海音乐学院', 'B+', null],
            ['延边大学', 'C-', null],
            ['云南艺术学院', 'B-', null],
            ['浙江师范大学', 'B-', null],
            ['郑州大学', 'C-', null],
            ['中国传媒大学', 'B', null],
            ['中国人民大学', 'C', null],
            ['中国戏曲学院', 'C+', null],
            ['中国音乐学院', 'A', '音乐与舞蹈学（自定）'],
            ['中央民族大学', 'B', null],
            ['中央音乐学院', 'A+', '音乐与舞蹈学']
          ]
        ],
        [
          '应用经济学',
          [
            ['安徽财经大学', 'B-', null],
            ['安徽大学', 'B', null],
            ['安徽工业大学', 'C-', null],
            ['北京大学', 'A+', '应用经济学'],
            ['北京工商大学', 'B', null],
            ['北京交通大学', 'B+', null],
            ['北京科技大学', 'C', null],
            ['北京理工大学', 'B', null],
            ['北京师范大学', 'C+', null],
            ['大连理工大学', 'B', null],
            ['东北财经大学', 'A', null],
            ['东北师范大学', 'B-', null],
            ['东南大学', 'B+', null],
            ['对外经济贸易大学', 'A', '应用经济学（自定）'],
            ['复旦大学', 'A-', null],
            ['广东财经大学', 'C+', null],
            ['广东外语外贸大学', 'B-', null],
            ['广西大学', 'B', null],
            ['广西师范大学', 'C-', null],
            ['贵州财经大学', 'C', null],
            ['贵州大学', 'C-', null],
            ['哈尔滨商业大学', 'B-', null],
            ['海南大学', 'C', null],
            ['河北大学', 'C', null],
            ['河北经贸大学', 'C+', null],
            ['河南财经政法大学', 'C+', null],
            ['河南大学', 'B-', null],
            ['湖南大学', 'B+', null],
            ['湖南科技大学', 'B-', null],
            ['华东理工大学', 'B-', null],
            ['华东师范大学', 'B', null],
            ['华东政法大学', 'C', null],
            ['华南师范大学', 'C+', null],
            ['华侨大学', 'B-', null],
            ['华中科技大学', 'B+', null],
            ['华中师范大学', 'C+', null],
            ['吉林财经大学', 'C+', null],
            ['吉林大学', 'B+', null],
            ['济南大学', 'C+', null],
            ['暨南大学', 'B+', null],
            ['江南大学', 'C', null],
            ['江苏大学', 'C', null],
            ['江苏师范大学', 'C', null],
            ['江西财经大学', 'A-', null],
            ['军事经济学院', 'B-', null],
            ['兰州财经大学', 'C', null],
            ['兰州大学', 'B-', null],
            ['辽宁大学', 'B+', '应用经济学（自定）'],
            ['南昌大学', 'C+', null],
            ['南京财经大学', 'B', null],
            ['南京大学', 'B+', null],
            ['南京审计大学', 'C', null],
            ['南京师范大学', 'B-', null],
            ['南开大学', 'A-', null],
            ['内蒙古财经大学', 'C-', null],
            ['内蒙古大学', 'C', null],
            ['宁波大学', 'C+', null],
            ['清华大学', 'A-', null],
            ['厦门大学', 'A', null],
            ['山东财经大学', 'B+', null],
            ['山东大学', 'A-', null],
            ['山东理工大学', 'C', null],
            ['山西财经大学', 'B', null],
            ['陕西师范大学', 'C-', null],
            ['上海财经大学', 'A', null],
            ['上海大学', 'B', null],
            ['上海对外经贸大学', 'B', null],
            ['上海师范大学', 'C', null],
            ['石河子大学', 'C-', null],
            ['首都经济贸易大学', 'B+', null],
            ['四川大学', 'B-', null],
            ['苏州大学', 'B-', null],
            ['天津财经大学', 'B+', null],
            ['天津工业大学', 'C-', null],
            ['天津商业大学', 'C-', null],
            ['同济大学', 'B', null],
            ['武汉大学', 'B+', null],
            ['武汉理工大学', 'B', null],
            ['西安财经学院', 'C+', null],
            ['西安交通大学', 'A-', null],
            ['西安邮电大学', 'C-', null],
            ['西北大学', 'C+', null],
            ['西南财经大学', 'A-', '应用经济学（自定）'],
            ['西南民族大学', 'C', null],
            ['西南政法大学', 'C', null],
            ['新疆财经大学', 'C+', null],
            ['云南财经大学', 'B', null],
            ['云南大学', 'C', null],
            ['云南师范大学', 'C-', null],
            ['长沙理工大学', 'C+', null],
            ['浙江财经大学', 'B+', null],
            ['浙江大学', 'B+', null],
            ['浙江工商大学', 'B+', null],
            ['浙江工业大学', 'B-', null],
            ['浙江理工大学', 'C', null],
            ['郑州大学', 'C+', null],
            ['中国地质大学', 'B-', null],
            ['中国海洋大学', 'B', null],
            ['中国矿业大学', 'C', null],
            ['中国人民大学', 'A+', '应用经济学'],
            ['中国石油大学', 'C', null],
            ['中国政法大学', 'C-', null],
            ['中南财经政法大学', 'A-', null],
            ['中山大学', 'B+', null],
            ['中央财经大学', 'A+', '应用经济学'],
            ['中央民族大学', 'C-', null],
            ['重庆大学', 'B', null],
            ['重庆工商大学', 'B-', null]
          ]
        ],
        [
          '园艺学',
          [
            ['安徽农业大学', 'B-', null],
            ['北京农学院', 'C+', null],
            ['东北农业大学', 'C+', null],
            ['福建农林大学', 'B-', null],
            ['甘肃农业大学', 'C+', null],
            ['海南大学', 'C', null],
            ['河北农业大学', 'B-', null],
            ['河南农业大学', 'C-', null],
            ['湖南农业大学', 'B+', null],
            ['华南农业大学', 'B', null],
            ['华中农业大学', 'A+', '园艺学'],
            ['南京农业大学', 'A-', null],
            ['山东农业大学', 'B+', null],
            ['山西农业大学', 'C', null],
            ['上海交通大学', 'B', null],
            ['沈阳农业大学', 'B+', null],
            ['石河子大学', 'C', null],
            ['四川农业大学', 'C+', null],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'B-', null],
            ['新疆农业大学', 'C-', null],
            ['扬州大学', 'C-', null],
            ['云南农业大学', 'C-', null],
            ['浙江大学', 'A+', '园艺学'],
            ['中国农业大学', 'B+', null]
          ]
        ],
        [
          '哲学',
          [
            ['安徽大学', 'C+', null],
            ['安徽师范大学', 'C-', null],
            ['北京大学', 'A+', '哲学'],
            ['北京师范大学', 'A-', null],
            ['大连理工大学', 'B-', null],
            ['东北大学', 'B', null],
            ['东南大学', 'B+', null],
            ['复旦大学', 'A+', '哲学'],
            ['广西大学', 'C-', null],
            ['河北大学', 'C+', null],
            ['河北师范大学', 'C-', null],
            ['河南大学', 'C+', null],
            ['河南师范大学', 'C-', null],
            ['黑龙江大学', 'B+', null],
            ['湖北大学', 'C+', null],
            ['湖南师范大学', 'B', null],
            ['华东师范大学', 'B+', null],
            ['华南师范大学', 'B-', null],
            ['华侨大学', 'C+', null],
            ['华中科技大学', 'B+', null],
            ['吉林大学', 'A-', null],
            ['吉林师范大学', 'C', null],
            ['吉首大学', 'C', null],
            ['昆明理工大学', 'C', null],
            ['兰州大学', 'C+', null],
            ['辽宁大学', 'B-', null],
            ['南昌大学', 'C+', null],
            ['南京大学', 'A', '哲学'],
            ['南京师范大学', 'B', null],
            ['南开大学', 'B+', null],
            ['清华大学', 'B+', null],
            ['厦门大学', 'B', null],
            ['山东大学', 'B+', null],
            ['山西大学', 'B', null],
            ['陕西师范大学', 'B', null],
            ['上海大学', 'C', null],
            ['上海师范大学', 'B-', null],
            ['深圳大学', 'C', null],
            ['沈阳师范大学', 'C-', null],
            ['四川大学', 'B', null],
            ['四川师范大学', 'C-', null],
            ['苏州大学', 'B-', null],
            ['同济大学', 'B', null],
            ['武汉大学', 'A-', null],
            ['西安交通大学', 'B-', null],
            ['西北政法大学', 'C', null],
            ['西南大学', 'B-', null],
            ['西南民族大学', 'C', null],
            ['西南政法大学', 'C-', null],
            ['云南大学', 'C', null],
            ['云南师范大学', 'C-', null],
            ['浙江大学', 'B+', null],
            ['中国人民大学', 'A', '哲学'],
            ['中国政法大学', 'C+', null],
            ['中南财经政法大学', 'C+', null],
            ['中南大学', 'B-', null],
            ['中山大学', 'A-', '哲学'],
            ['中央民族大学', 'B', null]
          ]
        ],
        [
          '政治学',
          [
            ['北京大学', 'A+', '政治学'],
            ['北京师范大学', 'B-', null],
            ['北京外国语大学', 'B-', null],
            ['东北师范大学', 'B', null],
            ['对外经济贸易大学', 'B', null],
            ['复旦大学', 'A+', '政治学'],
            ['广东外语外贸大学', 'C', null],
            ['广西民族大学', 'C-', null],
            ['贵州师范大学', 'C-', null],
            ['河南师范大学', 'C', null],
            ['黑龙江大学', 'C', null],
            ['湖南师范大学', 'B-', null],
            ['华东师范大学', 'B+', null],
            ['华东政法大学', 'B', null],
            ['华南师范大学', 'B-', null],
            ['华侨大学', 'C+', null],
            ['华中师范大学', 'A-', '政治学'],
            ['吉林大学', 'A-', null],
            ['暨南大学', 'B', null],
            ['辽宁大学', 'C', null],
            ['辽宁师范大学', 'C-', null],
            ['聊城大学', 'C-', null],
            ['南京大学', 'B+', null],
            ['南京师范大学', 'B-', null],
            ['南开大学', 'A-', null],
            ['青岛大学', 'C-', null],
            ['清华大学', 'A-', '政治学'],
            ['厦门大学', 'B+', null],
            ['山东大学', 'B+', null],
            ['山西大学', 'B-', null],
            ['陕西师范大学', 'C-', null],
            ['上海交通大学', 'C+', null],
            ['上海师范大学', 'C+', null],
            ['上海外国语大学', 'B+', null],
            ['深圳大学', 'C+', null],
            ['首都师范大学', 'C+', null],
            ['四川大学', 'B-', null],
            ['苏州大学', 'B', null],
            ['天津师范大学', 'B+', null],
            ['同济大学', 'B-', null],
            ['武汉大学', 'B', null],
            ['西华师范大学', 'B-', null],
            ['西南政法大学', 'C+', null],
            ['湘潭大学', 'C+', null],
            ['新疆大学', 'C-', null],
            ['延安大学', 'C-', null],
            ['云南大学', 'B+', null],
            ['浙江师范大学', 'C', null],
            ['郑州大学', 'C', null],
            ['中国海洋大学', 'C', null],
            ['中国人民大学', 'A', '政治学'],
            ['中国政法大学', 'B+', null],
            ['中山大学', 'B', null]
          ]
        ],
        [
          '植物保护',
          [
            ['安徽农业大学', 'C+', null],
            ['东北农业大学', 'C', null],
            ['福建农林大学', 'B+', null],
            ['广西大学', 'C', null],
            ['贵州大学', 'B+', '植物保护（自定）'],
            ['河北农业大学', 'C+', null],
            ['河南农业大学', 'C', null],
            ['湖南农业大学', 'B-', null],
            ['华南农业大学', 'B', null],
            ['华中农业大学', 'B+', null],
            ['吉林大学', 'C-', null],
            ['吉林农业大学', 'B-', null],
            ['南京农业大学', 'A+', null],
            ['青岛农业大学', 'C-', null],
            ['山东农业大学', 'B', null],
            ['山西农业大学', 'C-', null],
            ['沈阳农业大学', 'B-', null],
            ['四川农业大学', 'C', null],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'B', null],
            ['扬州大学', 'C+', null],
            ['云南农业大学', 'B', null],
            ['浙江大学', 'A+', '植物保护'],
            ['中国农业大学', 'A-', '植物保护']
          ]
        ],
        [
          '中国史',
          [
            ['安徽大学', 'C', null],
            ['安徽师范大学', 'C', null],
            ['北京大学', 'A', '中国史'],
            ['北京师范大学', 'A+', '中国史'],
            ['东北师范大学', 'B', null],
            ['福建师范大学', 'C+', null],
            ['复旦大学', 'A+', '中国史'],
            ['杭州师范大学', 'C-', null],
            ['河北大学', 'B-', null],
            ['河北师范大学', 'C+', null],
            ['河南大学', 'B-', null],
            ['湖北大学', 'C', null],
            ['湖南大学', 'B-', null],
            ['湖南师范大学', 'B-', null],
            ['华东师范大学', 'B+', null],
            ['华南师范大学', 'C', null],
            ['华中师范大学', 'A-', null],
            ['吉林大学', 'B', null],
            ['吉林师范大学', 'C-', null],
            ['暨南大学', 'B', null],
            ['江西师范大学', 'C', null],
            ['兰州大学', 'B', null],
            ['辽宁大学', 'C-', null],
            ['辽宁师范大学', 'C', null],
            ['南昌大学', 'C-', null],
            ['南京大学', 'A', null],
            ['南京师范大学', 'B-', null],
            ['南开大学', 'A-', null],
            ['内蒙古大学', 'C+', null],
            ['清华大学', 'B+', null],
            ['曲阜师范大学', 'C+', null],
            ['厦门大学', 'B+', null],
            ['山东大学', 'B+', null],
            ['山东师范大学', 'C-', null],
            ['山西大学', 'C+', null],
            ['陕西师范大学', 'B+', null],
            ['上海大学', 'C+', null],
            ['上海交通大学', 'C', null],
            ['上海师范大学', 'B', null],
            ['首都师范大学', 'B+', null],
            ['四川大学', 'B+', null],
            ['四川师范大学', 'C-', null],
            ['苏州大学', 'C+', null],
            ['天津师范大学', 'C+', null],
            ['武汉大学', 'B+', null],
            ['西北大学', 'B', null],
            ['西北师范大学', 'C+', null],
            ['西南大学', 'B', null],
            ['扬州大学', 'C', null],
            ['云南大学', 'B', null],
            ['云南师范大学', 'C-', null],
            ['浙江大学', 'B-', null],
            ['浙江师范大学', 'C-', null],
            ['郑州大学', 'B-', null],
            ['中国人民大学', 'A-', '中国史'],
            ['中山大学', 'A-', null],
            ['中央民族大学', 'B-', null]
          ]
        ],
        [
          '中国语言文学',
          [
            ['安徽大学', 'B-', null],
            ['安徽师范大学', 'B-', null],
            ['北京大学', 'A+', '中国语言文学'],
            ['北京第二外国语学院', 'C', null],
            ['北京师范大学', 'A+', '中国语言文学'],
            ['北京外国语大学', 'C+', null],
            ['北京语言大学', 'B+', null],
            ['渤海大学', 'C-', null],
            ['东北师范大学', 'B+', null],
            ['福建师范大学', 'B+', null],
            ['复旦大学', 'A', '中国语言文学'],
            ['广东外语外贸大学', 'C-', null],
            ['广西大学', 'C', null],
            ['广西民族大学', 'B-', null],
            ['广西师范大学', 'B', null],
            ['广州大学', 'C-', null],
            ['贵州民族大学', 'C-', null],
            ['贵州师范大学', 'C+', null],
            ['哈尔滨师范大学', 'B', null],
            ['海南大学', 'C-', null],
            ['海南师范大学', 'C+', null],
            ['杭州师范大学', 'C+', null],
            ['河北大学', 'B', null],
            ['河北师范大学', 'B', null],
            ['河南大学', 'B+', null],
            ['河南师范大学', 'C-', null],
            ['黑龙江大学', 'B', null],
            ['湖北大学', 'B-', null],
            ['湖南大学', 'C', null],
            ['湖南师范大学', 'B', null],
            ['华东师范大学', 'A', null],
            ['华南师范大学', 'B', null],
            ['华侨大学', 'C', null],
            ['华中科技大学', 'B-', null],
            ['华中师范大学', 'B+', '中国语言文学'],
            ['吉林大学', 'B+', null],
            ['吉林师范大学', 'C', null],
            ['济南大学', 'C', null],
            ['暨南大学', 'B+', null],
            ['江苏师范大学', 'B', null],
            ['江西师范大学', 'B-', null],
            ['兰州大学', 'B-', null],
            ['辽宁大学', 'B-', null],
            ['辽宁师范大学', 'C+', null],
            ['鲁东大学', 'C-', null],
            ['南昌大学', 'C+', null],
            ['南京大学', 'A', '中国语言文学'],
            ['南京师范大学', 'A-', null],
            ['南开大学', 'A-', null],
            ['南通大学', 'C-', null],
            ['内蒙古大学', 'B', null],
            ['内蒙古师范大学', 'C+', null],
            ['宁夏大学', 'C-', null],
            ['青岛大学', 'C', null],
            ['清华大学', 'B+', null],
            ['曲阜师范大学', 'B-', null],
            ['三峡大学', 'C', null],
            ['厦门大学', 'B', null],
            ['山东大学', 'A', null],
            ['山东师范大学', 'B+', null],
            ['山西大学', 'B-', null],
            ['陕西师范大学', 'B+', '中国语言文学（自定）'],
            ['上海大学', 'B', null],
            ['上海交通大学', 'B-', null],
            ['上海师范大学', 'B+', null],
            ['上海外国语大学', 'C+', null],
            ['深圳大学', 'C-', null],
            ['沈阳师范大学', 'C+', null],
            ['首都师范大学', 'A-', null],
            ['四川大学', 'A', null],
            ['四川师范大学', 'B', null],
            ['苏州大学', 'B+', null],
            ['天津师范大学', 'B', null],
            ['天津外国语大学', 'C-', null],
            ['同济大学', 'C-', null],
            ['温州大学', 'C', null],
            ['武汉大学', 'A-', null],
            ['西北大学', 'B', null],
            ['西北民族大学', 'C+', null],
            ['西北师范大学', 'B', null],
            ['西华师范大学', 'C', null],
            ['西南大学', 'B+', null],
            ['西南交通大学', 'C', null],
            ['西南民族大学', 'C+', null],
            ['湘潭大学', 'C+', null],
            ['新疆大学', 'B-', null],
            ['新疆师范大学', 'C+', null],
            ['延边大学', 'C-', null],
            ['扬州大学', 'B', null],
            ['云南大学', 'B-', null],
            ['云南民族大学', 'C-', null],
            ['云南师范大学', 'C', null],
            ['浙江大学', 'A', null],
            ['浙江工业大学', 'C', null],
            ['浙江师范大学', 'B+', null],
            ['郑州大学', 'C+', null],
            ['中国传媒大学', 'B-', null],
            ['中国海洋大学', 'C', null],
            ['中国人民大学', 'A-', null],
            ['中南民族大学', 'C', null],
            ['中山大学', 'A-', null],
            ['中央民族大学', 'B+', null],
            ['重庆师范大学', 'C+', null]
          ]
        ],
        [
          '中西医结合',
          [
            ['安徽中医药大学', 'C+', null],
            ['北京大学', 'B', null],
            ['北京协和医学院', 'C+', null],
            ['北京中医药大学', 'A+', '中西医结合'],
            ['成都中医药大学', 'B', null],
            ['大连医科大学', 'B+', null],
            ['第二军医大学', 'B', null],
            ['第四军医大学', 'B-', null],
            ['福建中医药大学', 'B', null],
            ['复旦大学', 'A-', '中西医结合'],
            ['广西中医药大学', 'C-', null],
            ['广州中医药大学', 'A-', null],
            ['河北医科大学', 'B-', null],
            ['河北中医学院', 'C', null],
            ['黑龙江中医药大学', 'B', null],
            ['湖北中医药大学', 'C-', null],
            ['湖南中医药大学', 'B-', null],
            ['华中科技大学', 'B', null],
            ['暨南大学', 'C+', null],
            ['江西中医药大学', 'C', null],
            ['辽宁中医药大学', 'B+', null],
            ['南方医科大学', 'B+', null],
            ['南京中医药大学', 'A-', null],
            ['山东中医药大学', 'C+', null],
            ['陕西中医药大学', 'C-', null],
            ['上海交通大学', 'C+', null],
            ['上海中医药大学', 'A+', null],
            ['首都医科大学', 'C', null],
            ['四川大学', 'B+', null],
            ['天津医科大学', 'B-', null],
            ['天津中医药大学', 'B+', null],
            ['西安交通大学', 'C-', null],
            ['新疆医科大学', 'C', null],
            ['扬州大学', 'C-', null],
            ['浙江中医药大学', 'B-', null],
            ['中国药科大学', 'C+', null],
            ['中山大学', 'C', null]
          ]
        ],
        [
          '中药学',
          [
            ['安徽中医药大学', 'B-', null],
            ['北京协和医学院', 'B-', null],
            ['北京中医药大学', 'B+', '中药学'],
            ['成都中医药大学', 'B+', '中药学'],
            ['第二军医大学', 'C+', null],
            ['第四军医大学', 'C+', null],
            ['福建中医药大学', 'C-', null],
            ['甘肃中医药大学', 'C', null],
            ['广西中医药大学', 'C-', null],
            ['广州中医药大学', 'B', null],
            ['贵阳中医学院', 'C', null],
            ['河南中医药大学', 'C+', null],
            ['黑龙江中医药大学', 'A+', null],
            ['湖北中医药大学', 'B-', null],
            ['湖南中医药大学', 'C-', null],
            ['暨南大学', 'B', null],
            ['江西中医药大学', 'B+', null],
            ['辽宁中医药大学', 'B-', null],
            ['南方医科大学', 'C-', null],
            ['南京中医药大学', 'A-', '中药学'],
            ['山东中医药大学', 'C+', null],
            ['陕西中医药大学', 'C', null],
            ['上海中医药大学', 'A+', '中药学'],
            ['沈阳药科大学', 'B', null],
            ['首都医科大学', 'C-', null],
            ['天津中医药大学', 'A-', '中药学'],
            ['温州医科大学', 'C', null],
            ['长春中医药大学', 'B-', null],
            ['浙江中医药大学', 'B', null],
            ['中国药科大学', 'B+', '中药学']
          ]
        ],
        [
          '中医学',
          [
            ['安徽中医药大学', 'B-', null],
            ['北京中医药大学', 'A+', '中医学'],
            ['成都中医药大学', 'B+', null],
            ['福建中医药大学', 'B-', null],
            ['甘肃中医药大学', 'C', null],
            ['广西中医药大学', 'C+', null],
            ['广州中医药大学', 'B+', '中医学'],
            ['贵阳中医学院', 'C', null],
            ['河北中医学院', 'C-', null],
            ['河南中医药大学', 'C+', null],
            ['黑龙江中医药大学', 'B+', null],
            ['湖北中医药大学', 'C+', null],
            ['湖南中医药大学', 'B', null],
            ['江西中医药大学', 'C+', null],
            ['辽宁中医药大学', 'B', null],
            ['南京中医药大学', 'A-', null],
            ['厦门大学', 'C-', null],
            ['山东中医药大学', 'B', null],
            ['陕西中医药大学', 'C', null],
            ['上海中医药大学', 'A+', '中医学'],
            ['首都医科大学', 'C+', null],
            ['天津中医药大学', 'B+', null],
            ['云南中医学院', 'C-', null],
            ['长春中医药大学', 'B-', null],
            ['浙江中医药大学', 'B', null]
          ]
        ],
        [
          '作物学',
          [
            ['安徽农业大学', 'C+', null],
            ['东北农业大学', 'B-', null],
            ['福建农林大学', 'C+', null],
            ['甘肃农业大学', 'C+', null],
            ['广西大学', 'C', null],
            ['海南大学', 'C-', '作物学（自定）'],
            ['河北农业大学', 'B-', null],
            ['河南农业大学', 'B', null],
            ['黑龙江八一农垦大学', 'C-', null],
            ['湖南农业大学', 'B+', null],
            ['华南农业大学', 'B', null],
            ['华中农业大学', 'A-', null],
            ['吉林农业大学', 'B-', null],
            ['江西农业大学', 'C', null],
            ['南京农业大学', 'A+', '作物学'],
            ['内蒙古农业大学', 'C-', null],
            ['青海大学', 'C', null],
            ['山东农业大学', 'B+', null],
            ['山西农业大学', 'C+', null],
            ['沈阳农业大学', 'B', null],
            ['石河子大学', 'C', null],
            ['四川农业大学', 'B+', '作物学（自定）'],
            ['西北农林科技大学', 'B+', null],
            ['西南大学', 'B', null],
            ['扬州大学', 'B', null],
            ['云南农业大学', 'C+', null],
            ['长江大学', 'C-', null],
            ['浙江大学', 'A-', null],
            ['中国农业大学', 'A+', '作物学']
          ]
        ],
        [
          '机械及航空航天和制造工程',
          [
            ['北京大学', null, '机械及航空航天和制造工程'],
            ['复旦大学', null, '机械及航空航天和制造工程']
          ]
        ],
        [
          '商业与管理',
          [
            ['北京大学', null, '商业与管理'],
            ['上海交通大学', null, '商业与管理']
          ]
        ],
        ['社会政策与管理', [['北京大学', null, '社会政策与管理']]],
        [
          '现代语言学',
          [
            ['北京大学', null, '现代语言学'],
            ['复旦大学', null, '现代语言学'],
            ['清华大学', null, '现代语言学']
          ]
        ],
        [
          '语言学',
          [
            ['北京大学', null, '语言学'],
            ['北京师范大学', null, '语言学']
          ]
        ],
        ['工程', [['大连理工大学', null, '工程']]],
        [
          '农学',
          [
            ['华南理工大学', null, '农学'],
            ['西北农林科技大学', null, '农学']
          ]
        ],
        [
          '化学工程与技术（自定）',
          [['宁夏大学', null, '化学工程与技术（自定）']]
        ],
        [
          '生态学（自定）',
          [
            ['青海大学', null, '生态学（自定）'],
            ['西藏大学', null, '生态学（自定）']
          ]
        ],
        ['会计与金融', [['清华大学', null, '会计与金融']]],
        ['经济学和计量经济学', [['清华大学', null, '经济学和计量经济学']]],
        ['统计学与运筹学', [['清华大学', null, '统计学与运筹学']]],
        ['电子电气工程', [['上海交通大学', null, '电子电气工程']]],
        ['艺术与设计', [['同济大学', null, '艺术与设计']]],
        ['政治学（自定）', [['外交学院', null, '政治学（自定）']]],
        ['公安学（自定）', [['中国人民公安大学', null, '公安学（自定）']]],
        ['美术学设计学', [['中央美术学院', null, '美术学设计学']]]
      ],
      doubleFirstClassType: {
        schoolA: [
          '北京大学',
          '中国人民大学',
          '清华大学',
          '北京航空航天大学',
          '北京理工大学',
          '中国农业大学',
          '北京师范大学',
          '中央民族大学',
          '南开大学',
          '天津大学',
          '大连理工大学',
          '吉林大学',
          '哈尔滨工业大学',
          '复旦大学',
          '同济大学',
          '上海交通大学',
          '华东师范大学',
          '南京大学',
          '东南大学',
          '浙江大学',
          '中国科学技术大学',
          '厦门大学',
          '山东大学',
          '中国海洋大学',
          '武汉大学',
          '华中科技大学',
          '中南大学',
          '中山大学',
          '华南理工大学',
          '四川大学',
          '重庆大学',
          '电子科技大学',
          '西安交通大学',
          '西北工业大学',
          '兰州大学',
          '国防科技大学'
        ],
        schoolB: [
          '东北大学',
          '郑州大学',
          '湖南大学',
          '云南大学',
          '西北农林科技大学',
          '新疆大学'
        ],
        subject: [
          '北京交通大学',
          '北京工业大学',
          '北京科技大学',
          '北京化工大学',
          '北京邮电大学',
          '北京林业大学',
          '北京协和医学院',
          '北京中医药大学',
          '首都师范大学',
          '北京外国语大学',
          '中国传媒大学',
          '中央财经大学',
          '对外经济贸易大学',
          '外交学院',
          '中国人民公安大学',
          '北京体育大学',
          '中央音乐学院',
          '中国音乐学院',
          '中央美术学院',
          '中央戏剧学院',
          '中国政法大学',
          '天津工业大学',
          '天津医科大学',
          '天津中医药大学',
          '华北电力大学',
          '河北工业大学',
          '太原理工大学',
          '内蒙古大学',
          '辽宁大学',
          '大连海事大学',
          '延边大学',
          '东北师范大学',
          '哈尔滨工程大学',
          '东北农业大学',
          '东北林业大学',
          '华东理工大学',
          '东华大学',
          '上海海洋大学',
          '上海中医药大学',
          '上海外国语大学',
          '上海财经大学',
          '上海体育学院',
          '上海音乐学院',
          '上海大学',
          '苏州大学',
          '南京航空航天大学',
          '南京理工大学',
          '中国矿业大学',
          '南京邮电大学',
          '河海大学',
          '江南大学',
          '南京林业大学',
          '南京信息工程大学',
          '南京农业大学',
          '南京中医药大学',
          '中国药科大学',
          '南京师范大学',
          '中国美术学院',
          '安徽大学',
          '合肥工业大学',
          '福州大学',
          '南昌大学',
          '河南大学',
          '中国地质大学',
          '武汉理工大学',
          '华中农业大学',
          '华中师范大学',
          '中南财经政法大学',
          '湖南师范大学',
          '暨南大学',
          '广州中医药大学',
          '华南师范大学',
          '海南大学',
          '广西大学',
          '西南交通大学',
          '西南石油大学',
          '成都理工大学',
          '四川农业大学',
          '成都中医药大学',
          '西南大学',
          '西南财经大学',
          '贵州大学',
          '西藏大学',
          '西北大学',
          '西安电子科技大学',
          '长安大学',
          '陕西师范大学',
          '青海大学',
          '宁夏大学',
          '石河子大学',
          '中国石油大学',
          '宁波大学',
          '中国科学院大学',
          '第二军医大学',
          '第四军医大学'
        ]
      }
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    getSchoolName: function (l) {
      return -1 !== this.doubleFirstClassType.schoolA.indexOf(l)
        ? l + '（一流大学建设高校A类）'
        : -1 !== this.doubleFirstClassType.schoolB.indexOf(l)
        ? l + '（一流大学建设高校B类）'
        : -1 !== this.doubleFirstClassType.subject.indexOf(l)
        ? l + '（一流学科建设高校）'
        : l
    },
    getAdditionalRanking: function (l) {
      if (undefined === l) return null
      console.log(l)
      for (var n = 0; n < this.additionalRanking.length; n++)
        if (
          -1 !== l.indexOf(this.additionalRanking[n][0]) ||
          -1 !== this.additionalRanking[n][0].indexOf(l)
        )
          return this.additionalRanking[n][1]
      return null
    },
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    },
    onMajorClick: function (item) {
      console.log('on major click and item is ', item)

      this.detailActive || (this.detailActive = !0),
        this.currentMajor !== item && (this.currentMajor = item),
        this.scrollToTop()
    },
    onBackClick: function () {
      this.detailActive && (this.detailActive = !1),
        this.currentMajor.length && (this.currentMajor = []),
        this.scrollToTop()
    },
    isNotEmpty: function (l) {
      return '' !== l && '-' !== l && '—' !== l && '－' !== l && undefined !==l && null !==l
    },
    scrollToTop: function () {
      if (!document.body.scrollTop || !document.documentElement.scrollTop)
        if (document.documentElement.scrollTop)
          var l = setInterval(function () {
            ;(document.documentElement.scrollTop =
              0.6 * document.documentElement.scrollTop),
              document.documentElement.scrollTop < 1 &&
                (clearInterval(l), (document.documentElement.scrollTop = 0))
          }, 30)
        else if (document.body.scrollTop)
          var n = setInterval(function () {
            ;(document.body.scrollTop = 0.6 * document.body.scrollTop),
              document.body.scrollTop < 1 &&
                (clearInterval(n), (document.body.scrollTop = 0))
          }, 30)
    }
  },
  mounted: function () {
    if (this.isMobile !== null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage5Mobile'
      })
      return
    }
    queryMatchingMajorsDetails({}).then(res => {
      if (res.rescode == 200) {
        console.log('queryMatchingMajorsDetails', res)
        this.majorsData = res.majorsData
        this.scrollToTop()
      }
    })
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../../Public/resources/css/templates/home/major/std_result5.min.css');

.main .main-content .main-submajors-wrapper .submajor-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  min-width: 100%;
}
.main-title {
  padding: 0;
}

.submajor p {
  color:#000
}
</style>
